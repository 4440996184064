<template>
    <transition name="modal">
        <div class="modal-mask">
            <div class="modal-wrapper">
                <div class="modal-container text-center">
                    <div class="card">
                        <h1 :class="`mb-4 mt-3 ${result.event.toUpperCase()}`">{{ result.event.toUpperCase() }}</h1>

                        <div class="card-body">
                            <div class="row">
                                <div class="col-4 text-right">
                                    <img src="@/assets/demo/cube_1.gif" id="dice" />
                                </div>

                                <div class="col-6 cont">
                                    <p class="mt-3" v-if="!isWinner">Oh Dear, you did not win this time!</p>

                                    <h3 v-if="isWinner">{{ winAmount }} ONE</h3>
                                    <p class="mt-3" v-if="isWinner">Congratulations on Winning!</p>
                                </div>

                                <div class="col-12">
                                    <small>{{ result.transactionHash }}</small>
                                </div>
                            </div>

                            <button :class="`btn ${result.event.toUpperCase()} mt-3`" v-on:click="close">Continue</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import Web3 from 'web3'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
    name: 'DiceResultModal',
})
export default class DiceResultModal extends Vue {
    @Prop(Object) result
    get isWinner() {
        return this.result.event.toUpperCase() === 'WINNER'
    }
    get winAmount() {
        if (!this.isWinner) return 0

        return Web3.utils.fromWei('' + this.result.returnValues.tokensWon, 'ether')
    }
    close() {
        this.$emit('close')
    }
}
</script>

<style scoped>
h1.LOSER {
    color: #ea4436;
}

h1.WINNER {
    color: #42b983;
}

.modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /*background: rgba(0, 0, 0, 0.9);*/
    display: table;
    transition: all 0.6s ease-in-out;
    opacity: 1;
}

.modal-wrapper {
    display: table-cell;
    vertical-align: middle;
}

.card {
    background: #080b1f;
    border: 1px solid white;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
}

.modal-container {
    width: 40%;
    margin: 0 auto;
    transition: all 0.6s ease-in-out;
}

.modal-header h3 {
    margin-top: 0;
    color: #42b983;
}

.card {
    color: white;
    border-radius: 14px;
    /*background: rgba(255, 255, 255, 0.1);*/
}

.card-body::before {
    content: unset;
}

.card-body,
h4,
h5,
p {
    color: white;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}

.btn.WINNER {
    background: #42b983;
}

.btn.LOSER {
    background: #ea4436;
}

h3 {
    color: #42b983;
}
</style>
