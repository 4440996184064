<template>
    <transition name="modal">
        <div class="modal-mask">
            <div class="modal-wrapper">
                <div class="modal-container text-center">
                    <div class="card">
                        <div class="card-body">
                            <h1 :class="`mb-4 mt-3 ${isWinner ? 'WINNER' : 'LOSER'}`">{{ isWinner ? 'WINNER' : 'LOSER' }}</h1>

                            <div class="row" v-if="isWinner">
                                <div class="offset-3"></div>
                                <div class="col-6 text-center">
                                    <h3>{{ winAmount }} ONE</h3>
                                    <p class="mt-3">Congratulations on Winning!</p>
                                </div>
                            </div>

                            <div class="row" v-if="!isWinner">
                                <div class="offset-3"></div>
                                <div class="col-6 text-center">
                                    <p class="mt-2">Oh Dear, you did not win this time!</p>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-12">
                                    <small v-on:click="showDetail = !showDetail">{{ resultHash }}</small>
                                    <p>
                                        <code :class="`detail ${showDetail ? 'show' : ''}`" v-show="showDetail">{{ JSON.stringify(result, null, 2) }}</code>
                                    </p>
                                </div>
                            </div>

                            <button :class="`btn btn-default mt-3`" v-on:click="close">Continue</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import { Prop, Vue } from 'vue-property-decorator'

export default class DiceResultModal extends Vue {
    @Prop(Object) result
    @Prop(Object) prize
    showDetail = false
    
    get winAmount() {
        return 0
    }

    get isWinner() {
        if (this.result === null) return false

        return this.result.event != 'Loser'
    }

    get resultHash() {
        if (this.result === null) return ''

        return this.result.transactionHash
    }

    close() {
        this.$emit('close')
    }
}
</script>

<style scoped>
h1.LOSER {
    color: #ea4436;
}

h1.WINNER {
    color: #42b983;
}

.detail {
    max-height: 0px;
    transition: max-height 0.3s ease-in-out;
}

.detail.show {
    transition: max-height 0.3s ease-in-out;
    max-height: 700px;
    overflow: scroll;
}

.modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /*background: rgba(0, 0, 0, 0.9);*/
    display: table;
    transition: all 0.6s ease-in-out;
    opacity: 1;
}

.modal-wrapper {
    display: table-cell;
    vertical-align: middle;
}

.card {
    background: #080b1f;
    border: 1px solid white;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
}

.modal-container {
    width: 40%;
    margin: 0 auto;
    transition: all 0.6s ease-in-out;
}

.modal-header h3 {
    margin-top: 0;
    color: #42b983;
}

.card {
    color: white;
    border-radius: 14px;
    /*background: rgba(255, 255, 255, 0.1);*/
}

.card-body::before {
    content: unset;
}

.card-body,
h4,
h5,
p {
    color: white;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}

.btn.WINNER {
    background: #42b983;
}

.btn.LOSER {
    background: #ea4436;
}

h3 {
    color: #42b983;
}
</style>
