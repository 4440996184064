<template>
  <div class="collection">
    <div class="row mx-2">
      <ItemCard
        class="col-lg-3 col-md-6 col-sm-6 mb-4 item-card"
        v-for="item in items"
        :key="item.id"
        :item="item"
      ></ItemCard>
    </div>
  </div>
</template>

<script>
import { Prop, Component, Vue } from 'vue-property-decorator';

@Component({
  name: 'ItemCollection',
  components: {
    ItemCard: () => import('@/components/marketplace/ItemCard.vue'),
  },
})
export default class ItemCollection extends Vue {
  @Prop(Object) collection;
  @Prop({ type: Array, required: true }) items;
}
</script>

<style scoped>
.item-card {
  min-height: 10vh;
}
</style>
