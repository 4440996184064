<template>
  <div class="mt-2" v-if="isAbleToMint">
    <button
      size="large"
      class="action-btn ant-btn-primary w-100 rounded-3"
      @click="visibleModal = true"
    >
      Mint
    </button>

    <a-modal
      v-model="visibleModal"
      class="modal--mint"
      centered
      @close="visibleModal = false"
      @ok="processMint()"
      :ok-text="!isMinting ? 'Mint' : 'Minting'"
      :ok-button-props="{ props: { disabled: isLoading } }"
      :confirm-loading="isMinting"
    >
      <b-form @submit.stop.prevent>
        <!--        <label for="mint-to">Mint to</label>-->
        <!--        <b-form-input v-model="mint.to" :state="validAddress" id="mint-to"></b-form-input>-->
        <!--        <b-form-invalid-feedback :state="validAddress" v-if="!validAddress">-->
        <!--          Invalid address format-->
        <!--        </b-form-invalid-feedback>-->
        <label for="mint-editions" class="mt-3">Number of editions to mint</label>
        <b-form-input
          v-model="mint.editions"
          :state="validEditions"
          id="mint-editions"
        ></b-form-input>
        <b-form-invalid-feedback :state="validEditions" v-if="!validEditions">
          Must be greater than zero
        </b-form-invalid-feedback>

        <div class="mt-4">
          <label>Estimated fee</label>: {{ totalCost }}
          <Icon type="sol-circle"></Icon>
        </div>
      </b-form>
    </a-modal>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { LAMPORTS_PER_SOL, PublicKey } from '@solana/web3.js';
import { MintLayout, AccountLayout } from '@solana/spl-token';
import { MetadataKey } from '@metaplex-foundation/mpl-token-metadata';
import { getPhantomWallet } from '@solana/wallet-adapter-wallets';

import { ArtType } from '@/views/shop/types';
import { decodeMasterEdition } from '@/views/shop/actions/metadata';
import { MAX_METADATA_LEN, MAX_EDITION_LEN } from '@/views/shop/actions/metadata';
import { toNumber } from '@/helper/numbers';
import { actions } from '@metaplex/js';

@Component({
  name: 'ArtMinting',
  components: {
    Icon: () => import('@/components/Icon.vue'),
  },
  computed: {
    ...mapState('walletModule', ['wallet_addr']),
    ...mapState('connectionModule', ['connection']),
  },
  methods: {
    ...mapActions('metaplexNFTModule', ['getTokenAccount', 'resetCacheList']),
  },
})
export default class ArtMinting extends Vue {
  @Prop(String) id;
  @Prop(Number) remountArtMinting;
  @Prop(Object) art;

  visibleModal = false;
  isLoading = false;
  isMinting = false;
  totalCost = 0;
  isAbleToMint = false;

  mint = this.getDefaultMint();
  debounceCalculateCost = null;

  async created() {
    const maxEditionsToMint = this.art.maxSupply - this.art.supply;
    const isArtMasterEdition = this.art.type === ArtType.Master;
    const tokenAccount = await this.getTokenAccount(this.id);
    const isArtOwnedByUser = (tokenAccount?.info.amount.toNumber() || 0) > 0;
    const isMasterEditionV1 = tokenAccount
      ? decodeMasterEdition(tokenAccount.account.data).key === MetadataKey.MasterEditionV1
      : false;

    this.isAbleToMint =
      isArtMasterEdition &&
      isArtOwnedByUser &&
      !isMasterEditionV1 &&
      maxEditionsToMint !== 0;
  }

  async calculateTotalCost() {
    const connection = this.connection;
    const mintRentExempt = await connection.getMinimumBalanceForRentExemption(
      MintLayout.span,
    );
    const accountRentExempt = await connection.getMinimumBalanceForRentExemption(
      AccountLayout.span,
    );
    const metadataRentExempt = await connection.getMinimumBalanceForRentExemption(
      MAX_METADATA_LEN,
    );
    const editionRentExempt = await connection.getMinimumBalanceForRentExemption(
      MAX_EDITION_LEN,
    );

    this.totalCost =
      ((mintRentExempt + accountRentExempt + metadataRentExempt + editionRentExempt) *
        this.mint.editions) /
      LAMPORTS_PER_SOL;
  }

  get validAddress() {
    try {
      new PublicKey(this.mint.to);
      return true;
    } catch (e) {
      return false;
    }
  }

  get validEditions() {
    return toNumber(this.mint.editions) > 0;
  }

  async processMint() {
    try {
      this.isLoading = true;
      this.isMinting = true;

      const ownerWallet = getPhantomWallet();
      const connectedAdapter = ownerWallet.adapter();
      connectedAdapter
        .connect()
        .then(() => {})
        .catch(() => {});

      for (let i = 0; i < this.mint.editions; i++) {
        let resp = await actions.mintEditionFromMaster({
          connection: this.connection,
          wallet: connectedAdapter,
          masterEditionMint: new PublicKey(this.id),
        });
        if (!resp) {
          throw new Error('mint edition failed');
        }
      }
    } catch (e) {
      console.error(e);
    } finally {
      this.isLoading = false;
      this.isMinting = false;
      this.visibleModal = false;
    }

    this.resetCacheList();
    this.$router.push('/shop/artworks');
  }

  getDefaultMint() {
    return {
      editions: 1,
      to: this.wallet_addr,
    };
  }

  @Watch('visibleModal')
  watchVisibleModal(show) {
    if (show) {
      this.mint = this.getDefaultMint();
    }
  }

  @Watch('mint.editions', { immediate: true })
  watchMintEditions(qty) {
    if (toNumber(qty) <= 0) {
      this.mint.editions = 1;
      return;
    }
    // simple debounce to void 429 request solana server
    clearTimeout(this.debounceCalculateCost);
    this.totalCost = '. . . . . . . . . .';
    this.isLoading = true;
    this.debounceCalculateCost = setTimeout(() => {
      this.calculateTotalCost().then(() => {
        this.isLoading = false;
      });
    }, 300);
  }
}
</script>

<style lang="scss" scoped>
@import './src/assets/css/components/metaplex-modal.scss';
</style>
