<template>
    <div class="show">
        <BannerTop
            title_blue="Games"
            title_white="Lounge"
            heading="Provably fair blockchain gaming"
            info="Secured by Harmony VRF"
            image="https://app.babilu.online/static/collections/0x0/items/sofa.png"
        ></BannerTop>

        <div class="container">
            <div class="row">
                <div class="col-4 mt-4" v-for="(game, idx) in games" :key="idx">
                    <div class="card game">
                        <div class="card-body text-center">
                            <img :src="game.image" />
                            <h4 class="mt-4">{{ game.name }}</h4>
                            <p>{{ game.description }}</p>

                            <button disabled v-if="!game.released" class="btn btn-default">COMING SOON!</button>
                            <router-link v-if="game.released" :to="game.link" class="btn btn-default">PLAY</router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator'

@Component({
    components: {
        BannerTop: () => import('../../components/BannerTop.vue'),
    },
})
export default class GameLoungeIndex extends Vue {
    games = [
        {
            name: 'Classic Dice',
            image: '/lounge/classic_dice.png',
            description: 'Simple dicing game',
            link: '/contracts/harmony/dice_one',
            released: true,
        },
        {
            name: 'Millions Jackpot',
            image: '/lounge/million_jackpot.png',
            description: '1 in a million chance to take it all',
            link: '/contracts/harmony/dice?machineID=1',
            released: false,
        },
        {
            name: 'Spin To Win',
            image: '/lounge/spin_to_win.png',
            description: 'Spin to win a variety of prizes',
            link: '/lounge/games/spin_to_win',
            released: true,
        },
        {
            name: 'AlphaBatem Slots',
            image: '/lounge/babilu_slots.png',
            description: 'Retro slot machine',
            link: '/contracts/harmony/slots?machineID=1',
            released: false,
        },
        {
            name: 'Higher or Lower',
            image: '/lounge/highlo.png',
            description: 'Guess the next number sequence',
            link: '/contracts/harmony/hilo?machineID=1',
            released: false,
        },
    ]
}
</script>

<style scoped>
.card {
    background: transparent;
    color: white;
    border: 1px solid #20126f;
}

.card-body::before {
    content: unset;
}

.card-body,
h4,
h5 {
    color: white;
}

h6,
small {
    color: #7a7a7a;
}

img {
    max-height: 100px;
}

.game:hover {
    background: rgba(0, 0, 0, 0.2);
}
</style>
