<template>
    <div class="nft-preview mt-4">
        <div class="row">
            <div class="col-12 text-center" id="preview-container">
                <div class="row">
                    <div class="col">
                        <canvas id="overlay-canvas" height="400px" width="800px"></canvas>
                        <canvas id="preview-canvas" height="400px" width="800px"></canvas>
                    </div>

                    <div class="col">
                        <div class="card">
                            <div class="card-body">
                                <h5>Information</h5>

                                <p>To ensure your NFT is correctly displayed within a AlphaBatem Oasis your item should align
                                    with the grey section within the preview to the left.</p>

                                <p>Multi-block structures can be imported by increasing the width, depth & height params
                                    below.</p>

                                <p>For items that are not within the isometric perspective, a custom shader can be
                                    applied to the image to
                                    adjust it to this viewpoint by selecting the box below.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-12">
                <form class="form row">
                    <div class="col-6">
                        <div class="form-group form-check mt-2">
                            <input class="form-check-input mt-2" type="checkbox" id="lockScale"
                                   v-model="form.lock_scale">
                            <label class="form-check-label" for="lockScale">
                                Lock scale
                            </label>
                        </div>


                        <div class="form-group form-check mt-5 mb-3">
                            <input class="form-check-input mt-2" type="checkbox" id="isoCheck"
                                   v-model="form.isometric_shader">
                            <label class="form-check-label" for="isoCheck">
                                Enable Isometric Shader
                            </label>
                        </div>

                        <div class="form-group">
                            <label>Width</label>
                            <div class="row">
                                <div class="col-9">
                                    <input type="range" min="1" :max="maxTileWidth" v-model="form.tile_x"
                                           class="form-control"
                                    >
                                </div>
                                <div class="col-3">
                                    <input type="number" min="1" :max="maxTileWidth" v-model="form.tile_x"
                                           class="form-control"
                                    >
                                </div>
                            </div>
                        </div>

                        <div class="form-group">
                            <label>Depth</label>
                            <div class="row">
                                <div class="col-9">
                                    <input type="range" min="1" :max="maxTileDepth" v-model="form.tile_y"
                                           class="form-control"
                                    >
                                </div>
                                <div class="col-3">
                                    <input type="number" min="1" :max="maxTileDepth" v-model="form.tile_y"
                                           class="form-control"
                                    >
                                </div>
                            </div>
                        </div>

                        <div class="form-group">
                            <label>Height</label>
                            <div class="row">
                                <div class="col-9">
                                    <input type="range" min="1" :max="maxTileHeight" v-model="form.tile_z"
                                           class="form-control"
                                    >
                                </div>
                                <div class="col-3">
                                    <input type="number" min="1" :max="maxTileHeight" v-model="form.tile_z"
                                           class="form-control"
                                    >
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-6">
                        <div class="form-group">

                            <label>Scale X</label>
                            <div class="row">
                                <div class="col-9">
                                    <input type="range" min="0.01" max="4" step="0.01" class="form-control"
                                           v-model="form.scale_x"
                                    >
                                </div>
                                <div class="col-3">
                                    <input type="number" min="0.01" max="4" step="0.01" class="form-control"
                                           v-model="form.scale_x"
                                    >
                                </div>
                            </div>
                        </div>

                        <div class="form-group">
                            <label>Scale Y</label>
                            <div class="row">
                                <div class="col-9">
                                    <input type="range" min="0.01" max="4" step="0.01" class="form-control"
                                           v-model="form.scale_y"
                                           :disabled="form.lock_scale"
                                    >
                                </div>
                                <div class="col-3">
                                    <input type="number" min="0.01" max="4" step="0.01" class="form-control"
                                           v-model="form.scale_y"
                                           :disabled="form.lock_scale"
                                    >
                                </div>
                            </div>
                        </div>

                        <div class="form-group">
                            <label>Position X</label>
                            <div class="row">
                                <div class="col-9">
                                    <input type="range" min="-400.0" max="400.0" step="1.0" class="form-control"
                                           v-model="form.position_x"
                                    >
                                </div>
                                <div class="col-3">
                                    <input type="number" min="-400.0" max="400.0" step="1.0" class="form-control"
                                           v-model="form.position_x"
                                    >
                                </div>
                            </div>
                        </div>

                        <div class="form-group">
                            <label>Position Y</label>
                            <div class="row">
                                <div class="col-9">
                                    <input type="range" min="-400.0" max="400.0" step="1.0" class="form-control"
                                           v-model="form.position_y"
                                    >
                                </div>
                                <div class="col-3">
                                    <input type="number" min="-400.0" max="400.0" step="1.0" class="form-control"
                                           v-model="form.position_y"
                                    >
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                
                <div class="col-12">
                    <button v-on:click="completeAdjustments" class="btn btn-block btn-lg mt-5">Next</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Isomer from 'isomer'

    export default {
        name: "Preview",
        props: {
            selected_asset: Object,
            item_type: Number,
            sub_type: Number,
            details: Object,
        },
        data() {
            return {
                image: null,
                iso: null,
                form: {
                    tile_x: 1,
                    tile_y: 1,
                    tile_z: 1,
                    scale_x: 1.0,
                    scale_y: 1.0,
                    position_x: 0.0,
                    position_y: 0.0,
                    isometric_shader: true,
                    lock_scale: true,
                }
            }
        },
        watch: {
            form: {
                handler(old, newV) {
                    this.validateLocks(old, newV);
                    this.loadCanvas();
                },
                deep: true,
            }
        },
        computed: {
            maxTileHeight: function () {

                if (this.item_type === 3) { //Sprite
                    if (this.sub_type === 0) //Player sprite
                        return 2;

                    if (this.sub_type === 12) //Pet sprite
                        return 1;
                }


                return 3
            },
            maxTileWidth: function () {
                if (this.item_type === 3) { //Sprite
                    if (this.sub_type === 0) //Player sprite
                        return 1;

                    if (this.sub_type === 12) //Pet sprite
                        return 2;
                }

                return 3
            },
            maxTileDepth: function () {
                if (this.item_type === 3) { //Sprite
                    if (this.sub_type === 0) //Player sprite
                        return 1;

                    if (this.sub_type === 12) //Pet sprite
                        return 2;
                }

                return 3
            }
        },
        methods: {
            validateLocks: function (old, newV) {
                if (!newV.lock_scale) {
                    return
                }
                if (this.form.scale_y !== newV.scale_x) {
                    console.log("new scale_y");
                    this.form.scale_y = newV.scale_x
                }

                if (this.form.scale_x !== newV.scale_y) {
                    console.log("new scale_x");
                    this.form.scale_x = newV.scale_y
                }
            },

            loadCanvas: function () {
                const canvas = document.getElementById("preview-canvas");
                if (this.iso === null)
                    this.iso = new Isomer(canvas);

                const context = canvas.getContext('2d');
                context.clearRect(0, 0, canvas.width, canvas.height);


                this.drawGrid();

                var Point = Isomer.Point;
                var Shape = Isomer.Shape;
                var Color = Isomer.Color;

                var red = new Color(160, 60, 50);
                var blue = new Color(50, 60, 160);

                this.iso.add(Shape.Prism(Point(5, 0, 0), 0, 5, 5), blue); //Wall Right
                this.iso.add(Shape.Prism(Point(0, 5, 0), 5, 0, 5), red); //Wall Left
                // iso.add(Shape.Prism(Point(12, 16, 1),1,0,3), new Color()); //Door

                let art;

                if (this.item_type === 2) //Wall decoration
                    art = Shape.Prism(Point(2, 3, 0), parseInt(this.form.tile_x), 0, parseInt(this.form.tile_z));
                else
                    art = Shape.Prism(Point(1, 1, 1), parseInt(this.form.tile_x), parseInt(this.form.tile_y), parseInt(this.form.tile_z));

                this.iso.add(art); //Object

                this.drawImageProjection();
            },

            drawImageProjection: function () {
                if (this.image == null)
                    return;

                const canvas = document.getElementById("overlay-canvas");
                const context = canvas.getContext('2d');
                context.clearRect(0, 0, canvas.width, canvas.height);


                let scaleWidth = this.form.scale_x * this.image.width;
                let scaleHeight = this.form.scale_y * this.image.height;

                let tileWidth = (canvas.width / 2) - (scaleWidth / 2);
                let tileHeight = (canvas.height / 2) - (scaleHeight / 2);

                console.log("img", {
                    tW: tileWidth,
                    tH: tileHeight,
                    posX: parseFloat(this.form.position_x) + tileWidth,
                    posY: parseFloat(this.form.position_y) + tileHeight,
                    shader: this.form.isometric_shader,
                });

                if (this.form.isometric_shader) {
                    const angle = -Math.PI / 30;
                    context.setTransform(1, Math.tan(angle), 0, 1, 0, 0);
                } else {
                    context.resetTransform()
                }

                context.drawImage(this.image, parseFloat(this.form.position_x) + tileWidth, parseFloat(this.form.position_y) + tileHeight, scaleWidth, scaleHeight);
                context.restore();

            },

            drawGrid: function () {
                const Point = Isomer.Point;
                const Color = Isomer.Color;
                const Path = Isomer.Path;
                const cBlack = new Color(1, 1, 1);

                for (let x = 0; x < 6; x++) {
                    this.iso.add(new Path([
                        new Point(x, 0, 0),
                        new Point(x, 5, 0),
                        new Point(x, 0, 0)
                    ]), cBlack);
                }
                for (let y = 0; y < 6; y++) {
                    this.iso.add(new Path([
                        new Point(0, y, 0),
                        new Point(5, y, 0),
                        new Point(0, y, 0)
                    ]), cBlack);
                }
            },

            completeAdjustments: function () {
                this.$emit("object-adjust", this.form);
                this.$emit("next")
            }
        },
        mounted() {
            this.form = this.details;

            this.image = new Image();
            this.image.addEventListener('load', e => {
                console.log("Image loaded", e);
                this.loadCanvas();
            });
            this.image.src = this.selected_asset.image_url;
        }
    }
</script>

<style scoped>
    #preview-canvas, #overlay-canvas {
        width: 800px;
        height: 500px;
        border: 1px solid grey;
    }

    #overlay-canvas {
        position: absolute;
    }

    .form-check-input {
        transform: scale(2.5);
    }

    .card {
        background: transparent;
        color: white;
        border: 1px solid #20126f;
    }

    .card-body::before {
        content: unset;
    }

    .card-body p {
        font-size: 0.8rem;
        margin-bottom: 15px;
    }

    .card-body, h4, h5 {
        color: white;
    }
</style>