<template>
    <div class="show">
        <BannerTop :title_blue="item.name" :heading="item.description" :image="imageUri"></BannerTop>

        <div class="container mt-4">
            <div class="row">
                <div class="col-6">
                    <h4>Item</h4>
                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-8">
                                    <h4>{{ item.name }}</h4>
                                    <p>{{ item.description }}</p>
                                </div>
                                <div class="col-4">
                                    <h4>
                                        100
                                        <small>/{{ item.max_quantity }}</small>
                                    </h4>

                                    <div class="badge badge-primary badge-pill" :style="{ background: rarityColour }">{{ itemRarity }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-6">
                    <h4>Details</h4>
                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-6">
                                    <h4>{{ saga.name }}</h4>
                                    <p>{{ niceDate }}</p>
                                </div>
                                <div class="item-attrs col-5">
                                    <Attr v-if="interactionString !== ''" class="mx-1" :attr="interactionString"></Attr>
                                    <Attr class="mx-1" :attr="itemTypeString"></Attr>
                                </div>
                            </div>
                            <div class="row">
                                <h6>{{ saga.contract_addr }}</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-5">
                <div class="col-6">
                    <h4>Activity</h4>
                    <div class="card">
                        <div class="card-body">
                            <div class="col-12 text-center my-5" v-if="activity.length === 0">
                                <i>No Activity</i>
                            </div>
                            <div v-for="a in activity" :key="a.id" class="col-12 mb-3"></div>
                        </div>
                    </div>
                </div>

                <div class="col-6">
                    <h4>Owners</h4>
                    <div class="card">
                        <div class="card-body">
                            <div class="col-12 text-center my-5" v-if="owners.length === 0">
                                <i>No Owners</i>
                            </div>
                            <div v-for="o in owners" :key="o.id" class="col-12 mb-3"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Saga from '@/api/saga'
import BannerTop from '../../components/BannerTop'
import Attr from './Attr'
import { Component, Vue } from 'vue-property-decorator'

@Component({
    components: {
        Attr: () => import('./Attr'),
        BannerTop: () => import('../../components/BannerTop'),
    },
})
export default class ItemShow extends Vue {
    saga = {}
    item = {}
    activity = []
    owners = []
    get imageUri() {
        if (this.item.animation_url !== undefined && this.item.animation_url !== '') return this.item.animation_url

        if (this.item.item !== undefined && this.item.item.sprite_uri !== undefined && this.item.item.sprite_uri !== '') return this.item.item.sprite_uri

        return this.item.image
    }

    get itemTypeString() {
        switch (this.item.item_type) {
            case 0:
                return 'Default'
            case 1:
                return 'Item'
            case 2:
                return 'Wall Decoration'
            case 3:
                return 'Sprite'
            case 4:
                return 'Profile Picture'
            case 5:
                return 'Wearable'
        }
        return ''
    }

    get interactionString() {
        switch (this.item.interaction_type) {
            case 0:
                return 'Default'
            case 1: //None
                return ''
            case 2:
                return 'Audio'
            case 3:
                return 'Image'
            case 4:
                return 'Video'
            case 5:
                return 'Seat'
            case 6:
                return 'Door'
            case 7:
                return 'Teleporter'
            case 8:
                return 'Animation'
            case 9:
                return 'Gambler'
            case 10:
                return 'Light'
            case 11:
                return 'Roller'
            case 12:
                return 'Scriptable'
            case 13:
                return 'Pet'
        }
        return ''
    }

    get niceDate() {
        const dt = new Date(this.saga.created_at)
        return dt.toLocaleDateString()
    }

    get itemRarity() {
        switch (this.item.rarity) {
            default:
            case 0:
                return 'Common'
            case 1:
                return 'Uncommon'
            case 2:
                return 'Rare'
            case 3:
                return 'Epic'
        }
    }

    get rarityColour() {
        switch (this.item.rarity) {
            default:
            case 0:
                return '#26b6d4'
            case 1:
                return '#C61664'
            case 2:
                return '#7558A5'
            case 3:
                return '#ea4436'
        }
    }
    getSaga(saga) {
        Saga.show(saga).then(r => (this.saga = r.data))
    }
    
    getItem(saga, item) {
        Saga.showItem(saga, item)
            .then(r => (this.item = r.data))
            .then(() => this.getSaga(this.item.saga_id))
    }

    mounted() {
        this.getItem(this.$route.params.saga_id, this.$route.params.id)
    }
}
</script>

<style scoped>
.stats {
    position: relative;
    height: 40vh;
    width: 20vw;
    margin-left: 5vw;
    text-align: center;
}

.card {
    background: transparent;
    color: white;
    border: 1px solid #20126f;
}

.card-body::before {
    content: unset;
}

.card-body,
h4,
h5 {
    color: white;
}

.item-container {
    text-align: center;
}

h6,
small {
    color: #7a7a7a;
}

.preview {
    max-height: 128px;
    width: auto;
}
</style>
