<template>
  <b-img-lazy :src="uri" loading="lazy" />
</template>

<script>
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({
  name: 'ImageContent',
})
export default class ImageContent extends Vue {
  @Prop(String) uri;
}
</script>

<style scoped></style>
