














































import { Component, Vue, Watch } from 'vue-property-decorator';
import { mapState } from 'vuex';
import { AuctionData, AuctionState } from '@metaplex-foundation/mpl-auction';
import { MetaState } from '@/views/shop/types';
import { cloneObject } from '@/helper/objects';
import { ParsedAccount } from '@/views/shop/contexts/accounts/types';
//@ts-ignore
import { PublicKey } from '@solana/web3.js';
import { programs, Connection } from '@metaplex/js';
import BN from 'bn.js';
import { VaultData } from '@metaplex-foundation/mpl-token-vault';

const LiveAuctionViewState: {
  [key: string]: {
    value: string;
    hidden?: boolean | undefined;
  };
} = {
  Defective: {
    value: '-1',
    hidden: true,
  },
  Live: {
    value: '0',
  },
  Participated: {
    value: '1',
  },
  Ended: {
    value: '2',
  },
  Resale: {
    value: '3',
    hidden: true,
  },
};
@Component({
  components: {
    Banner: () => import('./Banner.vue'),
    AuctionCard: () => import('./AuctionCard.vue'),
    SaleListLoader: () => import('./SaleListLoader.vue'),
  },
  name: 'SaleList',
  computed: {
    ...mapState('marketplaceAccountModule', ['initialized', 'metaState']),
    ...mapState('connectionModule', ['connection']),
  },
})
export default class SaleList extends Vue {
  metaState: MetaState | undefined;
  connection: Connection | undefined;

  loaded = false;
  LiveAuctionViewState = LiveAuctionViewState;
  activeTab = LiveAuctionViewState.Live.value;
  auctions: {
    [key: string]: Array<Object>;
  } = {
    [LiveAuctionViewState.Live.value]: [],
    [LiveAuctionViewState.Ended.value]: [],
    [LiveAuctionViewState.Participated.value]: [],
    [LiveAuctionViewState.Resale.value]: [],
    [LiveAuctionViewState.Defective.value]: [],
  };

  get mainBannerSvg() {
    return require('@/assets/pages/market-place/main-banner.svg');
  }

  setActiveTab(val: string) {
    this.activeTab = val;
  }

  async loadAuctionExtended(cache: any, obj: any) {
    const address = await programs.auction.AuctionExtended.getPDA(cache.vault);
    const extended = this.metaState?.auctionDataExtended?.[address.toBase58()];
    if (extended) {
      Object.assign(obj.info, extended.info);
    }
  }

  async loadExternalMetadata(cache: any, obj: any) {
    if (!this.connection) {
      return;
    }
    // get vault
    // get deposit boxes
    // get mint from boxes
    // get metadata from mint
    let vault = this.metaState?.vaults?.[cache.vault];
    if (!vault) {
      const obj = await programs.vault.Vault.load(this.connection, cache.vault);
      vault = {
        info: obj.data,
        account: obj.info,
        pubkey: obj.pubkey.toBase58(),
      } as ParsedAccount<VaultData>;
    }

    const boxes = await new programs.vault.Vault(
      vault.pubkey,
      vault.account,
    ).getSafetyDepositBoxes(this.connection!);
    if (!boxes?.length) {
      return;
    }
    const mint = await programs.metadata.Metadata.getPDA(boxes[0].data.tokenMint);
    const meta = await programs.metadata.Metadata.load(this.connection!, mint!);
    const parsedMetadata = await (await fetch(meta?.data?.data?.uri)).json();
    Object.assign(obj.externalMetadata, parsedMetadata);
  }

  @Watch('initialized', { immediate: true })
  watchInitialized(initialized: boolean) {
    if (!initialized) {
      return;
    }
    if (!this.metaState) {
      return;
    }

    const auctionCaches = Object.keys(this.metaState.auctionCaches)
      .map(item => {
        return {
          ...this.metaState?.auctionCaches[item].info,
          timestamp: (
            this.metaState?.auctionCaches[item].info?.timestamp as BN
          ).toNumber(),
        } as {
          [key: string]: any;
          timestamp: number;
        };
      })
      .sort((a: any, b: any) => {
        //let the newest auction on top
        if (a.timestamp < b.timestamp) {
          return 1;
        }

        return -1;
      });
    const fetchNFTs: Promise<any | void>[] = [];
    auctionCaches.forEach(cache => {
      const auction: ParsedAccount<AuctionData> =
        this.metaState?.auctions?.[cache.auction];
      if (!auction) {
        return;
      }

      let viewState = LiveAuctionViewState.Defective;
      switch (auction.info.state) {
        case AuctionState.Created:
          viewState = LiveAuctionViewState.Defective;
          break;
        case AuctionState.Started:
          viewState = LiveAuctionViewState.Live;
          break;
        case AuctionState.Ended:
          viewState = LiveAuctionViewState.Ended;
          break;
      }

      const clone = {
        ...cloneObject(auction),
        externalMetadata: {},
      };

      this.auctions[viewState.value].push(clone);
      fetchNFTs.push(
        Promise.all([
          this.loadExternalMetadata(cache, clone),
          this.loadAuctionExtended(cache, clone),
        ]),
      );
    });
    Promise.all(fetchNFTs).then(() => {
      this.loaded = true;
    });
  }
}
