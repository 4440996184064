





























































































































































































































































import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
@Component({
  components: { SimilarItem: () => import('@/views/shop/components/SimilarItem.vue') },
})
export default class MarketIndex extends Vue {
  @Ref('my-modal') myModal: any;

  get item() {
    let i = this.$route.params.id as any;
    return {
      id: i,
      name: 'CryptoPunk #9337',
      description: 'This is a description for item.',
      price: {
        fiat: 229292,
        eth: 54.8,
        sol: 1208.92,
      },
      creator: {
        id: i,
        name: 'Creator ' + i,
        wallet_addr: '0x' + i.toString(16),
        avatar:
          'https://lh3.googleusercontent.com/wl09kAQ8Zr_9rMmJM6hquCMWctYAgiQIOCnCvuHkabkaUKoC2hHq_qR2_5Re_Kq9j5ayjkqUnI19sHjKwvD8j8lyfZcUP_Meq8Q9=w600',
      },
      owner: {
        id: i,
        name: 'PJLuckyTrader',
        wallet_addr: '0xdf612c887e88f8e87ac0d2aa04ffd91f0556cfc0',
        avatar:
          'https://lh3.googleusercontent.com/JPJFg7AlJwL1CE8zJX6quHds9DtJg0VZmt-9qtin95rSAxV2IJKqOmoH3hB1kiJjByBqRmL_EeQBGr1uYGtsnaM4fTdwi2o32y5eNQ=s0',
      },
      media: {
        thumbnail_src:
          'https://lh3.googleusercontent.com/wl09kAQ8Zr_9rMmJM6hquCMWctYAgiQIOCnCvuHkabkaUKoC2hHq_qR2_5Re_Kq9j5ayjkqUnI19sHjKwvD8j8lyfZcUP_Meq8Q9=w600',
        image_src:
          'https://lh3.googleusercontent.com/wl09kAQ8Zr_9rMmJM6hquCMWctYAgiQIOCnCvuHkabkaUKoC2hHq_qR2_5Re_Kq9j5ayjkqUnI19sHjKwvD8j8lyfZcUP_Meq8Q9=w600',
        video_src:
          'https://lh3.googleusercontent.com/wl09kAQ8Zr_9rMmJM6hquCMWctYAgiQIOCnCvuHkabkaUKoC2hHq_qR2_5Re_Kq9j5ayjkqUnI19sHjKwvD8j8lyfZcUP_Meq8Q9=w600',
        object_src:
          'https://lh3.googleusercontent.com/wl09kAQ8Zr_9rMmJM6hquCMWctYAgiQIOCnCvuHkabkaUKoC2hHq_qR2_5Re_Kq9j5ayjkqUnI19sHjKwvD8j8lyfZcUP_Meq8Q9=w600',
      },
      metadata: [
        { key: 'Accessory', value: 'Earring' },
        { key: 'Accessory', value: 'Luxurious Beard' },
        { key: 'Accessory', value: 'Mohawk' },
        { key: 'Type', value: 'Male' },
      ],
      tags: [i, i + 1, i + 2],
      analytics: {
        views: i * 10,
      },
      history: [
        {
          id: i,
          name: 'Owner ' + i,
          avatar:
            'https://lh3.googleusercontent.com/wl09kAQ8Zr_9rMmJM6hquCMWctYAgiQIOCnCvuHkabkaUKoC2hHq_qR2_5Re_Kq9j5ayjkqUnI19sHjKwvD8j8lyfZcUP_Meq8Q9=w600',
          action: 'purchase',
          wallet_addr: '0x' + i.toString(16),
          timestamp: new Date().getTime(),
        },
        {
          id: i,
          name: 'Owner ' + i,
          avatar:
            'https://lh3.googleusercontent.com/wl09kAQ8Zr_9rMmJM6hquCMWctYAgiQIOCnCvuHkabkaUKoC2hHq_qR2_5Re_Kq9j5ayjkqUnI19sHjKwvD8j8lyfZcUP_Meq8Q9=w600',
          action: 'purchase',
          wallet_addr: '0x' + i.toString(16),
          timestamp: new Date().getTime(),
        },
        {
          id: i,
          name: 'Owner ' + i,
          avatar:
            'https://lh3.googleusercontent.com/wl09kAQ8Zr_9rMmJM6hquCMWctYAgiQIOCnCvuHkabkaUKoC2hHq_qR2_5Re_Kq9j5ayjkqUnI19sHjKwvD8j8lyfZcUP_Meq8Q9=w600',
          action: 'purchase',
          wallet_addr: '0x' + i.toString(16),
          timestamp: new Date().getTime(),
        },
        {
          id: i,
          name: 'Owner ' + i,
          avatar:
            'https://lh3.googleusercontent.com/wl09kAQ8Zr_9rMmJM6hquCMWctYAgiQIOCnCvuHkabkaUKoC2hHq_qR2_5Re_Kq9j5ayjkqUnI19sHjKwvD8j8lyfZcUP_Meq8Q9=w600',
          action: 'transfer',
          wallet_addr: '0x' + i.toString(16),
          timestamp: new Date().getTime(),
        },
        {
          id: i,
          name: 'Owner ' + i,
          avatar:
            'https://lh3.googleusercontent.com/wl09kAQ8Zr_9rMmJM6hquCMWctYAgiQIOCnCvuHkabkaUKoC2hHq_qR2_5Re_Kq9j5ayjkqUnI19sHjKwvD8j8lyfZcUP_Meq8Q9=w600',
          action: 'mint',
          wallet_addr: '0x' + i.toString(16),
          timestamp: new Date().getTime(),
        },
      ],
    };
  }

  /**
   * Formats numbers nicely with K,M etc with precision < 1k
   * @param num
   * @param digits
   * @returns {string|string}
   */
  nFormatter(num: any, digits: any) {
    const lookup = [
      { value: 1, symbol: '' },
      { value: 1e3, symbol: 'K' },
      { value: 1e6, symbol: 'M' },
      { value: 1e9, symbol: 'G' },
      { value: 1e12, symbol: 'T' },
      { value: 1e15, symbol: 'P' },
      { value: 1e18, symbol: 'E' },
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var item = lookup
      .slice()
      .reverse()
      .find(function (item) {
        return num >= item.value;
      }) as any;

    if (item.value === 1) {
      return item
        ? (num / item.value).toFixed(digits).replace(rx, '$1') + item.symbol
        : '0';
    }

    return item ? (num / item.value).toFixed(0).replace(rx, '$1') + item.symbol : '0';
  }

  showModalMakeOffer() {
    // this.isShowModalMakeOffer = true
    return true;
  }

  showModal() {
    this.myModal.show();
  }

  hideModal() {
    this.myModal.hide();
  }

  toggleModal() {
    // We pass the ID of the button that we want to return focus to
    // when the modal has hidden
    this.myModal.toggle('#toggle-btn');
  }
  mounted() {
    const el = document.getElementById('history') as any;
    const media = document.getElementById('media') as any;

    console.log('Setting style', media.clientHeight);
    el.style.maxHeight = `${media.clientHeight}px`;
    el.height = `${media.clientHeight}px`;
  }
}
