<template>
    <div class="col-12">
        <div class="row mt-3 bg-card p-3">
            <div class="col-md-3 col-lg-3 col-sm-12 img_container">
                <img :src="profile.avatar_url" class="img-fluid" alt="player avatar" />
            </div>
            <div class="col-md-9 col-lg-9 col-sm-12">
                <table class="table table-responsive">
                    <tbody>
                        <tr>
                            <td>Username:</td>
                            <td>{{ profile.name }}</td>
                        </tr>
                        <tr>
                            <td>Wallet Addr:</td>
                            <td>
                                <code>{{ profile.wallet_addr }}</code>
                            </td>
                        </tr>
                        <tr>
                            <td>Guild:</td>
                            <td>-</td>
                        </tr>
                        <tr>
                            <td>Last Login:</td>
                            <td>{{ humanDate }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({ name: 'PlayerCard' })
export default class PlayerCard extends Vue {
    @Prop({ type: Object, required: true }) profile
	
    get humanDate() {
        return moment.duration(-moment(this.profile.last_login_at).unix()).humanize(true)
    }
}
</script>

<style scoped>
h1 {
    font-family: 'Prime-Light', sans-serif;
    color: #0a9fb9;
    font-size: 2rem;
}

h3 {
    color: white;
    font-size: 1.2rem;
}

td {
    color: white;
    font-size: 1.2rem;
}

.img_container {
    text-align: center;
    vertical-align: middle;
}
</style>
