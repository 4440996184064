import { MetaState } from '@/views/shop/types';

export const makeSetter =
  (state: MetaState, filtered?: { [key: string]: (obj: any) => boolean }): Function =>
  (prop: string, key: string, value: any) => {
    let alreadyHasInState: boolean;

    switch (prop) {
      case 'metadata':
        state.metadata.push(value);
        break;
      case 'store':
        state[prop] = value;
        break;
      case 'storeIndexer':
        state.storeIndexer = state.storeIndexer.filter(
          (p: any) => p.info.page.toNumber() != value.info.page.toNumber(),
        );
        state.storeIndexer.push(value);
        state.storeIndexer = state.storeIndexer.sort((a: any, b: any) =>
          a.info.page.sub(b.info.page).toNumber(),
        );
        break;
      case 'packCardsByPackSet':
        if (!state.packCardsByPackSet[key]) {
          state.packCardsByPackSet[key] = [];
        }
        alreadyHasInState = state.packCardsByPackSet[key].some(
          ({ pubkey }: { pubkey: string }) => pubkey === value.pubkey,
        );
        if (!alreadyHasInState) {
          state.packCardsByPackSet[key].push(value);
        }
        break;
      case 'vaults':
      case 'auctions':
        if (filtered?.[prop] && !filtered[prop](key)) {
          return;
        }
        state[prop][key] = value;
        break;
      default:
        //@ts-ignore
        state[prop][key] = value;
    }

    return state;
  };
