























import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({
  name: 'SelectCategory',
})
export default class SelectCategory extends Vue {
  @Prop(Object) categories: any;
}
