<template>
    <div class="col-12">
        <div class="row mt-3">
            <div class="bg-card col-12">
                <div class="row mt-3 mb-5">
                    <div class="col-md-6 col-sm-12">
                        <input class="form-control-lg" placeholder="Search" v-model="search" />
                    </div>
                    <div class="col-lg-2 col-sm-4">
                        <button v-on:click="toggle2D" :class="`btn btn-block ${show2D ? 'btn-primary' : 'btn-primary-outline'}`">2D</button>
                    </div>
                    <div class="col-lg-2 col-sm-4">
                        <button v-on:click="toggle3D" :class="`btn btn-block ${show3D ? 'btn-primary' : 'btn-primary-outline'}`">3D</button>
                    </div>
                    <div class="col-lg-2 col-sm-4">
                        <button v-on:click="toggleWearable" :class="`btn btn-block ${showWearable ? 'btn-primary' : 'btn-primary-outline'}`">Wearable</button>
                    </div>
                </div>

                <div class="not-found" v-if="filtered.length === 0">
                    <i>No Results found</i>
                    <p class="mt-2">
                        <router-link class="brows-mktplace" to="/marketplace">Browse Marketplace</router-link>
                    </p>
                </div>

                <div class="row">
                    <div class="col-2 col-lg-2 col-sm-4 col-md-3 mb-3" v-for="item in filtered" :key="item.id">
                        <InventoryItem class="" :item="item"></InventoryItem>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Profile from '@/api/profile'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
    name: 'Inventory',
    components: {
        InventoryItem: () => import('./InventoryItem'),
    },
})
export default class Inventory extends Vue {
    @Prop(String) user
    search = ''
    show2D = true
    show3D = true
    showWearable = true
    items = []

    get filtered() {
        return this.items.filter(item => {
            if (!this.show2D && (item.item.display_type === 0 || item.item.sprite_uri.indexOf('.glb') === -1)) {
                return false
            }
            if (!this.show3D && (item.item.display_type === 1 || item.item.sprite_uri.indexOf('.glb') !== -1)) {
                return false
            }

            if (!this.showWearable && item.item.item_type !== 5) {
                return false
            }

            return this.search !== '' ? item.item.name.toLowerCase().includes(this.search.toLowerCase()) : true
        })
    }
	
    getInventory() {
        Profile.inventory(this.user).then(r => {
            console.log('resp', r.data)
            this.items = r.data
        })
    }

    toggle2D() {
        this.show2D = !this.show2D
    }

    toggle3D() {
        this.show3D = !this.show3D
    }

    toggleWearable() {
        this.showWearable = !this.showWearable
    }

    mounted() {
        this.getInventory()
    }
}
</script>

<style scoped>
h1 {
    font-family: 'Prime-Light', sans-serif;
    color: #0a9fb9;
    font-size: 2rem;
}

.brows-mktplace {
    color: #0a9fb9;
    text-decoration: underline !important;
}

.not-found {
    padding-top: 3vh;
    height: 10vh;
    color: white;
    text-align: center;
    font-size: 1.2rem;
}
</style>
