<template>
  <img v-if="hasAvt" :src="creator.image" alt="avatar of creator"/>
  <div v-else
       style="border-radius: 50px"
       class="overflow-hidden d-inline-block p-0 m-0"
       :style="{ width: pixel, height: pixel }"
  >
    <svg height="100" version="1.1" width="100" xmlns="http://www.w3.org/2000/svg"
         xmlns:xlink="http://www.w3.org/1999/xlink" style="overflow: hidden; position: relative;">
      <desc style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);">Created with Raphaël 2.3.0</desc>
      <defs style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);"></defs>
      <rect x="0" y="0" width="64" height="64" rx="0" ry="0" fill="#236fe1" stroke="none"
            style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);"></rect>
      <rect x="0" y="0" width="64" height="64" rx="0" ry="0" fill="#018e8c" stroke="none"
            transform="matrix(0.7822,-0.6231,0.6231,0.7822,-8.031,22.367)"
            style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);"></rect>
      <rect x="0" y="0" width="64" height="64" rx="0" ry="0" fill="#f93301" stroke="none"
            transform="matrix(-0.0256,0.9997,-0.9997,-0.0256,97.3509,0.2802)"
            style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);"></rect>
      <rect x="0" y="0" width="64" height="64" rx="0" ry="0" fill="#18a5f2" stroke="none"
            transform="matrix(0.4808,0.8768,-0.8768,0.4808,91.2224,7.2812)"
            style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);"></rect>
    </svg>
  </div>
</template>

<script>
import {Vue, Component, Prop} from "vue-property-decorator";

@Component({
  name: "MetaAvatar"
})
export default class MetaAvatar extends Vue {
  @Prop(Object) creator
  @Prop(Number) size

  get hasAvt() {
    return !!this.creator?.image
  }

  get pixel() {
    return `${this.size || 64}px`
  }
}
</script>

<style scoped>

</style>