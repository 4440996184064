import { Connection, programs } from '@metaplex/js';
import {
  PublicKey,
  SystemProgram,
  SYSVAR_RENT_PUBKEY,
  TransactionInstruction,
} from '@solana/web3.js';

import { WalletAdapter } from '@solana/wallet-adapter-base/src/adapter';
import { WalletNotConnectedError } from '@solana/wallet-adapter-base';
import { serialize } from 'borsh';
import { signInstructions } from '@/views/shop/actions/transactions';
import { METADATA_PROGRAM_ID, toPublicKey } from '@/views/shop/utils';
import { getAuctionWinnerTokenTypeTracker } from '@/views/shop/actions/auctions';
import {
  AmountRange,
  SafetyDepositConfig,
  ValidateSafetyDepositBoxV2Args,
} from '@/views/shop/actions/vaultDefines';
import BN from 'bn.js';
import { TupleNumericType } from '@metaplex-foundation/mpl-core';
import { WinningConfigType } from '@metaplex-foundation/mpl-metaplex';
import {
  ParticipationConfigV2,
  ParticipationStateV2,
} from '@/views/shop/actions/auctions-v2/vaultDefines';

export const validateDepositBox = async ({
  connection,
  wallet,
  vault,
  nft,
  store,
  metadata,
  tokenStore,
}: {
  connection: Connection;
  wallet: WalletAdapter;
  store: PublicKey;
  vault: PublicKey;
  nft: PublicKey;
  metadata: PublicKey;
  tokenStore: PublicKey;
}) => {
  if (!wallet.publicKey) throw new WalletNotConnectedError();
  const storeId = await programs.metaplex.Store.getPDA(wallet.publicKey);
  const auctionPDA = await programs.auction.Auction.getPDA(vault);
  const auctionManagerPDA = await programs.metaplex.AuctionManager.getPDA(auctionPDA);
  const loadedVault = await programs.vault.Vault.load(connection, vault);
  const sdb = await loadedVault.getSafetyDepositBoxes(connection);

  const whitelistedCreator = await programs.metaplex.WhitelistedCreator.getPDA(
    toPublicKey(store),
    wallet.publicKey,
  );

  const safetyDepositConfigKey = await programs.metaplex.SafetyDepositConfig.getPDA(
    auctionManagerPDA,
    sdb[0].pubkey,
  );
  const edition = await programs.metadata.Edition.getPDA(toPublicKey(nft));
  const originalAuthority = await PublicKey.findProgramAddress(
    [Buffer.from('metaplex'), auctionPDA.toBuffer(), toPublicKey(metadata).toBuffer()],
    toPublicKey('p1exdMJcjVao65QdewkaZRUnU6VPSXhus9n2GzWfh98'),
  );

  const safetyDepositConfigArgs = await loadedVault.getSafetyDepositBoxes(connection);
  const value = new ValidateSafetyDepositBoxV2Args(
    new SafetyDepositConfig({
      directArgs: {
        auctionManager: SystemProgram.programId.toBase58(),
        order: new BN(safetyDepositConfigArgs[0].data.order),
        amountRanges: [],
        amountType: TupleNumericType.U8,
        lengthType: TupleNumericType.U8,
        winningConfigType: WinningConfigType.TokenOnlyTransfer,
        participationConfig: null,
        participationState: null,
      },
    }),
  );
  const data = Buffer.from(serialize(VALIDATE_SAFETY_DEPOSIT_SCHEMA, value));

  const keys = [
    {
      pubkey: toPublicKey(safetyDepositConfigKey),
      isSigner: false,
      isWritable: true,
    },
    {
      pubkey: await getAuctionWinnerTokenTypeTracker(auctionManagerPDA),
      isSigner: false,
      isWritable: true,
    },
    {
      pubkey: auctionManagerPDA,
      isSigner: false,
      isWritable: true,
    },
    {
      pubkey: toPublicKey(metadata),
      isSigner: false,
      isWritable: true,
    },
    {
      pubkey: originalAuthority[0],
      isSigner: false,
      isWritable: true,
    },
    {
      pubkey: whitelistedCreator,
      isSigner: false,
      isWritable: false,
    },
    {
      pubkey: storeId,
      isSigner: false,
      isWritable: false,
    },
    {
      pubkey: sdb[0].pubkey,
      isSigner: false,
      isWritable: false,
    },
    {
      pubkey: toPublicKey(tokenStore),
      isSigner: false,
      isWritable: false,
    },
    {
      pubkey: toPublicKey(nft),
      isSigner: false,
      isWritable: false,
    },
    {
      pubkey: edition,
      isSigner: false,
      isWritable: false,
    },
    {
      pubkey: toPublicKey(vault),
      isSigner: false,
      isWritable: false,
    },
    {
      pubkey: wallet.publicKey,
      isSigner: true,
      isWritable: false,
    },
    {
      pubkey: wallet.publicKey,
      isSigner: true,
      isWritable: false,
    },

    {
      pubkey: wallet.publicKey,
      isSigner: true,
      isWritable: false,
    },
    {
      pubkey: toPublicKey(METADATA_PROGRAM_ID),
      isSigner: false,
      isWritable: false,
    },
    {
      pubkey: SystemProgram.programId,
      isSigner: false,
      isWritable: false,
    },
    {
      pubkey: SYSVAR_RENT_PUBKEY,
      isSigner: false,
      isWritable: false,
    },
  ];

  return signInstructions(connection, wallet, [
    new TransactionInstruction({
      keys,
      programId: programs.metaplex.MetaplexProgram.PUBKEY,
      data,
    }),
  ]);
};

const VALIDATE_SAFETY_DEPOSIT_SCHEMA = new Map<any, any>([
  [
    ValidateSafetyDepositBoxV2Args,
    {
      kind: 'struct',
      fields: [
        ['instruction', 'u8'],
        ['safetyDepositConfig', SafetyDepositConfig],
      ],
    },
  ],
  [
    AmountRange,
    {
      kind: 'struct',
      fields: [
        ['amount', 'u64'],
        ['length', 'u64'],
      ],
    },
  ],
  [
    SafetyDepositConfig,
    {
      kind: 'struct',
      fields: [
        ['key', 'u8'],
        ['auctionManager', 'pubkeyAsString'],
        ['order', 'u64'],
        ['winningConfigType', 'u8'],
        ['amountType', 'u8'],
        ['lengthType', 'u8'],
        ['amountRanges', [AmountRange]],
        ['participationConfig', { kind: 'option', type: ParticipationConfigV2 }],
        ['participationState', { kind: 'option', type: ParticipationStateV2 }],
      ],
    },
  ],
]);
