<template>
    <div>
        <canvas id="canvas" width="500px" height="500px"></canvas>
        <h3 class="state">
            State: <span class="st">{{ textState }}</span>
        </h3>
        <small v-show="timeElapsed !== ''">{{ timeElapsed }}</small>
    </div>
</template>

<script>
import moment from 'moment'

import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Component({ name: 'FortuneWheel' })
export default class FortuneWheel extends Vue {
    @Prop({ type: Array }) options
    @Prop({ type: [Object, Array] }) result
    @Prop({ type: Number }) state

    ctx = null
    img = ''
    interval = null
    startTime = -1
    timeElapsed = ''
    startAngle = 0
    arc = Math.PI
    spinTimeout = null
    spinArcStart = 10
    spinTime = 0
    spinTimeTotal = 3000
    get textState() {
        switch (this.state) {
            case 0:
                return 'Idle'
            case 1:
            case 2:
                return 'Spinning'
            case 3:
                return 'Result'
            default:
                return 'Unknown'
        }
    }
    @Watch('result')
    changeResult() {
        console.log('Wheel Result in', this.result)
        //Wheel is stopped within spinWheel func when it lands on our result
    }

    @Watch('options')
    changeOption() {
        this.buildWheel()
    }

    @Watch('state')
    changeOption() {
        console.log('state change', this.state)
        switch (this.state) {
            case 0:
                this.onIdle()
                break
            case 1:
                this.onStart()
                break
            case 2:
                this.onContinueSpin()
                break
        }
    }

    buildWheel() {
        this.arc = Math.PI / (this.options.length / 2)
        this.drawRouletteWheel()
    }

    byte2Hex(n) {
        var nybHexString = '0123456789ABCDEF'
        return String(nybHexString.substr((n >> 4) & 0x0f, 1)) + nybHexString.substr(n & 0x0f, 1)
    }

    RGB2Color(r, g, b) {
        return '#' + this.byte2Hex(r) + this.byte2Hex(g) + this.byte2Hex(b)
    }

    getColor(item) {
        if (item % 2 == 0) {
            return '#26b6d4'
        } else {
            return '#080b1f'
        }

        // var phase = 0;
        // var center = 128;
        // var width = 127;
        // var frequency = Math.PI * 2 / maxitem;
        //
        // const red = Math.sin(frequency * item + 2 + phase) * width + center;
        // const green = Math.sin(frequency * item + 0 + phase) * width + center;
        // const blue = Math.sin(frequency * item + 4 + phase) * width + center;
        //
        // return this.RGB2Color(red, green, blue);
    }

    drawRouletteWheel() {
        var canvas = document.getElementById('canvas')
        if (!canvas.getContext) {
            console.log('Unable to get canvas context')
            return
        }

        var outsideRadius = 200
        var textRadius = 160
        var insideRadius = 90

        this.ctx = canvas.getContext('2d')
        this.ctx.clearRect(0, 0, 500, 500)

        // if (this.result == null) {
        //     this.ctx.font = 'bold 26px Helvetica, Arial';
        //     this.ctx.fillStyle = '#26b6d4';
        //     this.ctx.fillText(this.textState.toUpperCase(), 250 - this.ctx.measureText(this.textState.toUpperCase()).width / 2, 250 + 10);
        // }

        this.ctx.font = 'bold 16px Helvetica, Arial'
        for (let i = 0; i < this.options.length; i++) {
            let angle = this.startAngle + i * this.arc
            this.ctx.fillStyle = this.getColor(i, this.options.length)
            // this.ctx.fillStyle = "transparent";

            this.ctx.beginPath()
            this.ctx.arc(250, 250, outsideRadius, angle, angle + this.arc, false)
            this.ctx.arc(250, 250, insideRadius, angle + this.arc, angle, true)
            this.ctx.fill()

            //Outline
            this.ctx.beginPath()
            this.ctx.arc(250, 250, outsideRadius, angle, angle + this.arc, false)
            this.ctx.arc(250, 250, insideRadius, angle + this.arc, angle, true)
            this.ctx.strokeStyle = 'black'
            this.ctx.lineWidth = 3
            this.ctx.stroke()

            this.ctx.save()
            this.ctx.shadowBlur = 0
            this.ctx.fillStyle = 'white'
            this.ctx.translate(250 + Math.cos(angle + this.arc / 2) * textRadius, 250 + Math.sin(angle + this.arc / 2) * textRadius)
            this.ctx.rotate(angle + this.arc / 2 + Math.PI / 2)
            let text = this.options[i].text
            this.ctx.fillText(text, -this.ctx.measureText(text).width / 2, 0)
            this.ctx.restore()
        }

        //Arrow
        this.ctx.fillStyle = 'white'
        this.ctx.beginPath()
        this.ctx.moveTo(250 - 4, 250 - (outsideRadius + 5))
        this.ctx.lineTo(250 + 4, 250 - (outsideRadius + 5))
        this.ctx.lineTo(250 + 4, 250 - (outsideRadius - 5))
        this.ctx.lineTo(250 + 9, 250 - (outsideRadius - 5))
        this.ctx.lineTo(250 + 0, 250 - (outsideRadius - 13))
        this.ctx.lineTo(250 - 9, 250 - (outsideRadius - 5))
        this.ctx.lineTo(250 - 4, 250 - (outsideRadius - 5))
        this.ctx.lineTo(250 - 4, 250 - (outsideRadius + 5))
        this.ctx.fill()
    }

    spin() {
        this.spinAngleStart = Math.random() * 10 + 10
        this.spinTime = 0
        this.rotateWheel()
    }

    rotateWheel() {
        this.spinTime = (this.spinTime + 30) % 360
        let spinAngle = this.spinAngleStart //Infinite spin
        //If result set ease it in
        // if (this.result > 0) {
        //     let easeAngle = this.spinAngleStart - this.easeOut(this.spinTime, 0, this.spinAngleStart, this.spinTimeTotal);
        //     if (easeAngle > 0) {
        //         spinAngle = easeAngle;
        //     }
        // }

        //TODO change to be based off current Angle (360 deg, 1pt per incr)
        if (this.result != null && parseInt(this.result.id) === parseInt(this.currentPrize().id)) {
            console.log('Landing on result: ', this.currentPrize())
            this.stopRotateWheel()
            return
        }

        this.startAngle += (spinAngle * Math.PI) / 180
        // console.log("angle", {
        //     startAngle: this.startAngle,
        //     // spinAngle: spinAngle,
        //     result: this.result,
        //     // spinTime: this.spinTime,
        //     currentAngle: this.currentAngle(),
        //     prize: parseInt(this.currentPrize().id),
        // });

        this.drawRouletteWheel()
        this.spinTimeout = setTimeout(this.rotateWheel, 30)
    }

    currentAngle() {
        return parseInt(((((this.startAngle * 180) / Math.PI + 90) % 360) + 1).toFixed(0)) //Degrees (360)
    }

    currentPrize() {
        const degrees = (this.startAngle * 180) / Math.PI + 90
        const arcd = (this.arc * 180) / Math.PI
        const index = Math.floor((360 - (degrees % 360)) / arcd)
        return this.options[index]
    }

    stopRotateWheel() {
        clearTimeout(this.spinTimeout)
        this.ctx.save()
        this.ctx.font = 'bold 30px Helvetica, Arial'
        const text = this.currentPrize().text
        this.ctx.fillText(text, 250 - this.ctx.measureText(text).width / 2, 250 + 10)
        this.ctx.restore()
    }

    easeOut(t, b, c, d) {
        let ts = (t /= d) * t
        let tc = ts * t
        return b + c * (tc + -3 * ts + 3 * t)
    }

    onStart() {
        this.onIdle() //Reset to idle incase we werent

        this.startTime = new Date().getTime()
        this.spin()
        setTimeout(() => {
            console.log('Checking continue spin', this.state)
            if (this.state == 1) this.onContinueSpin()
        }, 2000)

        this.timeElapsed = moment(this.startTime).fromNow()
        this.interval = setInterval(() => {
            this.timeElapsed = moment(this.startTime).fromNow()
        }, 1000)
    }

    onIdle() {
        this.timeElapsed = ''
        this.spinTime = 0
        clearInterval(this.interval)
        clearTimeout(this.spinTimeout)
    }

    onContinueSpin() {
        console.log('onContinueSpin')
    }

    onResult() {
        clearInterval(this.interval)
        clearTimeout(this.spinTimeout)
    }

    beforeMount() {
        // this.onIdle();
        moment.relativeTimeThreshold('ss', 3) // setter
    }
    
    mounted() {
        this.buildWheel()
    }
}
</script>

<style scoped>
#canvas {
    height: 500px;
    width: 500px;
}

.state .st {
    text-transform: uppercase;
    color: #26b6d4;
}

h3 {
    margin-bottom: 0;
}
</style>
