import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { getEmptyMetaState } from '@/views/shop/store/metaState';
import { makeSetter } from '@/views/shop/contexts/meta/loadAccount';
import { AccountInfo, Connection, PublicKey } from '@solana/web3.js';
import { AUCTION_ID, METAPLEX_ID, toPublicKey, VAULT_ID } from '@/views/shop/utils';
import { processMetaplexAccounts } from '@/views/shop/actions/processDecodeMetaplexAccounts';
import { processAuctionAccounts } from '@/views/shop/actions/processDecodeAuctionAccounts';
import { processVaultAccount } from '@/views/shop/actions/processDecodeVaultAccounts';
import { MetaState } from '@/views/shop/types';
import { Buffer } from 'buffer';

class State {
  metaState = getEmptyMetaState();
  initialized = false;
}

const getters: GetterTree<State, any> = {};
const mutations = <MutationTree<State>>{
  initialized(State) {
    State.initialized = true;
  },
};
const actions = <ActionTree<State, any>>{
  async fetchMetaplexAccountsInitIndexer(State) {
    const updateState = makeSetter(State.state.metaState);
    const handler = (item: { pubkey: PublicKey; account: AccountInfo<Buffer> }) => {
      processMetaplexAccounts(
        {
          account: item.account,
          pubkey: item.pubkey.toBase58(),
        },
        updateState,
      );
    };
    const connection: Connection = State.rootState.connectionModule.connection;
    const list = await connection.getProgramAccounts(toPublicKey(METAPLEX_ID));
    return delayHandleByBatch(list, handler);
  },
  async fetchAuctionAccountsInitSaleList(State) {
    const connection: Connection = State.rootState.connectionModule.connection;
    const metaState = State.state.metaState;
    if (!metaState.auctionCaches || !Object.keys(metaState.auctionCaches).length) {
      return;
    }
    const indexed = alreadyIndexedAuctions(metaState);
    const filtered = {
      auctions: (pubkey: string) => {
        return !!indexed[pubkey];
      },
    };
    const handler = (item: any) => {
      processAuctionAccounts(
        {
          account: item.account,
          pubkey: item.pubkey.toBase58(),
        },
        updateState,
      );
    };
    const updateState = makeSetter(metaState, filtered);
    const list = await connection.getProgramAccounts(toPublicKey(AUCTION_ID));
    return delayHandleByBatch(list, handler);
  },
  async fetchVaultAccountsInitSaleList(State) {
    const connection: Connection = State.rootState.connectionModule.connection;
    const metaState = State.state.metaState;
    if (!metaState.auctionCaches || !Object.keys(metaState.auctionCaches).length) {
      return;
    }
    const indexed = alreadyIndexedVaults(metaState);
    const filtered = {
      vaults: (pubkey: string) => {
        return !!indexed[pubkey];
      },
    };
    const handler = (item: any) => {
      processVaultAccount(
        {
          account: item.account,
          pubkey: item.pubkey.toBase58(),
        },
        updateState,
      );
    };
    const updateState = makeSetter(State.state.metaState, filtered);
    const list = await connection.getProgramAccounts(toPublicKey(VAULT_ID));
    return delayHandleByBatch(list, handler);
  },
};

const MarketplaceAccountModule = {
  namespaced: true,
  getters,
  state: new State(),
  mutations,
  actions,
};

export default MarketplaceAccountModule;

const alreadyIndexedAuctions = (state: MetaState) => {
  return Object.values(state.auctionCaches).reduce(
    (hash: { [key: string]: any }, val: any) => {
      hash[val.info.auction] = true;

      return hash;
    },
    {},
  );
};

const alreadyIndexedVaults = (state: MetaState) => {
  return Object.values(state.auctionCaches).reduce(
    (hash: { [key: string]: any }, val: any) => {
      hash[val.info.vault] = true;

      return hash;
    },
    {},
  );
};

// setTimeout to allow main process continue, move next batch to message queue waiting for a little bit for better fps/ux
const delayHandleByBatch = async (
  list: any[],
  handler: (item: any) => any,
  batch = 1000,
  timeout = 20,
): Promise<any> => {
  if (!list.length) {
    return;
  }

  const to = batch < list.length ? batch : list.length;
  list.splice(0, to).forEach(handler);
  await new Promise<void>(resolve => {
    setTimeout(() => {
      resolve();
    }, timeout);
  });
  return delayHandleByBatch(list, handler, batch, timeout);
};
