export function useWindowDimensions(context: any) {
  let windowDimensions = getWindowDimensions()

  function handleResize() {
    windowDimensions = getWindowDimensions()
  }

  if (context && context.$nextTick) {
    window.addEventListener('resize', handleResize);
    context.$nextTick(() => {
      window.removeEventListener('resize', handleResize);
    })
  }

  return windowDimensions;
}

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}
