<template>
    <div class="nft-type v_dark section_gradiant_dark">
        <div class="row text-center small_space benefit_wrap">
            <div class="col-lg-4 col-md-4 col-6">
                <div
                    :class="`benefit_box animation ${selected_type === 1 ? 'selected' : ''}`"
                    data-animation="fadeInUp"
                    data-animation-delay="0.1s"
                    v-on:click="selected_type = 1"
                >
                    <img src="@/assets/images/tower.png" alt="secure" />
                    <h6>Object / Item</h6>
                </div>
            </div>
            <div class="col-lg-4 col-md-4 col-6">
                <div
                    :class="`benefit_box animation ${selected_type === 2 ? 'selected' : ''}`"
                    data-animation="fadeInUp"
                    data-animation-delay="0.2s"
                    v-on:click="selected_type = 2"
                >
                    <img src="@/assets/images/tower.png" alt="token" />
                    <h6>Wall Decoration</h6>
                </div>
            </div>
            <div class="col-lg-4 col-md-4 col-6">
                <div
                    :class="`benefit_box animation ${selected_type === 3 ? 'selected' : ''}`"
                    data-animation="fadeInUp"
                    data-animation-delay="0.2s"
                    v-on:click="selected_type = 3"
                >
                    <img src="@/assets/images/tower.png" alt="token" />
                    <h6>Sprite / Model</h6>
                </div>
            </div>
        </div>

        <div class="row text-center small_space benefit_wrap mt-5">
            <div class="sub-type-decoration row col-12" v-show="selected_type === 1">
                <div class="col-12">
                    <h5>Select Sub-Type</h5>
                </div>
                <div class="col-lg-3 col-md-4 col-6 offset-2">
                    <div
                        :class="`benefit_box animation ${selected_sub_type === 0 ? 'selected' : ''}`"
                        data-animation="fadeInUp"
                        data-animation-delay="0.3s"
                        v-on:click="selected_sub_type = 0"
                    >
                        <img src="@/assets/images/payment.png" alt="payment" />
                        <h6>Decoration</h6>
                    </div>
                </div>
                <div class="col-lg-3 col-md-4 col-6">
                    <div
                        :class="`benefit_box animation ${selected_sub_type === 1 ? 'selected' : ''}`"
                        data-animation="fadeInUp"
                        data-animation-delay="0.3s"
                        v-on:click="selected_sub_type = 1"
                    >
                        <img src="@/assets/images/payment.png" alt="payment" />
                        <h6>Seat</h6>
                    </div>
                </div>
                <div class="col-lg-3 col-md-4 col-6">
                    <div
                        :class="`benefit_box animation ${selected_sub_type === 2 ? 'selected' : ''}`"
                        data-animation="fadeInUp"
                        data-animation-delay="0.3s"
                        v-on:click="selected_sub_type = 2"
                    >
                        <img src="@/assets/images/payment.png" alt="payment" />
                        <h6>Door</h6>
                    </div>
                </div>
                <div class="col-lg-3 col-md-4 col-6 mt-3 offset-lg-2">
                    <div
                        :class="`benefit_box animation disabled ${selected_sub_type === 3 ? 'selected' : ''}`"
                        data-animation="fadeInUp"
                        data-animation-delay="0.3s"
                        v-on:click="selected_sub_type = 3"
                    >
                        <img src="@/assets/images/payment.png" alt="payment" />
                        <h6>Animated Decoration</h6>
                        <h6>
                            <small><i>(Coming Soon)</i></small>
                        </h6>
                    </div>
                </div>
                <div class="col-lg-3 col-md-4 col-6 mt-3">
                    <div
                        :class="`benefit_box animation disabled ${selected_sub_type === 4 ? 'selected' : ''}`"
                        data-animation="fadeInUp"
                        data-animation-delay="0.3s"
                        v-on:click="selected_sub_type = 4"
                    >
                        <img src="@/assets/images/payment.png" alt="payment" />
                        <h6>Gambler</h6>
                        <h6>
                            <small><i>(Coming Soon)</i></small>
                        </h6>
                    </div>
                </div>
                <div class="col-lg-3 col-md-4 col-6 mt-3">
                    <div
                        :class="`benefit_box animation disabled ${selected_sub_type === 5 ? 'selected' : ''}`"
                        data-animation="fadeInUp"
                        data-animation-delay="0.3s"
                        v-on:click="selected_sub_type = 5"
                    >
                        <img src="@/assets/images/payment.png" alt="payment" />
                        <h6>Teleporter</h6>
                        <h6>
                            <small><i>(Coming Soon)</i></small>
                        </h6>
                    </div>
                </div>
            </div>

            <div class="sub-type-decoration row col-12" v-show="selected_type === 2">
                <div class="col-12">
                    <h5>Select Sub-Type</h5>
                </div>
                <div class="col-lg-3 col-md-4 col-6 offset-lg-2">
                    <div
                        :class="`benefit_box animation ${selected_sub_type === 2 ? 'selected' : ''}`"
                        data-animation="fadeInUp"
                        data-animation-delay="0.3s"
                        v-on:click="selected_sub_type = 2"
                    >
                        <img src="@/assets/images/payment.png" alt="payment" />
                        <h6>Image</h6>
                    </div>
                </div>
                <div class="col-lg-3 col-md-4 col-6">
                    <div
                        :class="`benefit_box animation ${selected_sub_type === 0 ? 'selected' : ''}`"
                        data-animation="fadeInUp"
                        data-animation-delay="0.3s"
                        v-on:click="selected_sub_type = 0"
                    >
                        <img src="@/assets/images/payment.png" alt="payment" />
                        <h6>Video</h6>
                    </div>
                </div>
                <div class="col-lg-3 col-md-4 col-6">
                    <div
                        :class="`benefit_box animation ${selected_sub_type === 1 ? 'selected' : ''}`"
                        data-animation="fadeInUp"
                        data-animation-delay="0.3s"
                        v-on:click="selected_sub_type = 1"
                    >
                        <img src="@/assets/images/payment.png" alt="payment" />
                        <h6>Audio</h6>
                    </div>
                </div>
            </div>

            <div class="sub-type-sprite row col-12" v-show="selected_type === 3">
                <div class="col-12">
                    <h5>Select Sub-Type</h5>
                </div>
                <div class="col-lg-3 col-md-4 col-6 offset-lg-2">
                    <div
                        :class="`benefit_box animation ${selected_sub_type === 0 ? 'selected' : ''}`"
                        data-animation="fadeInUp"
                        data-animation-delay="0.2s"
                        v-on:click="selected_sub_type = 0"
                    >
                        <img src="@/assets/images/tower.png" alt="token" />
                        <h6>Player Sprite</h6>
                    </div>
                </div>
                <div class="col-lg-3 col-md-4 col-6">
                    <div
                        :class="`benefit_box animation ${selected_sub_type === 1 ? 'selected' : ''}`"
                        data-animation="fadeInUp"
                        data-animation-delay="0.2s"
                        v-on:click="selected_sub_type = 1"
                    >
                        <img src="@/assets/images/tower.png" alt="token" />
                        <h6>Profile Avatar</h6>
                    </div>
                </div>
                <div class="col-lg-3 col-md-4 col-6">
                    <div
                        :class="`benefit_box animation disabled ${selected_sub_type === 12 ? 'selected' : ''}`"
                        data-animation="fadeInUp"
                        data-animation-delay="0.2s"
                        v-on:click="selected_sub_type = 12"
                    >
                        <img src="@/assets/images/tower.png" alt="token" />
                        <h6>Pet (Coming Soon)</h6>
                    </div>
                </div>
            </div>
        </div>

        <div class="row text-center mt-5">
            <div class="col-12">
                <button v-on:click="typeSelected" class="btn btn-lg btn-block">Next: {{ item_types[selected_type] }}</button>
            </div>
        </div>
    </div>
</template>

<script>
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({ name: 'RoomCard' })
export default class ItemType extends Vue {
    selected_type = 1
    selected_sub_type = -1
    item_types = {
        1: 'Object / Item',
        2: 'Wall Decoration',
        3: 'Sprite / Model',
    }
    iteraction_types = {
        0: 'Default',
        1: 'None',
        2: 'Audio',
        3: 'Image',
        4: 'Video',
        5: 'Seat',
        6: 'Door',
        // 7: "Teleporter",
        // 8: "animate__animated animate__fadeInUp",
        // 9: "Gambler",
        // 10: "Light",
        // 11: "Roller",
        // 12: "Scriptable",
        // 13: "Pet",
    }
    
    typeSelected() {
        this.$emit('type-select', {
            item_type: this.selected_type,
            sub_type: this.selected_sub_type,
        })
        this.$emit('next')
    }
}
</script>

<style scoped>
.selected {
    background-color: #023780;
}

.disabled {
    background-color: grey;
}

.section_gradiant_dark {
    background-image: none;
    background-color: transparent;
}
</style>
