import { Vue, Component } from 'vue-property-decorator';
// @ts-ignore
import { WRAPPED_SOL_MINT } from '@project-serum/serum/lib/token-instructions';
import { TokenInfo, TokenListProvider } from '@solana/spl-token-registry';

const NEXT_SPL_TOKEN_MINTS =
  'kinXdEcpDQeHPEuQnqmUgtYykqKGVFq6CeVX5iAHJq6,ETAtLmCmsoiEEKfNrHKJ2kYy3MoABhU6NQvpSfij5tDs,BLwTnYKqf7u4qjgZrrsKeNs2EzWkMLqVCu6j8iHyrNA3,ATLASXmbPQxBUYbxPsV97usA3fPQYEqzQBUHgiFCUsXx,EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v';
const subscribedTokenMints = NEXT_SPL_TOKEN_MINTS
  ? [WRAPPED_SOL_MINT, ...NEXT_SPL_TOKEN_MINTS.split(',')]
  : [WRAPPED_SOL_MINT];

@Component({
  name: 'IntegratedTokenMixins',
})
export default class IntegratedTokenMixins extends Vue {
  tokens: Array<TokenInfo> = [];
  tokenFetched = false;

  async created() {
    this.getTokens();
  }

  async getTokens() {
    const provider = new TokenListProvider();
    const data = await provider.resolve();
    let swappableTokens = data
      //@ts-ignore
      .filterByClusterSlug('mainnet-beta')
      .getList()
      .filter((f: TokenInfo) => subscribedTokenMints.some(s => s == f.address));

    swappableTokens = swappableTokens.filter(
      t => t.extensions?.serumV3Usdt || t.extensions?.serumV3Usdc,
    );
    swappableTokens.sort((a: TokenInfo, b: TokenInfo) =>
      a.symbol < b.symbol ? -1 : a.symbol > b.symbol ? 1 : 0,
    );
    this.tokens = swappableTokens;
    this.tokenFetched = true;
  }
}
