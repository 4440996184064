<template>
    <div class="index">
        <BannerTop title_blue="Saga" title_white="Explorer" heading="Explore all the Saga's within the AlphaBatem Universe"></BannerTop>

        <div class="container mt-3">
            <div class="row">
                <SagaCard class="mb-3" v-for="item in items" :key="item.id" :item="item"></SagaCard>
            </div>
        </div>
    </div>
</template>

<script>
import Saga from '@/api/saga'
import { Component, Vue } from 'vue-property-decorator'

@Component({
    name: 'SagaIndex',
    components: {
        BannerTop: () => import('../../components/BannerTop.vue'),
        SagaCard: () => import('../../components/explorer/SagaCard.vue'),
    },
})
export default class SagaIndex extends Vue {
    items = []

    getIndex() {
        Saga.index().then(r => (this.items = r.data))
    }
    
    mounted() {
        this.getIndex()
    }
}
</script>

<style scoped></style>
