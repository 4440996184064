<template>
    <div class="tt card" ref="tooltip" v-show="show" @mouseover="hover = true" @mouseleave="hover = false">
        <div class="card-body container-master-fluid">
            <div class="row">
                <div class="col-3 pr-0">
                    <div class="image-container" v-show="!hover">
                        <img class="img-fluid" :src="validImage" alt="tile image" />
                    </div>
                    <button v-show="hover" class="btn btn-primary btn-go" v-on:click="onClick">
                        {{ tile.id === '' || selected.length > 1 ? 'ADD' : 'VISIT' }}
                    </button>
                </div>
                <div class="col-9 pl-0">
                    <div class="noline">
                        <div class="row">
                            <div class="col">
                                <h4>{{ tile.name }}</h4>
                            </div>
                            <div class="col-4 text-center">
                                <h4>
                                    {{ Math.sqrt(selected.length).toFixed(0) }}x{{ Math.sqrt(selected.length).toFixed(0) }}
                                    <small>({{ selected.length }})</small>
                                </h4>
                            </div>
                        </div>
                        <code>{{ tile.owner }}</code>
                    </div>
                    <div class="row">
                        <div class="col">
                            <h6>
                                Pos: <small>{{ chunk[0] }},{{ chunk[1] }}</small>
                            </h6>
                        </div>
                        <div class="col">
                            <h6>
                                Guild: <small>{{ tile.guild.name }}</small>
                            </h6>
                        </div>
                        <div class="col">
                            <h6>
                                Points: <small>{{ tilePoints }}</small>
                            </h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Component({ name: 'Tooltip' })
export default class Tooltip extends Vue {
    hover = false
    el = null

    @Prop({
        type: Array,
        default: () => {
            return []
        },
    })
    selected
    @Prop({
        type: Object,
        default: () => {
            return {
                id: '',
                image: '/lounge/virtual_room_logo.png',
                name: 'Empty Land',
                owner: '0x0000000000000000000000000000000000000000',
                guild: { name: '' },
                points: 0,
            }
        },
    })
    tile
    @Prop({
        type: Boolean,
        default: () => {
            return false
        },
    })
    show
    @Prop({
        type: Array,
        default: () => {
            return [0, 0]
        },
    })
    chunk
    @Prop({
        type: Object,
        default: () => {
            return {
                x: 0,
                y: 0,
            }
        },
    })
    position
    get tilePoints() {
        return this.tile.points
    }

    get validImage() {
        return this.tile.image || '/lounge/virtual_room_logo.png'
    }

    @Watch('position')
    changePosition() {
        this.updatePosition()
    }
	
    updatePosition() {
        console.log('Updating tooltip', this.position)
        this.el.style.left = this.position.x + 'px'
        this.el.style.top = this.position.y + 'px'
    }

    onClick() {
        console.log('Tile', this.tile)

        if (this.tile.id !== '' && this.selected.length === 1) this.$router.push(`/oasis/${this.tile.id}/visit`)

        for (let i = 0; i < this.selected.length; i++) this.$emit('add-basket', this.selected[i])

        // this.$emit("add-basket", this.chunk)
    }
    mounted() {
        this.el = this.$refs.tooltip
    }
}
</script>

<style scoped>
.tt {
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
    border: 1px solid black;
    border-radius: 4px;
    width: 500px;
}

.card {
    background: #0e0e0f;
    padding: 5px;
    /* box-shadow: inset 0px 10px 20px rgb(255 255 255 / 25%), inset 0px -10px 20px rgb(255 255 255 / 25%); */
    box-shadow: 1px 1px 8px #26b6d4;
    border-radius: 14px;
}

.card-body:before {
    content: revert;
}

.card-body {
    padding: 5px;
}

code {
    font-size: 72%;
    font-weight: bold;
    color: #166375;
}

h4 {
    margin-bottom: 0;
}

h4,
h5,
h6 {
    color: white;
}

.noline {
    line-height: 1;
    margin-bottom: 15px;
}

.btn-go {
    padding: 0;
    border-radius: 40px;
}

.btn-go,
.image-container {
    position: relative;
    width: 75px;
    height: 75px;
}

img {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
}
</style>
