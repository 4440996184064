<template>
  <div>
    <div class="base-upload__container">
      <div
        :ref="refUploadContainer"
        class="drag-upload p-4"
        draggable="true"
        @dragend="handleDragDrop"
        @drop="handleDragDrop"
        @dragover="$event.preventDefault()"
        @click="chooseFile"
      >
        <div class="base-upload__header">
          <slot v-if="hasHeaderSlot" name="header" />
          <div v-else>
            <h6>{{ placeholder || 'Upload your cover image' }}</h6>
          </div>
          <p class="ant-upload-text" style="color: rgb(109, 109, 109)">
            Drag and drop, or click to browse
          </p>
        </div>
      </div>
      <b-form-file
        :ref="refUploadButton"
        :accept="extAllowed.join(', ')"
        v-model="currentValue"
        class="d-none"
        plain
      />
      <div
        v-if="currentValue"
        class="w-100 d-flex ant-row-flex-space-between mt-1 attachment-detail px-1"
      >
        <div>
          <BIconPaperclip rotate="45" />
          <p class="d-inline-block mx-2 mb-0">{{ currentValue.name }}</p>
        </div>
        <BIconTrash
          class="mt-1"
          :style="{ cursor: 'pointer' }"
          @click="currentValue = null"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { Component, Prop, Vue } from 'vue-property-decorator';
import { BIconPaperclip, BIconTrash } from 'bootstrap-vue';

@Component({
  name: 'BaseUpload',
  components: {
    BIconTrash: BIconTrash,
    BIconPaperclip: BIconPaperclip,
  },
})
export default class BaseUpload extends Vue {
  @Prop([File, null]) value;
  @Prop(Array) accept;
  @Prop(String) placeholder;

  refUploadContainer = 'upload__container';
  refUploadButton = 'btn__upload';

  get currentValue() {
    return this.value;
  }

  set currentValue(value) {
    this.$emit('input', value);
  }

  get hasHeaderSlot() {
    return !!this.$slots['header'];
  }

  get extAllowed() {
    if (this.accept) {
      return this.accept;
    }

    return ['.png', '.jpg', '.jpeg', '.gif', '.svg'];
  }

  handleDragDrop(evt) {
    evt.preventDefault();
    if (evt?.dataTransfer?.items?.length) {
      this.currentValue = evt.dataTransfer.items[0].getAsFile();
    } else {
      this.currentValue = null;
    }
  }

  chooseFile(evt) {
    evt.preventDefault();
    const el = this.$refs?.[this.refUploadButton].$el;
    if (el) {
      el.click();
    }
  }
}
</script>

<style scoped>
.drag-upload {
  background: rgba(255, 255, 255, 0.08);
  width: 100%;
  height: 120px;
  border-radius: 8px;
  transition: border-color 0.3s;
  border: 1px solid transparent;
}

.drag-upload:hover {
  border-color: #a1b4ff;
  border-style: dashed;
}

.drag-upload h4 {
  color: white;
}

.drag-upload p {
  text-align: center;
}

.attachment-detail:hover {
  transition: 0.3s;
  background-color: white;
  color: #212121;
}

.attachment-detail:hover p {
  color: #212121;
}

.base-upload__container {
  cursor: pointer;
}

.base-upload__header h6 {
  opacity: 1;
  font-weight: 700;
}
</style>
