











import { Vue, Component } from 'vue-property-decorator';

@Component({
  name: 'LimitedSale',
  components: {
    AddNFT: () => import('@/views/shop/components/sell-nft/AddNFT.vue'),
    AuctionMint: () => import('@/views/shop/components/sell-nft/AuctionMint.vue'),
  },
})
export default class LimitedSale extends Vue {
  value = {
    nft: null,
    sellType: null,
    auctionSetup: null,
  };
}
