import {
  CreateAuctionV2Args,
  PriceFloor,
  PriceFloorType,
  WinnerLimit,
  WinnerLimitType,
} from '@metaplex-foundation/mpl-auction';
import { SellCategories } from '@/views/shop/components/sell-nft/sellCategories';
import { WinningConfigType } from '@metaplex-foundation/mpl-metaplex';
import { AmountRange } from '@/views/shop/actions/vaultDefines';
import BN from 'bn.js';
import { WRAPPED_SOL_MINT } from '@project-serum/serum/lib/token-instructions';
import { LAMPORTS_PER_SOL } from '@solana/web3.js';

export interface SellDataGathering {
  editions: number | undefined;
  winningConfigType: WinningConfigType;
  amountRanges: Array<AmountRange>;
  priceFloor: number | undefined;
  getAuctionSettings(): CreateAuctionV2Args;
}

// factory + abstract
export class AbstractGathering {
  static BuildGathering(type: string, args: any): SellDataGathering {
    switch (type) {
      case SellCategories.instant.type:
        return new InstantSaleGathering(args);
      default:
        return new InstantSaleGathering(args);
    }
  }
}

class InstantSaleGathering implements SellDataGathering {
  editions: number | undefined;
  winningConfigType: WinningConfigType = WinningConfigType.TokenOnlyTransfer;
  amountRanges: Array<AmountRange> = [];
  priceFloor: number | undefined;
  quoteMintAddress: string = '';
  quoteMintInfo: any;
  constructor(args: { [key: string]: any }) {
    Object.assign(this, args);
    this.priceFloor = Number(args.instantSalePrice);
    this.quoteMintAddress = args.auctionMint
      ? args.auctionMint.address
      : WRAPPED_SOL_MINT.toBase58();
    this.quoteMintInfo = args.quoteMintInfo;
  }
  getAuctionSettings(): CreateAuctionV2Args {
    this.winningConfigType = WinningConfigType.PrintingV2;
    if (!this.editions) {
      // editions ~ quantity
      this.winningConfigType = WinningConfigType.TokenOnlyTransfer;
    }
    this.amountRanges = [
      new AmountRange({
        amount: new BN(1),
        length: new BN(this.editions || 1),
      }),
    ];
    const winnerLimit = new WinnerLimit({
      type: WinnerLimitType.Capped,
      usize: new BN(this.editions || 1),
    });

    const LAMPORTS_PER_TOKEN =
      this.quoteMintAddress == WRAPPED_SOL_MINT.toBase58()
        ? LAMPORTS_PER_SOL
        : Math.pow(10, this.quoteMintInfo.decimals || 0);

    return new CreateAuctionV2Args({
      authority: '',
      resource: '',
      winners: winnerLimit,
      endAuctionAt: null,
      auctionGap: null,
      priceFloor: new PriceFloor({
        type: PriceFloorType.Minimum,
        minPrice: new BN((this.priceFloor || 0) * LAMPORTS_PER_TOKEN),
      }),
      tokenMint: this.quoteMintAddress,
      gapTickSizePercentage: null,
      tickSize: null,
      instantSalePrice: new BN((this.priceFloor || 0) * LAMPORTS_PER_TOKEN),
      name: null,
    });
  }
}
