





































// @ts-ignore
import User from '@/api/user'
import { Component, Vue } from 'vue-property-decorator'
// import { getPhantomWallet } from '@solana/wallet-adapter-wallets'
// import { NFTGet } from '@/common/NFTget'

@Component({
    name: 'ProfileIndex',
    components: {
        PlayerCard: () => import('../../components/profile/PlayerCard.vue'),
        Inventory: () => import('../../components/profile/Inventory.vue'),
        Equipment: () => import('../../components/profile/Equipment.vue'),
        PetList: () => import('../../components/profile/PetList.vue'),
    },
})
export default class ProfileIndex extends Vue {
    user = ''
    profile = {}

    // async testConnectMetaplex() {
    //     const gottenWallet = getPhantomWallet();
    //     const connectedAdapter = gottenWallet.adapter();
    //
    //     connectedAdapter.connect().then(() => {
    //         this.$store.commit('walletModule/set_wallet_public_key', connectedAdapter.publicKey)
    //     }).catch(err => {
    //         console.log('@@@@- err')
    //         console.log(err)
    //
    //     })
    //
    //     console.log('@@@ - wallet_public_key', this.$store.state.wallet_public_key)
    //
    //     const params = {
    //         owner: connectedAdapter.publicKey,
    //         creator: undefined,
    //         mint: undefined,
    //         updateAuthority: undefined
    //     } as any
    //
    //     // const a = await NFTGet(params)
    //     // console.log(a)
    //
    //     NFTGet(params).then(rs => {
    //         console.log('@@@@ - get data NFT')
    //         console.log(rs)
    //     }).catch(err => {
    //         console.log('@@@@ - get data NFT ERR')
    //         console.log(err)
    //     })
    //
    // }

    getProfile() {
        User.show(this.user).then((resp :any) => {
            this.profile = resp.data
        })
    }

    beforeMount() {
        this.user = this.$store.state.wallet_addr
    }
    mounted() {
        this.getProfile()
    }
}
