import { render, staticRenderFns } from "./SagaIndex.vue?vue&type=template&id=30431ee6&scoped=true&"
import script from "./SagaIndex.vue?vue&type=script&lang=js&"
export * from "./SagaIndex.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "30431ee6",
  null
  
)

export default component.exports