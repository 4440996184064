<template>
    <div class="nft-lookup row mt-5">
        <div class="col-12">
            <div class="card mb-3" v-for="asset in assets" :key="asset.id" v-on:click="selectAsset(asset)">
                <div class="card-body">
                    <NFTListItem :nft="asset"></NFTListItem>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
    name: 'RoomCard',
    components: {
        NFTListItem: () => import('./NFTListItem'),
    },
})
export default class Lookup extends Vue {
    account = ''
    opensea_api = 'https://rinkeby-api.opensea.io/api/v1'
    assets = []
    selected_asset = {
        name: '',
        description: '',
        image_preview_url: '',
        image_thumbnail_url: '',
        asset_contract: {
            address: '',
            schema_name: '',
        },
    }
    getOpenSea() {
        fetch(`${this.opensea_api}/assets?owner=${this.account}`)
            .then(response => response.json())
            .then(response => (this.assets = response.assets))
            .catch(err => console.error(err))
    }

    selectAsset(asset) {
        this.$emit('nft-select', asset)
        this.$emit('next')
    }

    mounted() {
        window.web3.eth
            .getAccounts()
            .then(accounts => (this.account = accounts[0]))
            .then(this.getOpenSea)
    }
}
</script>

<style scoped>
.card {
    background: transparent;
    color: white;
    border: 1px solid #20126f;
}

.card-body::before {
    content: unset;
}

.card-body,
h4,
h5 {
    color: white;
}
</style>
