import BN from 'bn.js';
import {
  Connection,
  Keypair,
  PublicKey,
  Transaction,
  TransactionCtorFields,
  TransactionInstruction,
} from '@solana/web3.js';
import { actions, programs, Wallet } from '@metaplex/js';
import {
  CreateAuctionV2,
  CreateAuctionV2Args,
  SetAuctionAuthority,
} from '@metaplex-foundation/mpl-auction';
import { Progresses } from '@/views/shop/components/sell-nft/sellTransactionStages';
import { AccountLayout, NATIVE_MINT } from '@solana/spl-token';
import { SetVaultAuthority, Vault } from '@metaplex-foundation/mpl-token-vault';
import { WalletAdapter } from '@solana/wallet-adapter-base/src/adapter';
import { toPublicKey } from '@/views/shop/utils';
import { InitAuctionManagerV2, StartAuction } from '@metaplex-foundation/mpl-metaplex';
import { WalletNotConnectedError } from '@solana/wallet-adapter-base';
import { createUninitializedTokenAccount } from '@/views/shop/actions/accounts';
import { signInstructions } from '@/views/shop/actions/transactions';
import { WRAPPED_SOL_MINT } from '@project-serum/serum/lib/token-instructions';
import { TupleNumericType } from '@metaplex-foundation/mpl-core';
import { validateDepositBox } from '@/views/shop/actions/vaults';
import { cacheAuctionIndexer } from '@/views/shop/actions/cacheAuction';

export async function createAuctionManager(
  connection: Connection,
  wallet: Wallet & WalletAdapter,
  store: PublicKey,
  auctionSettings: CreateAuctionV2Args,
  dtoNFT: any,
  storeIndexer: any,
  callbackProgress?: (_: any) => void,
) {
  callbackProgress?.(Progresses.createExternalPriceAccount.type);
  const { externalPriceAccount, priceMint } = await actions.createExternalPriceAccount({
    connection,
    wallet,
  });

  callbackProgress?.(Progresses.createVault.type);
  const { vault, fractionMint, fractionTreasury } = await actions.createVault({
    connection,
    wallet,
    priceMint: new PublicKey(priceMint),
    externalPriceAccount: new PublicKey(externalPriceAccount),
  });

  callbackProgress?.(Progresses.addTokensToVault.type);
  const vaultStores = await actions.addTokensToVault({
    connection,
    wallet,
    vault,
    nfts: [
      {
        amount: new BN(1),
        tokenAccount: dtoNFT.nft.splTokenInfo.address,
        tokenMint: dtoNFT.nft.mint,
      },
    ],
  });

  callbackProgress?.(Progresses.activeVault.type);
  const fractionMintAuthority = await Vault.getPDA(vault);
  let block = await connection.getRecentBlockhash('single');
  let txOptions: TransactionCtorFields = {
    feePayer: wallet.publicKey,
    recentBlockhash: block.blockhash,
  };
  const activeVaultArgs = new programs.vault.ActivateVault(txOptions, {
    vault,
    fractionMint,
    fractionTreasury,
    fractionMintAuthority,
    vaultAuthority: wallet.publicKey,
    numberOfShares: new BN(0),
  });
  await wallet.sendTransaction(activeVaultArgs, connection);

  callbackProgress?.(Progresses.combineVault.type);
  await combineVault({
    connection,
    wallet,
    vault,
  });

  callbackProgress?.(Progresses.initAuction.type);
  const accountRentExempt = await connection.getMinimumBalanceForRentExemption(
    AccountLayout.span,
  );

  await makeAuction(connection, wallet, vault, auctionSettings);
  const { auctionKey: auction, auctionManagerKey: auctionManager } = await getAuctionKeys(
    vault.toBase58(),
  );

  /* --- Init auction V1: Not exist instant sale
  *
  auctionSettings.resource = vault.toBase58();
  auctionSettings.authority = wallet.publicKey.toBase58();
  await actions.initAuction({
    connection,
    wallet,
    vault,
    auctionSettings,
  });
  *
  * */

  callbackProgress?.(Progresses.initAuctionManager.type);
  block = await connection.getRecentBlockhash('single');
  txOptions = {
    feePayer: wallet.publicKey,
    recentBlockhash: block.blockhash,
  };
  let signers: Keypair[] = [];
  let instructions: TransactionInstruction[] = [];
  const acceptPaymentAccount = createUninitializedTokenAccount(
    instructions,
    wallet.publicKey,
    accountRentExempt,
    WRAPPED_SOL_MINT,
    auctionManager,
    signers,
  );
  await signInstructions(connection, wallet, instructions, signers);

  const initAManager = new InitAuctionManagerV2(txOptions, {
    store,
    auction,
    auctionManager,
    vault,
    auctionManagerAuthority: wallet.publicKey,
    amountType: TupleNumericType.U8,
    lengthType: TupleNumericType.U8,
    maxRanges: new BN(10),
    acceptPaymentAccount,
    tokenTracker: await getAuctionWinnerTokenTypeTracker(auctionManager),
  });
  let tx = new Transaction(txOptions);
  tx.recentBlockhash = block.blockhash;
  tx.add(initAManager);
  await wallet.sendTransaction(tx, connection);

  callbackProgress?.(Progresses.setVaultAndAuctionAuthority.type);
  const setAuctionAuthority = new SetAuctionAuthority(
    {},
    {
      auction,
      newAuthority: auctionManager,
      currentAuthority: wallet.publicKey,
    },
  );
  const setVaultAuthority = new SetVaultAuthority(
    {},
    {
      vault,
      newAuthority: auctionManager,
      currentAuthority: wallet.publicKey,
    },
  );
  block = await connection.getRecentBlockhash('single');
  txOptions = {
    feePayer: wallet.publicKey,
    recentBlockhash: block.blockhash,
  };
  tx = new Transaction(txOptions);
  tx.recentBlockhash = block.blockhash;
  tx.add(setVaultAuthority);
  tx.add(setAuctionAuthority);
  await wallet.sendTransaction(tx, connection);

  callbackProgress?.(Progresses.validateSafetyDepositBox.type);
  await validateDepositBox({
    connection,
    wallet,
    vault,
    nft: dtoNFT.nft.mint,
    store,
    metadata: dtoNFT.nft.metadataPDA.toBase58(),
    tokenStore: vaultStores.safetyDepositTokenStores[0].tokenStoreAccount,
  });

  localStorage.setItem('auction_created', auction.toBase58());
  callbackProgress?.(Progresses.startAuction.type);
  await startAuction({
    connection,
    store,
    wallet,
    vault,
  });

  callbackProgress?.(Progresses.updateStore.type);
  const intsAndSigners = await cacheAuctionIndexer(
    wallet,
    vault.toBase58(),
    auction.toBase58(),
    auctionManager.toBase58(),
    [dtoNFT.nft.mint.toBase58()],
    storeIndexer,
  );

  if (!intsAndSigners?.instructions) {
    return;
  }
  for (let i = 0; i < intsAndSigners.instructions.length; i++) {
    if (!intsAndSigners.instructions.length) {
      continue;
    }
    await signInstructions(
      connection,
      wallet,
      intsAndSigners.instructions[i],
      intsAndSigners.signers[i]?.length ? intsAndSigners.signers[i] : undefined,
    );
  }
}

export async function closeVaultToWithdraw({
  connection,
  wallet,
  store,
  vaultAccountRaw,
}: {
  connection: Connection;
  wallet: Wallet & WalletAdapter;
  store: PublicKey;
  vaultAccountRaw: Vault;
}) {
  await actions.closeVault({
    connection,
    wallet,
    vault: vaultAccountRaw.pubkey,
    priceMint: NATIVE_MINT,
  });

  const walletPubkey = wallet.publicKey;
  const [block, boxes] = await Promise.all([
    connection.getRecentBlockhash('single'),
    vaultAccountRaw.getSafetyDepositBoxes(connection),
  ]);
  const withdrawArgs = new programs.vault.WithdrawTokenFromSafetyDepositBox(
    {
      feePayer: walletPubkey,
      recentBlockhash: block.blockhash,
    },
    {
      store,
      vault: vaultAccountRaw.pubkey,
      destination: walletPubkey,
      safetyDepositBox: boxes[0].pubkey,
      fractionMint: new PublicKey(vaultAccountRaw.data.fractionMint),
      vaultAuthority: walletPubkey,
      transferAuthority: walletPubkey,
      amount: new BN(1),
    },
  );
  return wallet.sendTransaction(withdrawArgs, connection);
}

export async function getAuctionManagerKey(
  vault: string,
  auctionKey: string,
): Promise<PublicKey> {
  return (
    await PublicKey.findProgramAddress(
      [
        Buffer.from(programs.metaplex.MetaplexProgram.PREFIX),
        toPublicKey(auctionKey).toBuffer(),
      ],
      toPublicKey(programs.metaplex.MetaplexProgram.PUBKEY),
    )
  )[0];
}

export async function getAuctionKeys(
  vault: string,
): Promise<{ auctionKey: PublicKey; auctionManagerKey: PublicKey }> {
  const auctionKey = await programs.auction.Auction.getPDA(vault);
  const auctionManagerKey = await programs.metaplex.AuctionManager.getPDA(auctionKey);

  return { auctionKey, auctionManagerKey };
}

export async function combineVault({
  connection,
  wallet,
  vault,
}: {
  connection: Connection;
  wallet: WalletAdapter;
  vault: PublicKey;
}) {
  if (!wallet.publicKey) throw new WalletNotConnectedError();
  const accountRentExempt = await connection.getMinimumBalanceForRentExemption(
    AccountLayout.span,
  );
  const loadedVault = await programs.vault.Vault.load<Vault>(connection, vault);
  const vaultInfo = loadedVault.data;

  const signers: Keypair[] = [];
  const instructions: TransactionInstruction[] = [];
  const outstandingShareTokenAccount = createUninitializedTokenAccount(
    instructions,
    wallet.publicKey,
    accountRentExempt,
    toPublicKey(vaultInfo.fractionMint),
    wallet.publicKey,
    signers,
  );

  const payingTokenAccount = createUninitializedTokenAccount(
    instructions,
    wallet.publicKey,
    accountRentExempt,
    NATIVE_MINT,
    wallet.publicKey,
    signers,
  );

  const burnAuthority = (
    await PublicKey.findProgramAddress(
      [
        Buffer.from(programs.vault.VaultProgram.PREFIX),
        toPublicKey(programs.vault.VaultProgram.PUBKEY).toBuffer(),
        toPublicKey(vault).toBuffer(),
      ],
      toPublicKey(programs.vault.VaultProgram.PUBKEY),
    )
  )[0];

  await signInstructions(connection, wallet, instructions, signers);

  const block = await connection.getRecentBlockhash('single');
  const txOptions: TransactionCtorFields = {
    feePayer: wallet.publicKey,
    recentBlockhash: block.blockhash,
  };
  const combineVaultArgs = new programs.vault.CombineVault(txOptions, {
    vault,
    fractionMint: toPublicKey(vaultInfo.fractionMint),
    fractionTreasury: toPublicKey(vaultInfo.fractionTreasury),
    outstandingShareTokenAccount,
    payingTokenAccount,
    redeemTreasury: toPublicKey(vaultInfo.redeemTreasury),
    vaultAuthority: wallet.publicKey,
    transferAuthority: wallet.publicKey,
    externalPriceAccount: toPublicKey(vaultInfo.pricingLookupAddress),
    burnAuthority,
  });
  return wallet.sendTransaction(combineVaultArgs, connection);
}

// This command makes an auction
export async function makeAuction(
  connection: Connection,
  wallet: WalletAdapter,
  vault: PublicKey,
  auctionSettings: CreateAuctionV2Args,
) {
  if (!wallet.publicKey) throw new WalletNotConnectedError();

  const fullSettings = new CreateAuctionV2Args({
    ...auctionSettings,
    authority: wallet.publicKey.toBase58(),
    resource: vault.toBase58(),
  });

  const [auction, auctionExtended] = await Promise.all([
    programs.auction.Auction.getPDA(vault),
    programs.auction.AuctionExtended.getPDA(vault),
  ]);
  const block = await connection.getRecentBlockhash('single');
  const txOption = {
    feePayer: wallet.publicKey,
    recentBlockhash: block.blockhash,
  };
  const tx = new Transaction(txOption);
  tx.add(
    new CreateAuctionV2(txOption, {
      auction,
      auctionExtended,
      creator: wallet.publicKey,
      args: fullSettings,
    }),
  );
  return wallet.sendTransaction(tx, connection);
}

export async function startAuction({
  connection,
  store,
  wallet,
  vault,
}: {
  connection: Connection;
  wallet: WalletAdapter;
  store: PublicKey;
  vault: PublicKey;
}) {
  if (!wallet.publicKey) throw new WalletNotConnectedError();

  const { auctionKey, auctionManagerKey } = await getAuctionKeys(vault.toBase58());
  const block = await connection.getRecentBlockhash('single');
  const txOptions: TransactionCtorFields = {
    feePayer: wallet.publicKey,
    recentBlockhash: block.blockhash,
  };
  const startAuctionArgs = new StartAuction(txOptions, {
    store,
    auction: auctionKey,
    auctionManager: auctionManagerKey,
    auctionManagerAuthority: wallet.publicKey,
  });

  return wallet.sendTransaction(startAuctionArgs, connection);
}

export async function getAuctionWinnerTokenTypeTracker(auctionManager: PublicKey) {
  return (
    await PublicKey.findProgramAddress(
      [
        Buffer.from(programs.metaplex.MetaplexProgram.PREFIX),
        programs.metaplex.MetaplexProgram.PUBKEY.toBuffer(),
        auctionManager.toBuffer(),
        Buffer.from('totals'),
      ],
      programs.metaplex.MetaplexProgram.PUBKEY,
    )
  )[0];
}
