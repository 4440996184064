<template>
    <router-link :to="`/sagas/${item.saga_id}/items/${item.id}`">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-2 text-center">
                        <img class="preview" :src="imageUri" />
                    </div>
                    <div class="col-6">
                        <h4>{{ item.name }}</h4>
                        <h6>{{ item.description }}</h6>
                    </div>
                    <div class="col-4">
                        <h4>{{ item.max_quantity }}</h4>
                        <div class="badge badge-primary badge-pill" :style="{ background: rarityColour }">{{ itemRarity }}</div>
                    </div>
                </div>
            </div>
        </div>
    </router-link>
</template>

<script>
import { Component, Prop, Vue } from 'vue-property-decorator'
@Component({
    name: 'ItemCard',
})
export default class ItemCard extends Vue {
    @Prop(Object) item
    get imageUri() {
        if (this.item.animation_url !== undefined && this.item.animation_url !== '') return this.item.animation_url

        if (this.item.sprite_uri !== undefined && this.item.sprite_uri !== '') return this.item.sprite_uri

        //Saga items are nested
        if (this.item.item !== undefined && this.item.item.sprite_uri !== undefined && this.item.item.sprite_uri !== '') return this.item.item.sprite_uri

        return this.item.image
    }

    get itemRarity() {
        switch (this.item.rarity) {
            default:
            case 0:
                return 'Common'
            case 1:
                return 'Uncommon'
            case 2:
                return 'Rare'
            case 3:
                return 'Epic'
        }
    }
    get rarityColour() {
        switch (this.item.rarity) {
            default:
            case 0:
                return '#26b6d4'
            case 1:
                return '#C61664'
            case 2:
                return '#7558A5'
            case 3:
                return '#ea4436'
        }
    }
}
</script>

<style scoped>
.preview {
    max-height: 50px;
    width: auto;
}

.card {
    background: transparent;
    color: white;
    border: 1px solid #20126f;
}

.card-body::before {
    content: unset;
}

.card-body h4,
h5 {
    color: white;
}

.card-body {
    padding: 1rem;
}

h6,
small {
    color: #7a7a7a;
}

.rarity {
    color: red;
}
</style>
