








































































import { Component, Watch } from 'vue-property-decorator';
import { ValidationObserver } from 'vee-validate';
import {
  AllSteps,
  InstantSaleSteps,
  LimitedSaleSteps,
} from '@/views/shop/components/sell-nft/sellSteps';
import { SellCategories } from '@/views/shop/components/sell-nft/sellCategories';
import { mapActions } from 'vuex';
import { getPhantomWallet } from '@solana/wallet-adapter-wallets';
import ProgressLoadingMixins from '@/mixins/progressLoadingMixins';
import { Progresses } from '@/views/shop/components/sell-nft/sellTransactionStages';
import { AbstractGathering } from '@/views/shop/components/sell-nft/sellDataGathering';
//@ts-ignore
import { Connection, PublicKey } from '@solana/web3.js';
import { MintParser } from '@/views/shop/actions/mintCurrency';

@Component({
  name: 'SellNFT',
  components: {
    SelectCategory: () => import('@/views/shop/components/sell-nft/SelectCategory.vue'),
    InstantSale: () => import('@/views/shop/components/sell-nft/instant-sale/Index.vue'),
    LimitedSale: () => import('@/views/shop/components/sell-nft/limited-sale/Index.vue'),

    // Phase - Step
    InitialPhase: () => import('@/views/shop/components/sell-nft/InitialPhase.vue'),
    InstantStep3: () =>
      import('@/views/shop/components/sell-nft/instant-sale/InstantStep3.vue'),

    // Others
    ValidationObserver,
    ProgressLoading: () => import('@/components/ProgressLoading.vue'),
  },
  methods: {
    ...mapActions('marketplaceAuctionModule', ['listingInstantSale']),
  },
})
export default class SellNFT extends ProgressLoadingMixins {
  listingInstantSale: ((_: any) => any) | undefined;

  loading = false;

  LimitedSaleSteps = LimitedSaleSteps;
  InstantSaleSteps = InstantSaleSteps;
  Steps = AllSteps;
  currentStep = this.Steps.SelectCategory.value;
  subSteps = null;

  categories = SellCategories;
  typeSelected = '';

  fullVHeight = '100vh';

  value: {
    nft: { [key: string]: any } | null;
    sellType: string | null;
    auctionMint: { [key: string]: any } | null;
    instantSalePrice: number | null;
  } = {
    nft: null,
    sellType: null,
    auctionMint: null,
    instantSalePrice: null,
  };

  created() {
    this.setupProgresses(Progresses);
  }

  get currentListStep() {
    if (!this.subSteps) {
      return this.Steps;
    }

    if (this.currentStep === this.Steps.SelectCategory.value) {
      return this.Steps;
    }

    return this.subSteps || {};
  }

  calculateContentRightBody() {
    const el = document.getElementsByClassName('content__right')?.[0];
    if (el) {
      const rect = el.getBoundingClientRect();
      this.fullVHeight = `${window.innerHeight - rect.y - 48 - 1}px`; // - padding of parent, -1 for sure
    }
  }

  backToStep(step: number) {
    if (step < this.currentStep) {
      this.currentStep = step;
    }
  }

  selectCategory(selected: any) {
    this.currentStep++;
    this.typeSelected = selected;
    //@ts-ignore
    this.subSteps = this.categories[this.typeSelected].subSteps;
  }

  async goNextStep() {
    let ref = this.$refs.validator;
    //@ts-ignore
    if (ref?.validate && !(await ref.validate())) {
      return;
    }

    this.currentStep++;
    this.startSell();
  }

  async startSell() {
    this.loading = true;
    const instantSalePrice = this.value.instantSalePrice;

    const connectedAdapter = getPhantomWallet().adapter();
    await connectedAdapter.connect().catch((err: any) => {
      console.error(err);
    });
    let quoteMintInfo;
    if (this.value?.auctionMint?.address) {
      const connection: Connection = this.$store.state.connectionModule.connection;
      const accountMint = await connection.getAccountInfo(
        new PublicKey(this.value.auctionMint.address),
      );
      if (accountMint) {
        quoteMintInfo = MintParser(this.value.auctionMint.address, accountMint).info;
      }
    }
    const auctionSettings = AbstractGathering.BuildGathering(
      SellCategories.instant.type,
      {
        editions: undefined,
        quoteMintInfo,
        instantSalePrice,
        auctionMint: this.value.auctionMint,
      },
    ).getAuctionSettings();

    console.log('nft: ', this.value);
    await this.listingInstantSale?.({
      dtoNFT: this.value,
      auctionSettings,
      wallet: connectedAdapter,
      callbackProgress: this.callbackProgress,
    }).catch(() => {
      this.$notification.error({
        message: 'Network!!!',
        description: 'Network error occurred.',
      });
    });
    this.loading = false;
  }

  @Watch('currentStep')
  watchCurrentStep(val: number) {
    if (val === this.Steps.SelectCategory.value) {
      this.subSteps = null;
    }
  }

  @Watch('typeSelected')
  watchTypeSelect() {
    this.$nextTick(this.calculateContentRightBody);
  }
}
