<template>
    <div class="equipment-container">
        <div class="col-12 equipment text-center bg-card">
            <div class="row m-3">
                <div class="offset-4"></div>
                <div class="col-3">
                    <EquipmentSlot class="es" :item="equipSlot['helm']" equipSlot="helm"></EquipmentSlot>
                </div>
            </div>

            <div class="row mb-3">
                <div class="offset-1 col-3">
                    <EquipmentSlot class="es" :item="equipSlot['cape']" equipSlot="cape"></EquipmentSlot>
                </div>
                <div class="col-3">
                    <EquipmentSlot class="es" :item="equipSlot['neck']" equipSlot="neck"></EquipmentSlot>
                </div>
                <div class="col-3">
                    <EquipmentSlot class="es" :item="equipSlot['shoulders']" equipSlot="shoulders"></EquipmentSlot>
                </div>
            </div>

            <div class="row mb-3">
                <div class="offset-1 col-3">
                    <EquipmentSlot class="es" :item="equipSlot['weapon']" equipSlot="weapon"></EquipmentSlot>
                </div>
                <div class="col-3">
                    <EquipmentSlot class="es" :item="equipSlot['chest']" equipSlot="chest"></EquipmentSlot>
                </div>
                <div class="col-3">
                    <EquipmentSlot class="es" :item="equipSlot['shield']" equipSlot="shield"></EquipmentSlot>
                </div>
            </div>

            <div class="row">
                <div class="offset-1 col-3">
                    <EquipmentSlot class="es" :item="equipSlot['ring']" equipSlot="ring"></EquipmentSlot>
                </div>
                <div class="col-3">
                    <EquipmentSlot class="es" :item="equipSlot['boots']" equipSlot="boots"></EquipmentSlot>
                </div>
                <div class="col-3">
                    <EquipmentSlot class="es" :item="equipSlot['gloves']" equipSlot="gloves"></EquipmentSlot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
    name: 'Equipment',
    components: { EquipmentSlot: () => import('./EquipmentSlot') },
})
export default class Equipment extends Vue {
    @Prop(String) user

    equipSlot = {
        helm: {},
        cape: {},
        neck: {},
        shoulders: {},
        weapon: {},
        chest: {},
        shield: {},
        ring: {},
        boots: {},
        gloves: {},
    }
}
</script>

<style scoped>
h1 {
    font-family: 'Prime-Light', sans-serif;
    color: #0a9fb9;
    font-size: 2rem;
}

.es {
    min-height: 100px;
}

.equipment-container {
    height: 100%;
}
</style>
