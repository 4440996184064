











































import { Component, Vue } from 'vue-property-decorator'

@Component({
    components: {
        Metamask: () => import('../../components/wallet/Metamask.vue'),
        WalletConnect: () => import('../../components/wallet/WalletConnect.vue'),
        Phantom: () => import('../../components/wallet/Phantom.vue'),
    },
})
export default class Login extends Vue {
    onConnect() {
        this.$router.push('/profile')
    }
}
