<template>
    <div class="import-payment">
        <p>Payment Processing</p>
    </div>
</template>

<script>
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({ name: 'RoomCard' })
export default class Payment extends Vue {}
</script>

<style scoped></style>
