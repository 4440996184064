<template>
  <div v-if="width < 768">
    <img id="logo" class="logo" src="@/assets/images/alpha_black.png" @click="setIsModalVisible(true)" />
  </div>
  <DefaultActions v-else/>
</template>

<script>
import {Vue, Component} from "vue-property-decorator";
import {useWindowDimensions} from '@/views/shop/utils/layout'

@Component({
  name: "MetaplexMenu",
  components: {
    DefaultActions: () => import('./DefaultActions'),
  },
})
export default class MetaplexMenu extends Vue{
  isModalVisible = false

  get width() {
    return useWindowDimensions(this).width;
  }

  setIsModalVisible(val) {
    this.isModalVisible = val
  }
}
</script>

<style scoped>

</style>