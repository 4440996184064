<template>
  <div class="d-flex">
    <model-viewer
        id="reveal"
        :src="animationUrl"
        :poster="uri"
        :style="{minHeight: '400px'}"
        class="w-100"
        loading="eager"
        camera-controls
        auto-rotate
        @load="loaded = true"
    >
    </model-viewer>
  </div>
</template>

<script>
import {Vue, Component, Prop} from "vue-property-decorator";
import ModelViewer from '@google/model-viewer'

@Component({
  name: "MeshArtContent",
  components: {
    ModelViewer: ModelViewer,
  }
})
export default class MeshArtContent extends Vue {
  @Prop(String) uri
  @Prop(String) preview
  @Prop(String) animationUrl

  loaded = false // use unique loading/progress
}
</script>

<style scoped>
model-viewer#reveal {
  --poster-color: transparent;
}
</style>