<template>
    <div class="show">
        <BannerTop title_blue="Player " :title_white="player.name" :heading="player.wallet_addr" :image="player.sprite_url"></BannerTop>

        <div class="container-fluid mt-4">
            <div class="row">
                <div class="col-4 offset-2">
                    <h4>{{ player.name }}</h4>
                    <div class="card">
                        <div class="card-body text-center">
                            <h6>Address</h6>
                            <h5 class="mb-5">{{ player.wallet_addr }}</h5>

                            <div class="" v-if="player.name !== ''">
                                <h6>NickName</h6>
                                <h5 class="mb-5">{{ player.name }}</h5>
                            </div>

                            <h6>Joined On</h6>
                            <h5 class="mb-5">{{ niceDate(player.created_at) }}</h5>

                            <h6>Last Login</h6>
                            <h5 class="mb-5">{{ player.last_login_at === null ? '-' : niceDate(player.last_login_at) }}</h5>

                            <div class="" v-if="player.description !== ''">
                                <h6>Bio</h6>
                                <p>{{ player.description === undefined ? '-' : player.description }}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-5">
                    <h4>Rooms</h4>
                    <div class="row">
                        <div v-for="ri in rooms" :key="ri.id" class="col-12 mb-3">
                            <RoomCard :item="ri"></RoomCard>
                        </div>
                    </div>
                    <h4>Items</h4>
                    <div class="row">
                        <div v-for="ri in items" :key="ri.id" class="col-12 mb-3">
                            <ItemCard :item="ri"></ItemCard>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import User from '@/api/user'
import { Component, Vue } from 'vue-property-decorator'

@Component({
    name: 'PlayerShow',
    components: {
        RoomCard: () => import('../../components/BannerTop.vue'),
        ItemCard: () => import('../../components/explorer/ItemCard.vue'),
        RoomCard: () => import('../../components/explorer/RoomCard.vue'),
    },
})
export default class PlayerShow extends Vue {
    rooms = []
    items = []
    player = {}
    
    getPlayer(player) {
        User.show(player).then(r => {
            this.player = r.data
        })
    }

    getPlayerItems(player) {
        User.indexItems(player).then(r => {
            this.items = r.data
        })
    }

    getPlayerRooms(player) {
        User.indexRooms(player).then(r => {
            this.rooms = r.data
        })
    }

    niceDate(ca) {
        const dt = new Date(ca)
        return dt.toLocaleDateString()
    }

    mounted() {
        this.getPlayer(this.$route.params.id)
        this.getPlayerItems(this.$route.params.id)
        this.getPlayerRooms(this.$route.params.id)
    }
}
</script>

<style scoped>
.player-stats {
    position: relative;
    height: 40vh;
    width: 20vw;
    margin-left: 5vw;
    text-align: center;
}

.card {
    background: transparent;
    color: white;
    border: 1px solid #20126f;
}

.card-body::before {
    content: unset;
}

.card-body,
h4,
h5 {
    color: white;
}

.item-container {
    text-align: center;
}

.preview {
    max-height: 128px;
    width: auto;
}

h6 {
    color: #7a7a7a;
    text-align: left;
}
</style>
