import {getLast} from "@/common/arrays";

export const MeshArtParserFactory = {
    build(files: Array<{ uri: string | undefined | null, type: string }>): MeshArtParser | undefined {
        for (let i = 0; i < files.length; i++) {
            const file = files[i]
            if (typeof file.uri !== "string") {
                continue
            }

            let ext = new URLSearchParams(
                getLast((file.uri || '').split('?')), //ex: https://www.arweave.net/swP-83V4LTXV163cNFSbCLwp-Nwyua5Cjjyt_KewH-Q?ext=glb
            ).get('ext');
            if (isExtenstionValid(ext || '')) {
                return new MetaplexMeshArtParser(file.uri)
            }

            try {
                const uri = new URL(file.uri)
                ext = getLast(uri.pathname.split('.')) || ''
                if (isExtenstionValid(ext)) {
                    return new AlphaBatumMeshArtParser(file.uri)
                }
            } catch (e) {
                continue
            }
        }
    }
}

export interface MeshArtParser {
    uri: string,
    parse: () => Promise<string>
}

class AlphaBatumMeshArtParser implements MeshArtParser {
    uri: string
    constructor(uri: string) {
        this.uri = uri
    }
    parse(): Promise<string> {
        return Promise.resolve(this.uri)
    }
}

class MetaplexMeshArtParser implements MeshArtParser {
    uri: string
    constructor(uri: string) {
        this.uri = uri
    }

    async parse(): Promise<string> {
        const blobSrc = await fetch(this.uri)
            .then((resp) => {
                return resp.blob()
            })
            .then((blob) => {
                return new Blob([blob])
            })
            .catch(() => {
                return Promise.resolve(undefined)
            })
        return Promise.resolve(URL.createObjectURL(blobSrc || ''))
    }
}

const isExtenstionValid = (ext: string) => {
    return ext.includes('glb') || ext.includes('gltf')
}

