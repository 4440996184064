<template>
    <router-link :to="`/oasis/${item.token_id}`">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-2 text-center">
                        <img class="preview" :src="imageUri" />
                    </div>
                    <div class="col-5">
                        <h4>{{ item.name }}</h4>
                        <h6>{{ item.description }}</h6>
                    </div>
                    <div class="col-3">
                        <h4>{{ item.players_in_room }} / {{ item.capacity }}</h4>
                    </div>
                    <div class="col-2">
                        <router-link :to="`/oasis/${item.token_id}/visit`" class="btn btn-default">Join</router-link>
                    </div>
                </div>
            </div>
        </div>
    </router-link>
</template>

<script>
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({ name: 'RoomCard' })
export default class RoomCard extends Vue {
    @Prop(Object) item
    
    get imageUri() {
        if (this.item.sprite_uri !== undefined && this.item.sprite_uri !== '') return this.item.sprite_uri

        return this.item.image
    }
}
</script>

<style scoped>
.preview {
    max-height: 50px;
    width: auto;
}

.card {
    background: transparent;
    color: white;
    border: 1px solid #20126f;
}

.card-body::before {
    content: unset;
}

.card-body h4,
h5 {
    color: white;
}

.card-body {
    padding: 1rem;
}

h6,
small {
    color: #7a7a7a;
}

.rarity {
    color: red;
}
</style>
