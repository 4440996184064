export const AllSteps = {
  SelectCategory: {
    value: 1,
    text: 'Category',
  },
  Copies: {
    value: 2,
    text: 'Copies',
  },
  Price: {
    value: 3,
    text: 'Price',
  },
  Initial: {
    value: 4,
    text: 'Initial Phase',
  },
  Ending: {
    value: 5,
    text: 'Ending Phase',
  },
  Review: {
    value: 6,
    text: 'Review',
  },
  Publish: {
    value: 7,
    text: 'Publish',
  },
};

export const InstantSaleSteps = {
  SelectCategory: {
    value: 1,
    text: 'Category',
  },
  InstantSale: {
    value: 2,
    text: 'Instant Sale',
  },
  Review: {
    value: 3,
    text: 'Review',
  },
  Publish: {
    value: 4,
    text: 'Publish',
  },
};

export const LimitedSaleSteps = {
  SelectCategory: {
    value: 1,
    text: 'Category',
  },
  Copies: {
    value: 2,
    text: 'Copies',
  },
  Price: {
    value: 3,
    text: 'Price',
  },
  Initial: {
    value: 4,
    text: 'Initial Phase',
  },
  Ending: {
    value: 5,
    text: 'Ending Phase',
  },
  Participation: {
    value: 6,
    text: 'Participation NFT',
  },
  Review: {
    value: 7,
    text: 'Review',
  },
  Publish: {
    value: 8,
    text: 'Publish',
  },
};
