<template>
  <div class="item-card mb-5" @click="$emit('select')">
    <div class="item">
      <div class="media__container p-4">
        <div class="w-100 mb-2 p-0 tag--edition">
          <a-spin v-if="!badgeChecked" style="height: 32px" />
          <a-tag v-else color="black" class="m-0">{{ badge }}</a-tag>
        </div>
        <div class="media__body">
          <b-img-lazy
            class="img-fluid nft-image"
            :draggable="false"
            :alt="nft.metadataExternal.name || ''"
            :src="nft.metadataExternal.image"
          />
        </div>
      </div>
      <div class="detail__container">
        <h3 class="art-name">
          {{ nft.metadataOnchain.data.name }}
        </h3>
        <template v-if="!isQuickView">
          <p class="nft-description">About : {{ nft.metadataExternal.description }}</p>
          <p>
            Seller Basic :
            {{
              (nft.metadataExternal && nft.metadataExternal.seller_fee_basis_points) ||
              'N/A'
            }}
          </p>
        </template>
      </div>
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { pubkeyToString } from '@/views/shop/utils/ids';
import { ArtType } from '@/views/shop/types';

@Component({
  name: 'NftItem',
  methods: {
    ...mapActions('metaplexNFTModule', ['getExtendedArt']),
  },
})
export default class NftItem extends Vue {
  @Prop({ type: Object, required: true }) nft;
  @Prop(Boolean) isQuickView;
  badgeChecked = false;
  badge = '';

  get extNFT() {
    return this.nft.metadataExternal.image.split('.').pop();
  }

  get isImage() {
    return ['jpg', 'jpeg', 'png', 'gif', 'svg'].includes(this.extNFT);
  }

  get isVideo() {
    return ['mp4', 'mov'].includes(this.extNFT);
  }

  get isAudio() {
    return ['flac', 'mp3', 'wav'].includes(this.extNFT);
  }

  get playableURI() {
    const files = this.nft.metadataExternal?.properties?.files;
    if (!files) {
      return '';
    }

    return files.find(file => !file.type.includes('image'))?.uri || '';
  }

  async created() {
    const id = pubkeyToString(this.nft.mint);
    const art = await this.getExtendedArt(id);
    if (art.type === ArtType.NFT) {
      this.badge = 'Unique';
    } else if (art.type === ArtType.Master) {
      this.badge = 'NFT 0';
    } else if (art.type === ArtType.Print) {
      this.badge = `${art.edition} of ${art.supply}`;
    }

    this.badgeChecked = true;
  }
}
</script>

<style scoped lang="scss">
.item-card {
  min-height: 10vh;
}

.item {
  color: white;
  background: #0e0e0f;
  border-radius: 8px;
  box-shadow: black 0 4px 7px;
  transition: all 0.3s ease-in-out;
}

.detail__container {
  padding: 1rem;
}

h2,
h3,
h4 {
  text-align: left;
  color: white;
}

h2 {
  text-align: center;
  font-size: 2rem;
}

h2.price {
  text-align: right;
}

.item:hover {
  transform: scale(1.05);
  box-shadow: black 0 7px 9px;
}

.img-fluid {
  width: 100%;
  object-fit: cover;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.nft-description {
  max-height: 130px;
  overflow: hidden;
  word-break: break-word;
  overflow-wrap: break-word;
  text-overflow: ellipsis;
}

.nft-image {
  max-height: 300px;
  border-radius: 8px;
}

.tag--edition {
  text-align: right;
  opacity: 0.8;
}

.detail__container {
  p,
  h3 {
    opacity: 0.8;
  }

  h3 {
    font-size: 1.5rem;
  }
}

.ant-tag {
  padding: 5px 16px;
  text-transform: uppercase;
  font-weight: bold;
  border-radius: 24px;
}

.art-name {
  overflow: hidden;
  text-overflow: ellipsis;
}

.item-card {
  cursor: pointer;
}

video {
  width: 100%;
}
</style>
