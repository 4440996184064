import { render, staticRenderFns } from "./FixedBet.vue?vue&type=template&id=450ec10a&scoped=true&"
import script from "./FixedBet.vue?vue&type=script&lang=js&"
export * from "./FixedBet.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "450ec10a",
  null
  
)

export default component.exports