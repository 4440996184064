




import { Component, Vue } from 'vue-property-decorator'
@Component({
  name: 'index',
  components: {
  },
})
export default class index extends Vue {

}
