<template>
    <div class="card">
        <div class="card-body text-center">
            <div class="row">
                <div class="col">
                    <small>Min Bet</small>
                </div>
                <div class="col"><h5>Place Bet</h5></div>
                <div class="col">
                    <small>Max Bet</small>
                </div>
            </div>

            <div class="col-lg-10 col-md-12 col-sm-12 offset-lg-1">
                <div class="row">
                    <div class="col-3">
                        <label class="currency">{{ min_bet }}</label>
                    </div>
                    <div class="col"><input class="form-control" type="range" :min="min_bet" step="1" :max="max_bet" v-model="betToPlace" /></div>
                    <div class="col-3">
                        <label class="currency">{{ max_bet }}</label>
                    </div>
                </div>
            </div>

            <div class="col-lg-8 col-md-12 col-sm-12 offset-lg-2 mt-4">
                <div class="row">
                    <div class="col-4"><img src="https://cryptologos.cc/logos/harmony-one-logo.svg?v=014" height="40px" /></div>
                    <div class="col"><input class="form-control text-center" type="number" min="0" v-model="betToPlace" max="10" step="0.1" /></div>
                    <div class="col-4"><label class="currency text">ONE</label></div>
                </div>
            </div>

            <h6 class="mt-4">
                Current Address:
                <small>{{ $store.state.wallet_addr }}</small>
            </h6>

            <button class="btn mt-2 mb-2" v-show="!bets_enabled" disabled>Loading Contract...</button>
            <button class="btn btn-default mt-2 mb-2" v-show="bets_enabled" v-on:click="play">Place Bet</button>
        </div>
    </div>
</template>

<script>
import { Component, Prop, Vue } from 'vue-property-decorator'
@Component({ name: 'PlaceBet' })
export default class PlaceBet extends Vue {
    @Prop({ type: Boolean, default: false }) bets_enabled
    @Prop(Number) min_bet
    @Prop(Number) max_bet

    betToPlace = 0

    play() {
        //TODO Validation
        this.$emit('place-bet', this.betToPlace)
    }
    
    mounted() {
        this.betToPlace = this.min_bet
    }
}
</script>

<style scoped></style>
