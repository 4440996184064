import Vue from 'vue';
import Router, { RouteConfig } from 'vue-router';
import MetaplexRoutes from '@/views/shop/routes';

Vue.use(Router);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Dashboard',
    component: () =>
      import(/* webpackChunkName: "home" */ '@/views/dashboard/Dashboard.vue'),
  },
  {
    path: '/',
    name: 'Login',
    component: () => import(/* webpackChunkName: "home" */ '@/views/auth/Login.vue'),
  },
  // Sage
  {
    path: '/sagas',
    name: 'Saga',
    component: () =>
      import(/* webpackChunkName: "saga_index" */ '@/views/sagas/index.vue'),
    children: [
      {
        path: '',
        name: 'Saga Index',
        component: () =>
          import(/* webpackChunkName: "saga_index" */ '@/views/sagas/SagaIndex.vue'),
      },
      {
        path: ':id',
        name: 'Saga Show',
        component: () =>
          import(/* webpackChunkName: "saga_show" */ '@/views/sagas/SagaShow.vue'),
      },
      {
        path: ':id/items',
        name: 'Saga Item Index',
        component: () =>
          import(
            /* webpackChunkName: "saga_item_index" */ '@/views/sagas/SagaItemIndex.vue'
          ),
      },
      {
        path: '/sagas/:saga_id/items/:id',
        name: 'Saga Item Show',
        component: () =>
          import(/* webpackChunkName: "item_show" */ '@/views/items/ItemShow.vue'),
      },
    ],
  },
  //Marketplace
  {
    path: '/marketplace',
    name: 'Marketplace',
    component: () =>
      import(/* webpackChunkName: "saga_index" */ '@/views/marketplace/index.vue'),
    children: [
      {
        path: '',
        name: 'Marketplace Index',
        component: () =>
          import(
            /* webpackChunkName: "item_index" */ '@/views/marketplace/MarketIndex.vue'
          ),
      },
      {
        path: 'collections',
        name: 'Marketplace Collections',
        component: () =>
          import(
            /* webpackChunkName: "item_index" */ '@/views/marketplace/MarketIndex.vue'
          ), //TODO
      },
      {
        path: 'collections/:id',
        name: 'Marketplace Collections Detail',
        component: () =>
          import(
            /* webpackChunkName: "item_index" */ '@/views/marketplace/MarketIndex.vue'
          ), //TODO
      },
      {
        path: 'assets/:id',
        name: 'Marketplace Item Detail',
        component: () =>
          import(
            /* webpackChunkName: "item_index" */ '@/views/marketplace/MarketShowItem.vue'
          ),
      },
    ],
  },
  //Item
  {
    path: '/items',
    name: 'Item',
    component: () =>
      import(/* webpackChunkName: "item_index" */ '@/views/items/index.vue'),
    children: [
      {
        path: '',
        name: 'Item Index',
        component: () =>
          import(/* webpackChunkName: "item_index" */ '@/views/items/ItemIndex.vue'),
      },
      {
        path: ':id',
        name: 'Item Show',
        component: () =>
          import(/* webpackChunkName: "item_show" */ '@/views/items/ItemShow.vue'),
      },
    ],
  },
  //Player
  {
    path: '/players',
    name: 'Player',
    component: () =>
      import(/* webpackChunkName: "player_index" */ '@/views/players/index.vue'),
    children: [
      {
        path: '',
        name: 'Player Index',
        component: () =>
          import(
            /* webpackChunkName: "player_index" */ '@/views/players/PlayerIndex.vue'
          ),
      },
      {
        path: ':id',
        name: 'Player Show',
        component: () =>
          import(/* webpackChunkName: "player_show" */ '@/views/players/PlayerShow.vue'),
      },
    ],
  },
  //Profile
  {
    path: '/profile',
    name: 'Profile',
    component: () =>
      import(/* webpackChunkName: "player_index" */ '@/views/profile/index.vue'),
    children: [
      {
        path: '',
        name: 'Player Profile',
        component: () =>
          import(
            /* webpackChunkName: "player_index" */ '@/views/profile/ProfileIndex.vue'
          ),
      },
      {
        path: '/profile/update',
        name: 'Player Profile',
        component: () =>
          import(
            /* webpackChunkName: "player_index" */ '@/views/profile/ProfileUpdate.vue'
          ),
      },
    ],
  },
  //Map
  {
    path: '/map',
    name: 'Map',
    component: () =>
      import(/* webpackChunkName: "room_index" */ '@/views/land/index.vue'),
    children: [
      {
        path: '',
        name: 'Map index',
        component: () =>
          import(/* webpackChunkName: "room_index" */ '@/views/land/LandIndex2.vue'),
      },
      {
        path: 'purchase',
        name: 'Map Purchase',
        component: () =>
          import(/* webpackChunkName: "room_index" */ '@/views/land/LandIndex2.vue'),
      },
    ],
  },
  //Oasis
  {
    path: '/oasis',
    name: 'Oasis',
    component: () =>
      import(/* webpackChunkName: "room_index" */ '@/views/rooms/index.vue'),
    children: [
      {
        path: '',
        name: 'Oasis Index',
        component: () =>
          import(/* webpackChunkName: "room_index" */ '@/views/rooms/RoomIndex.vue'),
      },
      {
        path: 'create',
        name: 'Oasis Create',
        component: () =>
          import(/* webpackChunkName: "room_index" */ '@/views/tools/CreateRoom.vue'),
      },
      {
        path: ':id',
        name: 'Oasis Show',
        component: () =>
          import(/* webpackChunkName: "room_show" */ '@/views/rooms/RoomShow.vue'),
      },
      {
        path: ':id/visit',
        name: 'Rooms',
        component: () =>
          import(/* webpackChunkName: "visit_room" */ '@/views/rooms/VisitRoom.vue'),
      },
    ],
  },
  //Lounge
  {
    path: '/lounge',
    name: 'Lounge',
    component: () =>
      import(/* webpackChunkName: "game_lounge" */ '@/views/lounge/index.vue'),
    children: [
      {
        path: 'games',
        name: 'Games Lounge Index',
        component: () =>
          import(
            /* webpackChunkName: "game_lounge" */ '@/views/lounge/GameLoungeIndex.vue'
          ),
        children: [
          {
            path: '',
            name: 'Games Lounge',
            component: () =>
              import(/* webpackChunkName: "dice_one" */ '@/views/lounge/GameLounge.vue'),
          },
          {
            path: 'classic_dice',
            name: 'Classic Dice',
            component: () =>
              import(/* webpackChunkName: "dice_one" */ '@/views/contracts/DiceOne.vue'),
          },
          {
            path: 'spin_to_win',
            name: 'Spin To Win',
            component: () =>
              import(
                /* webpackChunkName: "spin_to_win" */ '@/views/contracts/SpinToWin.vue'
              ),
          },
        ],
      },
    ],
  },

  {
    path: '/nft-import',
    name: 'NFT Import',
    component: () => import(/* webpackChunkName: "nft_import" */ '@/views/NFTImport.vue'),
  },

  {
    path: '/contracts/harmony/dice_one',
    name: 'Harmony Dice',
    component: () =>
      import(/* webpackChunkName: "dice_one" */ '@/views/contracts/DiceOne.vue'),
  },

  {
    path: '/admin/3c5bd2fb-4580-4f2d-8ee9-8cdb395c6217',
    name: 'Uploader',
    component: () =>
      import(/* webpackChunkName: "visit_room" */ '@/views/admin/TestItemUploader.vue'),
  },
];

routes.push(...MetaplexRoutes);

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});
