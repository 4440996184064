import { Connection, PublicKey } from '@solana/web3.js';
import { ParsedAccount } from '@/views/shop/contexts/accounts/types';
import { StoreIndexer } from '@/views/shop/actions/cacheAuctionDefines';
import { getStoreIndexer } from '@/views/shop/actions/cacheAuction';
import { deserializeUnchecked } from 'borsh';

export const pullPages = async (
  connection: Connection,
): Promise<ParsedAccount<StoreIndexer>[]> => {
  let i = 0;

  let pageKey = await getStoreIndexer(i);
  let account = await connection.getAccountInfo(new PublicKey(pageKey));
  const pages: ParsedAccount<StoreIndexer>[] = [];
  while (account) {
    pages.push({
      info: decodeStoreIndexer(account.data),
      pubkey: pageKey.toBase58(),
      account,
    });
    i++;

    pageKey = await getStoreIndexer(i);
    account = await connection.getAccountInfo(new PublicKey(pageKey));
  }
  return pages;
};

export const decodeStoreIndexer = (buffer: Buffer) => {
  return deserializeUnchecked(StoreIndexer.SCHEMA, StoreIndexer, buffer) as StoreIndexer;
};
