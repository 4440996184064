import {
  Keypair,
  PublicKey,
  SYSVAR_CLOCK_PUBKEY,
  SYSVAR_RENT_PUBKEY,
  TransactionInstruction,
} from '@solana/web3.js';
import { WalletNotConnectedError } from '@solana/wallet-adapter-base';
import BN from 'bn.js';
import { WalletAdapter } from '@solana/wallet-adapter-base/src/adapter';
import {
  ERROR_INVALID_ACCOUNT_DATA,
  StringPublicKey,
} from '@metaplex-foundation/mpl-core';
import { ParsedAccount } from '@/views/shop/contexts/accounts/types';
import { getStoreID, programIds, pubkeyToString, toPublicKey } from '@/views/shop/utils';
import { deserializeUnchecked, serialize } from 'borsh';
import { METAPLEX_PREFIX } from '@/views/shop/constants';
import {
  AuctionCache,
  INDEX,
  MAX_INDEXED_ELEMENTS,
  SetAuctionCacheArgs,
  SetStoreIndexArgs,
  StoreIndexer,
} from '@/views/shop/actions/cacheAuctionDefines';
import { programs } from '@metaplex/js';

// This command caches an auction at position 0, page 0, and moves everything up
export async function cacheAuctionIndexer(
  wallet: WalletAdapter,
  vault: StringPublicKey,
  auction: StringPublicKey,
  auctionManager: StringPublicKey,
  tokenMints: StringPublicKey[],
  storeIndexer: ParsedAccount<StoreIndexer>[],
  skipCache?: boolean,
): Promise<{
  instructions: TransactionInstruction[][];
  signers: Keypair[][];
}> {
  if (!wallet.publicKey) throw new WalletNotConnectedError();
  const payer = wallet.publicKey.toBase58();

  const instructions: TransactionInstruction[] = [];

  const {
    auctionCache,
    instructions: createAuctionCacheInstructions,
    signers: createAuctionCacheSigners,
  } = await createAuctionCache(wallet, vault, auction, auctionManager, tokenMints);

  const above =
    storeIndexer.length == 0 ? undefined : storeIndexer[0].info.auctionCaches[0];

  const storeIndexKey = await getStoreIndexer(0);
  await setStoreIndex(
    storeIndexKey.toBase58(),
    auctionCache,
    payer,
    new BN(0),
    new BN(0),
    instructions,
    undefined,
    above,
  );

  const { instructions: propagationInstructions, signers: propagationSigners } =
    await propagateIndex(wallet, storeIndexer);

  return {
    instructions: [
      ...(skipCache ? [] : createAuctionCacheInstructions),
      instructions,
      ...propagationInstructions,
    ],
    signers: [...(skipCache ? [] : createAuctionCacheSigners), [], ...propagationSigners],
  };
}

const INDEX_TRANSACTION_SIZE = 10;
async function propagateIndex(
  wallet: WalletAdapter,
  storeIndexer: ParsedAccount<StoreIndexer>[],
): Promise<{ instructions: TransactionInstruction[][]; signers: Keypair[][] }> {
  if (!wallet.publicKey) throw new WalletNotConnectedError();

  const payer = wallet.publicKey.toBase58();

  const currSignerBatch: Array<Keypair[]> = [];
  const currInstrBatch: Array<TransactionInstruction[]> = [];

  let indexSigners: Keypair[] = [];
  let indexInstructions: TransactionInstruction[] = [];

  let currPage: ParsedAccount<StoreIndexer> | null = storeIndexer[0];
  let lastPage: ParsedAccount<StoreIndexer> | null = null;
  while (currPage && currPage.info.auctionCaches.length == MAX_INDEXED_ELEMENTS) {
    const cacheLeavingThePage =
      currPage.info.auctionCaches[currPage.info.auctionCaches.length - 1];
    const nextPage: ParsedAccount<StoreIndexer> =
      storeIndexer[currPage.info.page.toNumber() + 1];
    if (nextPage) {
      lastPage = currPage;
      currPage = nextPage;
    } else {
      lastPage = currPage;
      currPage = null;
    }

    const storeIndexKey = currPage
      ? currPage.pubkey
      : await getStoreIndexer(lastPage.info.page.toNumber() + 1);
    const above = currPage ? currPage.info.auctionCaches[0] : undefined;

    await setStoreIndex(
      pubkeyToString(storeIndexKey),
      cacheLeavingThePage,
      payer,
      lastPage.info.page.add(new BN(1)),
      new BN(0),
      indexInstructions,
      undefined,
      above,
    );

    if (indexInstructions.length >= INDEX_TRANSACTION_SIZE) {
      currSignerBatch.push(indexSigners);
      currInstrBatch.push(indexInstructions);
      indexSigners = [];
      indexInstructions = [];
    }
  }

  if (indexInstructions.length < INDEX_TRANSACTION_SIZE && indexInstructions.length > 0) {
    currSignerBatch.push(indexSigners);
    currInstrBatch.push(indexInstructions);
  }

  return {
    instructions: currInstrBatch,
    signers: currSignerBatch,
  };
}

const TRANSACTION_SIZE = 10;

async function createAuctionCache(
  wallet: WalletAdapter,
  vault: StringPublicKey,
  auction: StringPublicKey,
  auctionManager: StringPublicKey,
  tokenMints: StringPublicKey[],
): Promise<{
  auctionCache: StringPublicKey;
  instructions: TransactionInstruction[][];
  signers: Keypair[][];
}> {
  if (!wallet.publicKey) throw new WalletNotConnectedError();

  const payer = wallet.publicKey.toBase58();

  const currSignerBatch: Array<Keypair[]> = [];
  const currInstrBatch: Array<TransactionInstruction[]> = [];

  let cacheSigners: Keypair[] = [];
  let cacheInstructions: TransactionInstruction[] = [];
  const auctionCache = await getAuctionCache(wallet.publicKey, auction);
  if (!auctionCache) {
    throw ERROR_INVALID_ACCOUNT_DATA();
  }

  for (let i = 0; i < tokenMints.length; i++) {
    const safetyDeposit = await getSafetyDepositBoxAddress(vault, tokenMints[i]);

    await setAuctionCache(
      auctionCache.toBase58(),
      payer,
      auction,
      safetyDeposit,
      auctionManager,
      new BN(0),
      cacheInstructions,
    );

    if (cacheInstructions.length >= TRANSACTION_SIZE) {
      currSignerBatch.push(cacheSigners);
      currInstrBatch.push(cacheInstructions);
      cacheSigners = [];
      cacheInstructions = [];
    }
  }

  if (cacheInstructions.length < TRANSACTION_SIZE && cacheInstructions.length > 0) {
    currSignerBatch.push(cacheSigners);
    currInstrBatch.push(cacheInstructions);
  }

  return {
    auctionCache: auctionCache.toBase58(),
    instructions: currInstrBatch,
    signers: currSignerBatch,
  };
}

export async function setAuctionCache(
  auctionCache: StringPublicKey,
  payer: StringPublicKey,
  auction: StringPublicKey,
  safetyDepositBox: StringPublicKey,
  auctionManager: StringPublicKey,
  page: BN,
  instructions: TransactionInstruction[],
) {
  const PROGRAM_IDS = programIds();
  const store = PROGRAM_IDS.store;
  if (!store) {
    throw new Error('Store not initialized');
  }

  const value = new SetAuctionCacheArgs();
  const data = Buffer.from(serialize(SetAuctionCacheArgs.SCHEMA, value));

  const keys = [
    {
      pubkey: toPublicKey(auctionCache),
      isSigner: false,
      isWritable: true,
    },
    {
      pubkey: toPublicKey(payer),
      isSigner: true,
      isWritable: false,
    },
    {
      pubkey: toPublicKey(auction),
      isSigner: false,
      isWritable: false,
    },

    {
      pubkey: toPublicKey(safetyDepositBox),
      isSigner: false,
      isWritable: false,
    },

    {
      pubkey: toPublicKey(auctionManager),
      isSigner: false,
      isWritable: false,
    },

    {
      pubkey: toPublicKey(store),
      isSigner: false,
      isWritable: false,
    },
    {
      pubkey: PROGRAM_IDS.system,
      isSigner: false,
      isWritable: false,
    },
    {
      pubkey: SYSVAR_RENT_PUBKEY,
      isSigner: false,
      isWritable: false,
    },
    {
      pubkey: SYSVAR_CLOCK_PUBKEY,
      isSigner: false,
      isWritable: false,
    },
  ];

  instructions.push(
    new TransactionInstruction({
      keys,
      programId: toPublicKey(PROGRAM_IDS.metaplex),
      data,
    }),
  );
}

export async function getAuctionCache(walletPubkey: PublicKey, auction: StringPublicKey) {
  const PROGRAM_IDS = programIds();
  const store = await getStoreID(walletPubkey.toBase58());
  if (!store) {
    return;
  }
  return (
    await PublicKey.findProgramAddress(
      [
        Buffer.from(METAPLEX_PREFIX),
        toPublicKey(PROGRAM_IDS.metaplex).toBuffer(),
        toPublicKey(store).toBuffer(),
        toPublicKey(auction).toBuffer(),
        Buffer.from('cache'),
      ],
      toPublicKey(PROGRAM_IDS.metaplex),
    )
  )[0];
}

export async function getStoreIndexer(page: number) {
  const PROGRAM_IDS = programIds();
  const store = PROGRAM_IDS.store;
  if (!store) {
    throw new Error('Store not initialized');
  }

  return (
    await PublicKey.findProgramAddress(
      [
        Buffer.from(METAPLEX_PREFIX),
        toPublicKey(PROGRAM_IDS.metaplex).toBuffer(),
        toPublicKey(store).toBuffer(),
        Buffer.from(INDEX),
        Buffer.from(page.toString()),
      ],
      toPublicKey(PROGRAM_IDS.metaplex),
    )
  )[0];
}

export async function setStoreIndex(
  storeIndex: StringPublicKey,
  auctionCache: StringPublicKey,
  payer: StringPublicKey,
  page: BN,
  offset: BN,
  instructions: TransactionInstruction[],
  belowCache?: StringPublicKey,
  aboveCache?: StringPublicKey,
) {
  const PROGRAM_IDS = programIds();
  const store = PROGRAM_IDS.store;
  if (!store) {
    throw new Error('Store not initialized');
  }

  const value = new SetStoreIndexArgs({ page, offset });
  const data = Buffer.from(serialize(SetStoreIndexArgs.SCHEMA, value));

  const keys = [
    {
      pubkey: toPublicKey(storeIndex),
      isSigner: false,
      isWritable: true,
    },
    {
      pubkey: toPublicKey(payer),
      isSigner: true,
      isWritable: false,
    },
    {
      pubkey: toPublicKey(auctionCache),
      isSigner: false,
      isWritable: false,
    },

    {
      pubkey: toPublicKey(store),
      isSigner: false,
      isWritable: false,
    },
    {
      pubkey: PROGRAM_IDS.system,
      isSigner: false,
      isWritable: false,
    },
    {
      pubkey: SYSVAR_RENT_PUBKEY,
      isSigner: false,
      isWritable: false,
    },
  ];

  if (aboveCache) {
    keys.push({
      pubkey: toPublicKey(aboveCache),
      isSigner: false,
      isWritable: false,
    });
  }

  if (belowCache) {
    keys.push({
      pubkey: toPublicKey(belowCache),
      isSigner: false,
      isWritable: false,
    });
  }
  instructions.push(
    new TransactionInstruction({
      keys,
      programId: toPublicKey(PROGRAM_IDS.metaplex),
      data,
    }),
  );
}

export async function getSafetyDepositBoxAddress(
  vault: StringPublicKey,
  tokenMint: StringPublicKey,
): Promise<StringPublicKey> {
  const PROGRAM_IDS = programIds();
  return (
    await PublicKey.findProgramAddress(
      [
        Buffer.from(programs.vault.VaultProgram.PREFIX),
        toPublicKey(vault).toBuffer(),
        toPublicKey(tokenMint).toBuffer(),
      ],
      toPublicKey(PROGRAM_IDS.vault),
    )
  )[0].toBase58();
}

export const decodeAuctionCache = (buffer: Buffer) => {
  return deserializeUnchecked(AuctionCache.SCHEMA, AuctionCache, buffer) as AuctionCache;
};
