<template>
    <div class="col-12 bg-card">
        <div class="row">
            <div class="pet col-2 col-lg-2 col-sm-4 col-md-3" v-for="(pet, idx) in pets" :key="idx" v-on:click="activePet = idx">
                <span v-show="idx === activePet" class="active-pet">ACTIVE</span>
                <img class="img-fluid pet-image" :src="pet.src" alt="pet image" />
            </div>
        </div>
    </div>
</template>

<script>
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({ name: 'PetList' })
export default class PetList extends Vue {
    activePet = 1
    pets = [
        { src: 'https://storage.googleapis.com/assets.axieinfinity.com/axies/184305/axie/axie-full-transparent.png' },
        { src: 'https://storage.googleapis.com/assets.axieinfinity.com/axies/7868722/axie/axie-full-transparent.png' },
        { src: 'https://storage.googleapis.com/assets.axieinfinity.com/axies/6333244/axie/axie-full-transparent.png' },
        { src: 'https://storage.googleapis.com/assets.axieinfinity.com/axies/6211196/axie/axie-full-transparent.png' },
        { src: 'https://storage.googleapis.com/assets.axieinfinity.com/axies/228480/axie/axie-full-transparent.png' },
    ]
}
</script>

<style scoped>
.pet-image {
    max-height: 200px;
}

h1 {
    font-family: 'Prime-Light', sans-serif;
    color: #0a9fb9;
    font-size: 2rem;
}

.pet {
    cursor: pointer;
}

.active-pet {
    position: absolute;
    top: 0;
    border-radius: 12px;
    padding: 5px;
    color: white;
    background: #0a9fb9;
    font-size: 0.8rem;
    font-weight: bold;
}
</style>
