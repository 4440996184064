import { mapActions, mapMutations, mapState } from 'vuex';
import { Component, Vue, Watch } from 'vue-property-decorator';

import { getStoreID } from '@/views/shop/utils';

@Component({
  name: 'metaplexStoreMixin',
  computed: {
    ...mapState('walletModule', ['wallet_addr']),
  },
  methods: {
    ...mapActions('marketplaceAccountModule', [
      'fetchMetaplexAccountsInitIndexer',
      'fetchAuctionAccountsInitSaleList',
      'fetchVaultAccountsInitSaleList',
    ]),
    ...mapMutations('marketplaceAccountModule', ['initialized']),
  },
})
export default class metaplexStoreMixin extends Vue {
  fetchAuctionAccountsInitSaleList: (() => void) | undefined;
  fetchMetaplexAccountsInitIndexer: (() => void) | undefined;
  fetchVaultAccountsInitSaleList: (() => void) | undefined;
  initialized: (() => void) | undefined;

  async setupStoreByOwner(address: any) {
    const storeId = (await getStoreID(address)) || '';
    return this.$store.commit('walletModule/set_store_id', storeId);
  }

  async pullAccountToPrepare() {
    await this.fetchMetaplexAccountsInitIndexer?.();
    return Promise.all([
      this.fetchAuctionAccountsInitSaleList?.(),
      this.fetchVaultAccountsInitSaleList?.(),
    ]);
  }

  @Watch('wallet_addr', { immediate: true })
  async watchWalletChanges(add: string) {
    if (!add) {
      return;
    }
    this.setupStoreByOwner(add).then(this.pullAccountToPrepare).then(this.initialized);
  }
}
