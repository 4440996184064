<template>
    <div class="container-fluid p-5">
        <h1>Uploader</h1>

        <div class="row mt-5">
            <div class="col-12">
                <form class="form">
                    <div class="form-group">
                        <label>Name</label>
                        <input class="form-control" placeholder="Name" v-model="form.name" />
                    </div>
                    <div class="form-group">
                        <label>Description</label>
                        <input class="form-control" placeholder="Description" v-model="form.description" />
                    </div>
                    <div class="form-group">
                        <label>SpriteURI</label>
                        <input class="form-control" placeholder="SpriteURI" v-model="form.sprite_uri" />
                    </div>
                </form>
            </div>

            <div class="offset-4 col-4">
                <button v-on:click="submit" type="submit" class="btn btn-primary btn-block">Create</button>
                <button v-on:click="autoSpawn" type="submit" class="btn btn-primary btn-block">Spawn</button>
            </div>
        </div>
    </div>
</template>

<script>
import Api from '@/api/api'
import { Component, Vue } from 'vue-property-decorator'

@Component({ name: 'TestItemUploader' })
export default class TestItemUploader extends Vue {
    form = {
        owner_addr: '0x1b47f49a325EFC79ad3a8d780b77Ce74bbF9a545', //TODO
        chain_id: 0,
        contract_addr: '0x0',
        token_addr: '', //TODO uuid
        nft_platform: 0,
        metadata_uri: '',
        name: '',
        description: '',
        sprite_external: false,
        sprite_uri: 'https://app.babilu.online/static/uploads/demo-furniture/',
        item_type: 1,
        interaction_type: 1,
        display_type: 1, //3D
        details: {
            tile_x: 1,
            tile_y: 1,
            tile_z: 1,
            isometric_shader: false,
            scale_x: 1,
            scale_y: 1,
            scale_z: 1,
            position_x: 0,
            position_y: 0,
            position_z: 0,
        },
    }

    spawn = [
        'Bathroom_Toilet.glb',
        'Bathroom_ToiletPaper.glb',
        'Bathroom_ToiletPaperPile.glb',
        'Bathroom_Towel.glb',
        'Bed_King.glb',
        'Bed_Single.glb',
        'Carpet_1-convcol.glb',
        'Carpet_2.glb',
        'Carpet_Round.glb',
        'Chair_1.glb',
        'Chair_2.glb',
        'Chair_3.glb',
        'Chair_4.glb',
        'Column_Round1.glb',
        'Column_Round2.glb',
        'Column_Round3.glb',
        'Column_SquareBig.glb',
        'Column_SquareSmall.glb',
        'Couch_L.glb',
        'Couch_Large1.glb',
        'Couch_Large2.glb',
        'Couch_Large3.glb',
        'Couch_Medium1.glb',
        'Couch_Medium2.glb',
        'Couch_Small1.glb',
        'Couch_Small2.glb',
        'Curtains_Double.glb',
        'Curtains_Single.glb',
        'Door_1.glb',
        'Door_2.glb',
        'Door_6.glb',
        'Door_Double.glb',
        'Drawer_1.glb',
        'Drawer_2.glb',
        'Drawer_3.glb',
        'Drawer_5.glb',
        'Fireplace.glb',
        'Houseplant_1.glb',
        'Houseplant_2.glb',
        'Houseplant_3.glb',
        'Houseplant_5.glb',
        'Houseplant_6.glb',
        'Houseplant_7.glb',
        'Houseplant_8.glb',
        'Kitchen_1Drawers.glb',
        'Kitchen_2Drawers.glb',
        'Kitchen_3Drawers.glb',
        'Kitchen_Cabinet1.glb',
        'Kitchen_Cabinet2.glb',
        'Kitchen_Fridge.glb',
        'Kitchen_Oven.glb',
        'Kitchen_Oven_Large.glb',
        'Kitchen_Sink.glb',
        'Light_Desk.glb',
        'Light_Floor1.glb',
        'Light_Floor2.glb',
        'Light_Floor3.glb',
        'Light_Floor4.glb',
        'Light_Stand1.glb',
        'Light_Stand2.glb',
        'NightStand_1.glb',
        'Spoon.glb',
        'Stool.glb',
        'Table_RoundLarge.glb',
        'Table_RoundSmall.glb',
        'Table_RoundSmall2.glb',
        'Trashcan_Cylindric.glb',
        'Trashcan_Green.glb',
        'Trashcan_Large.glb',
        'Trashcan_Small1.glb',
    ]

    submit(e) {
        e.preventDefault()
        let payload = Object.assign({}, this.form)
        this.send(payload)
    }

    send(payload) {
        payload.token_addr = this.uuidv4()

        console.log('Payload: ', payload)

        // axios.post("https://app.babilu.online/importer/requests", payload).then(resp => {
        Api.post('http://localhost:9093/importer/requests', payload).then(resp => {
            console.log(resp.data)
        })
    }
	
    uuidv4() {
        return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c => (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16))
    }

    autoSpawn() {
        const f = {
            owner_addr: '0x1b47f49a325EFC79ad3a8d780b77Ce74bbF9a545', //TODO
            chain_id: 0,
            contract_addr: '0x0',
            token_addr: '', //TODO uuid
            nft_platform: 0,
            metadata_uri: '',
            name: '',
            description: '',
            sprite_external: false,
            sprite_uri: 'https://app.babilu.online/static/uploads/demo-furniture/',
            item_type: 1,
            interaction_type: 1,
            display_type: 1, //3D
            details: {
                tile_x: 1,
                tile_y: 1,
                tile_z: 1,
                isometric_shader: false,
                scale_x: 1,
                scale_y: 1,
                scale_z: 1,
                position_x: 0,
                position_y: 0,
                position_z: 0,
            },
        }

        for (let i in this.spawn) {
            const s = this.spawn[i]
            let payload = Object.assign({}, f)

            console.log(s)
            const n = s.replaceAll('_', ' ').replaceAll('.glb', '')

            payload.name = n
            payload.description = n
            payload.sprite_uri = `https://app.babilu.online/static/uploads/demo-furniture/${s}`
            this.send(payload)
        }
    }
}
</script>

<style scoped>
h1 {
    color: white;
    font-size: 2rem;
}

label {
    color: white;
}
</style>
