<template>
  <transition name="fade">
    <div v-if="!onLoaded" class="col-12 d-flex align-items-center justify-content-center">
      <a-spin></a-spin>
    </div>
    <div v-else>
      <div v-if="list.length" class="row" :class="onPopup ? 'mt-3' : 'mt-5 p-4'">
        <div class="listItem">
          <div class="row mx-2">
            <NFTItem
              v-for="(nft, idx) in list"
              @select="onSelectNFT(nft)"
              :key="idx"
              :class="[{ 'col-6': onPopup, 'col-md-3 col-xs-6': !onPopup }]"
              :nft="nft"
              :is-quick-view="onPopup"
            />
          </div>
        </div>
      </div>
      <h3 class="text-white" v-else>No NFT founded</h3>

      <div class="container"></div>
    </div>
  </transition>
</template>

<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import { mapActions } from 'vuex';

@Component({
  name: 'ArtWork',
  components: {
    NFTItem: () => import('@/views/shop/components/NftItem.vue'),
  },
  methods: {
    ...mapActions('metaplexNFTModule', ['fetchNFTs']),
  },
})
export default class ArtWork extends Vue {
  @Prop(Boolean) onPopup;

  onLoaded = false;
  list = [];

  async created() {
    //@ts-ignore
    this.list = await this.fetchNFTs();
    this.onLoaded = true;
  }

  onSelectNFT(nft) {
    if (!this.onPopup) {
      this.$router.push(`/shop/artworks/${nft.mint}`);
      return;
    }

    this.$emit('select', nft);
  }
}
</script>

<style lang="scss" scoped>
.fill-height {
  height: 100%;
}

.item-card {
  margin-bottom: 20px;
}
</style>
