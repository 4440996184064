import { Keypair, Transaction, TransactionInstruction } from '@solana/web3.js';
import { Connection } from '@metaplex/js';
import { WalletAdapter } from '@solana/wallet-adapter-base/src/adapter';

export const signInstructions = async (
  connection: Connection,
  wallet: WalletAdapter,
  ints: TransactionInstruction[],
  signers?: Keypair[],
): Promise<string> => {
  const block = await connection.getRecentBlockhash('single');
  const tx = new Transaction({
    feePayer: wallet.publicKey,
    recentBlockhash: block.blockhash,
  });
  tx.add(...ints);
  if (signers) {
    tx.sign(...signers);
  }
  return wallet.sendTransaction(tx, connection);
};
