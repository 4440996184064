import { AUCTION_ID, pubkeyToString } from '@/views/shop/utils';
import { ProcessAccountsFunc } from '@/views/shop/contexts/meta/types';
import { ParsedAccount } from '@/views/shop/contexts/accounts/types';
import { AuctionData, AuctionDataExtended } from '@metaplex-foundation/mpl-auction';
import { programs } from '@metaplex/js';

const MAX_AUCTION_DATA_EXTENDED_SIZE = 8 + 9 + 2 + 9 + 33 + 158;

const isExtendedAuctionAccount = (account: any) =>
  account.data.length === MAX_AUCTION_DATA_EXTENDED_SIZE;

const isAuctionAccount = (account: any) =>
  account && pubkeyToString(account.owner) === AUCTION_ID;

export const processAuctionAccounts: ProcessAccountsFunc = async (
  { account, pubkey },
  setter,
) => {
  if (!isAuctionAccount(account)) return;

  try {
    const parsedAccount: ParsedAccount<AuctionData> = {
      account,
      pubkey,
      info: new programs.auction.Auction(pubkey, account).data,
    };
    setter('auctions', pubkey, parsedAccount);
  } catch {
    //
  }

  try {
    if (isExtendedAuctionAccount(account)) {
      const parsedAccount: ParsedAccount<AuctionDataExtended> = {
        account,
        pubkey,
        info: new programs.auction.AuctionExtended(pubkey, account).data,
      };

      setter('auctionDataExtended', pubkey, parsedAccount);
    }
  } catch {
    // ignore errors
    // add type as first byte for easier deserialization
  }
};
