<template>
    <div class="container-fluid nft-import show">
        <div class="row">
            <BannerTop title_blue="NFT Import" heading="Import your NFT into the AlphaBatem Universe" class="col-12"></BannerTop>
        </div>
        <div class="container">
            <div class="row mt-5">
                <div class="col-12">
                    <div>
                        <!--                        <div class="card-header">-->
                        <!--                            <h3>{{steps[step].title}}</h3>-->
                        <!--                        </div>-->
                        <div class="card-body">
                            <h4 class="text-center">{{ steps[step].subtitle }}</h4>

                            <Connect @next="completeStep" v-if="step === 0"></Connect>
                            <Platform @next="completeStep" @platform-selected="setPlatform" v-if="step === 1"></Platform>
                            <Lookup @next="completeStep" @nft-select="setNFT" v-if="step === 2"></Lookup>
                            <ItemType @next="completeStep" @type-select="setItemType" v-if="step === 3"></ItemType>
                            <Preview
                                :details="form.details"
                                :selected_asset="selected_asset"
                                :item_type="form.item_type"
                                :sub_type="form.interaction_type"
                                @next="completeStep"
                                @object-adjust="setObjectDetail"
                                v-if="step === 4"
                            ></Preview>
                            <Details :total_blocks="totalBlocks" :selected_asset="selected_asset" :form="form" @next="completeStep" v-if="step === 5"></Details>
                            <Payment @next="completeStep" v-if="step === 6"></Payment>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col text-center">
                    <button class="btn btn-lg" v-if="step > 0" v-on:click="previousStep">Back</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator'

@Component({
    name: 'NFTImport',
    components: {
        Lookup: () => import('../components/nft_import/Lookup.vue'),
        Details: () => import('../components/nft_import/Details.vue'),
        ItemType: () => import('../components/nft_import/ItemType.vue'),
        Preview: () => import('../components/nft_import/Preview.vue'),
        Payment: () => import('../components/nft_import/Payment.vue'),
        Connect: () => import('../components/nft_import/Connect.vue'),
        Platform: () => import('../components/nft_import/Platform.vue'),
    },
})
export default class NFTImport extends Vue {
    selected_asset = {}

    step = 0
    steps = [
        {
            icon: 'mail',
            name: 'first',
            title: 'Connect Wallet',
            subtitle: 'Connect NFT Wallet',
            component: Connect,
            completed: false,
        },
        {
            icon: 'mail',
            name: 'first',
            title: 'Platform',
            subtitle: 'NFT Platform',
            component: Platform,
            completed: false,
        },
        {
            icon: 'mail',
            name: 'first',
            title: 'NFT Lookup',
            subtitle: 'NFT Import Search',
            component: Lookup,
            completed: false,
        },
        {
            icon: 'report_problem',
            name: 'second',
            title: 'Import Type',
            subtitle: 'Select an item type',
            component: ItemType,
            completed: false,
        },
        {
            icon: 'report_problem',
            name: 'fourth',
            title: 'Preview',
            subtitle: 'Preview & adjust import settings',
            component: Preview,
            completed: false,
        },
        {
            icon: 'report_problem',
            name: 'third',
            title: 'Review',
            subtitle: 'Review your import settings',
            component: Details,
            completed: false,
        },
        {
            icon: 'report_problem',
            name: 'fifth',
            title: 'Payment',
            subtitle: 'Import payment fee',
            component: Payment,
            completed: false,
        },
    ]
    form = {
        //Lookup
        contract_addr: '',
        token_addr: '',
        owner_addr: '',
        sprite_uri: '',
        nft_platform: 0,

        //Step 2
        name: '',
        description: '',

        //Step 3
        item_type: 0,
        interaction_type: 0,

        //Step 4
        details: {
            isometric_shader: false,
            position_x: 0,
            position_y: 0,
            scale_x: 1,
            scale_y: 1,
            tile_x: 1,
            tile_y: 1,
            tile_z: 1,
        },
    }

    totalBlocks() {
        return parseInt(this.form.details.tile_x) * parseInt(this.form.details.tile_y) * parseInt(this.form.details.tile_z) //Original blocks
    }
    // Executed when @completed-step event is triggered
    completeStep() {
        console.log('Step completed', this.step)
        this.steps[this.step].completed = true
        this.nextStep()
    }

    // Executed when @active-step event is triggered
    isStepActive(payload) {
        this.steps.forEach(step => {
            if (step.name === payload.name) {
                if (step.completed === true) {
                    step.completed = false
                }
            }
        })
    }

    nextStep() {
        this.step++
    }

    previousStep() {
        if (this.step <= 0) this.step = 0
        else this.step--
    }
    setPlatform(platform) {
        this.form.nft_platform = platform
    }

    setNFT(nft) {
        //TODO
        console.log('nft-selected', nft)
        this.selected_asset = nft
        this.form.contract_addr = nft
    }

    setItemType(obj) {
        console.log('type-selected', obj)
        this.form.item_type = obj.item_type
        this.form.interaction_type = obj.interaction_type
    }

    setObjectDetail(obj) {
        this.form.details = obj
    }

    onFinish() {}
}
</script>

<style scoped>
.card {
    background: transparent;
    color: white;
    border: 1px solid #20126f;
}

.card-body::before {
    content: unset;
}

.card-body,
h4,
h5 {
    color: white;
}
</style>
