<template>
  <div>
    <!--    <MobileNavbar />-->
    <div id="desktop-navbar">
      <div class="app-left">
        <router-link to="/shop">
          <img :src="metaplexLogo" />
        </router-link>
        &nbsp;&nbsp;&nbsp;
        <MetaplexMenu />
      </div>

      <div
        class="box d-inline-flex justify-content-center align-items-center"
        style="margin-right: 4rem"
      >
        <div class="push wallet-wrapper">
          <a-popover v-model="visible" trigger="click" overlayClassName="pop-profile">
            <div slot="title" style="text-align: center; margin: 4px 0px">
              <b-tooltip
                ref="tooltip"
                disabled
                target="copy-address-container"
                placement="top"
                triggers="focus"
              >
                Address copied
              </b-tooltip>
              <div
                id="copy-address-container"
                class="d-inline-flex justify-content-center align-items-center fw-bold"
                style="cursor: pointer"
                @click="copyAddress"
              >
                <a-icon type="copy" class="mx-2" />{{ shortenWalletAddr(10) }}
              </div>
            </div>

            <div slot="content">
              <div class="d-flex align-items-center">
                <img
                  style="
                    margin: 7px 10px;
                    height: 25px;
                    width: 25px;
                    background-color: rgb(187, 187, 187);
                    border-radius: 50%;
                    display: inline-block;
                  "
                  src="https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/So11111111111111111111111111111111111111112/logo.png"
                />
                <div class="text-black fw-bold h5 m-0 opacity-75">
                  {{ getSolBalance }} SOL
                </div>
                <p class="text-black m-0 mx-3 opacity-50 fw-bold">{{ getUsdBalance }}</p>
              </div>

              <div class="profile-action">
                <span class="cursor-pointer fw-bold text-black opacity-75 h6">
                  Add Funds
                </span>
              </div>

              <div class="profile-action">
                <div>
                  <span
                    class="cursor-pointer fw-bold text-black opacity-75 h6"
                    @click="gotoPath('/shop/artworks/create')"
                  >
                    Create
                  </span>
                </div>

                <div>
                  <span
                    class="cursor-pointer fw-bold text-black opacity-75 h6"
                    @click="gotoPath('/shop/artworks/sell')"
                  >
                    Sell
                  </span>
                </div>
              </div>
            </div>
            <button class="profile-btn" :class="[{ active: visible }]">
              {{ shortenWalletAddr(4) }}
            </button>
          </a-popover>
        </div>
        <div style="margin-left: 20px">
          <a-icon type="bell" style="font-size: large" />
        </div>
        <div style="margin-left: 20px">
          <a-icon type="setting" style="font-size: large" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Vue, Component } from 'vue-property-decorator';
import { mapActions, mapGetters, mapState } from 'vuex';
import { shortenAddress } from '@/views/shop/utils';
import { formatNumber, formatUSD } from '@/views/shop/utils';

@Component({
  name: 'AppBar',
  components: {
    MetaplexMenu: () => import('./MetaplexMenu'),
  },
  computed: {
    ...mapState('walletModule', ['wallet_addr', 'wallet_balance']),
  },
})
export default class AppBar extends Vue {
  visible = false;
  formatNumber;

  get metaplexLogo() {
    return require('@/assets/pages/market-place/metaplex-logo.svg');
  }

  shortenWalletAddr(number = 4) {
    return shortenAddress(this.wallet_addr, number);
  }

  get getSolBalance() {
    return formatNumber.format(this.wallet_balance.sol);
  }

  get getUsdBalance() {
    return formatUSD.format(this.wallet_balance.usd);
  }

  gotoPath(path) {
    this.$router.push(path);
    this.hide();
  }

  hide() {
    this.visible = false;
  }

  copyAddress() {
    navigator.clipboard.writeText(this.wallet_addr);
    this.$refs.tooltip.$emit('open');
    setTimeout(() => {
      this.$refs.tooltip.$emit('close');
    }, 1000);
  }
}
</script>

<style scoped lang="scss">
.profile-action {
  display: flex;
  flex-wrap: wrap;
  row-gap: 10px;
  column-gap: 2em;
  margin-top: 1rem;
}

.profile-action > * {
  flex: 1;
}

.box {
  display: flex;
}
.push {
  margin-left: auto;
}

.app-left {
  align-items: center;
  display: flex;
  flex-direction: row;
  margin-left: 15px;
}

.push {
  margin-left: auto;
}

.App-Bar {
  padding: 0px;
  justify-content: space-between !important;
}

.App-Bar button.ant-btn:not(.connector) {
  height: 32px;
  background: none;
}

.title {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 26px;
  display: flex;
  align-items: center;
  text-align: center;

  color: #ffffff;
  padding: 10px 14px 10px 6px;

  margin-bottom: 0px;
}

.divider {
  border-left: 1px solid #282828;
  width: 2px;
  height: 40px;
  margin-right: 14px;
}

.wallet-wrapper {
  padding-right: 0.7rem;
}

.app-btn {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  border-width: 0px;
}

.app-bar-inner {
  display: flex;
  min-width: 50%;
  justify-content: center;
  position: relative;
  height: auto;
}

.app-bar-item {
  cursor: pointer;
  padding: 0 30px;
  color: #547595; /* @tungsten-60 */
  font-size: 18px;
}

.app-bar-item a {
  color: inherit;
}

.app-bar-item a.active {
  color: white;
}

.profile-btn {
  font-size: 1.2rem;
  font-weight: 400;
  border: 3px solid transparent;
  padding: 5px 10px;
  margin-left: 10px;
  background-color: transparent;
  border-radius: 24px;
}

.profile-btn:hover,
.profile-btn.active {
  color: #e6ceff;
  border-color: #dfc2ff54;
}
</style>
