<template>
    <div class="index p-5">
        <div class="container-fluid mt-3">
            <div class="row mb-3">
                <div class="col-4">
                    <button class="btn btn-primary btn-sm" v-on:click="toCenter">Center</button>
                </div>

                <div class="col-4"><h2 class="text-center mb-0 section-common-title section-common-title-center wow fadeInDown">Land Map</h2></div>

                <div class="col-4 text-right">
                    <button class="btn btn-primary btn-sm" v-on:click="toggleBasket">
                        Basket <small v-show="Object.keys(this.shopping.basket).length > 0">({{ Object.keys(this.shopping.basket).length }})</small>
                    </button>
                </div>
            </div>

            <div class="row">
                <div class="col-12 text-center">
                    <div id="canvas-container" class="land-map" @wheel="handleWheel">
                        <canvas v-on:click="mapClick" id="canvas" :width="this.mapSize * this.tileSize * 2" :height="this.mapSize * this.tileSize * 2"></canvas>
                        <Tooltip
                            @add-basket="onAddBasket"
                            :tile="tooltip.tile"
                            :show="tooltip.show"
                            :chunk="tooltip.chunk"
                            :position="tooltip.position"
                            :selected="selectedTiles"
                        ></Tooltip>
                    </div>
                </div>
            </div>

            <Landnav @hide="shopping.basket_open = false" :shopping="shopping" :show="shopping.basket_open"></Landnav>
        </div>
    </div>
</template>

<script>
import { gsap } from 'gsap'
import Draggable from 'gsap/Draggable'
import { Component, Vue } from 'vue-property-decorator'

@Component({
    components: {
        Landnav: () => import('./Landnav'),
        Tooltip: () => import('./../../components/land/map/Tooltip'),
    },
})
export default class LandIndex2 extends Vue {
    mapSize = 100
    tileSize = 15
    tiles = {}
    shopping = { basket_open: false, basket: {} }
    map = []
    selectedTiles = []
    ctx = null
    tooltip = {
        show: false,
        chunk: [0, 0],
        position: {
            x: 0,
            y: 0,
        },
        tile: {
            guild: { name: '' },
        },
    }

    mounted() {
        gsap.registerPlugin(Draggable)
        Draggable.create('#canvas', {
            bounds: { minX: -(this.mapSize * this.tileSize * 2), minY: -(this.mapSize * this.tileSize * 2), maxX: 0, maxY: 0 },
            cursor: 'pointer',
            onDragStart: () => {
                this.tooltip.show = false
            },
        })

        //Add Babilu Central
        const centralSize = 10
        for (let x = -centralSize; x < centralSize; x++)
            for (let y = -centralSize; y < centralSize; y++)
                this.tiles[`${x}|${y}`] = {
                    id: '0a68d52c-5aec-4466-83d2-b031e1da49a9',
                    name: 'AlphaBatem Central',
                    owner: 'AlphaBatem',
                    guild: { name: 'AlphaBatem' },
                }

        var c = document.getElementById('canvas')

        this.ctx = c.getContext('2d')
        this.ctx.fillStyle = 'blue'
        this.ctx.strokeStyle = 'black'
        //
        console.log('Map Size: ', this.mapSize)
        for (let x = 0; x <= this.mapSize * 2; x++) {
            this.map[x] = []
            for (let y = 0; y <= this.mapSize * 2; y++) this.map[x][y] = `${x - this.mapSize}${y - this.mapSize}`
        }

        console.log('DM: ', this.map.length)
        this.drawMap()
        this.toCenter()
    }

    toCenter() {
        const c = document.getElementById('canvas-container')
        console.log('C', c.clientWidth, c.clientHeight, 1 - 15 / this.tileSize)

        gsap.to('#canvas', 1, {
            x: -((this.mapSize * this.tileSize) / 2),
            y: -((this.mapSize * this.tileSize) / 2) + -(c.clientHeight / 2 + c.clientHeight * (1 - 15 / this.tileSize)),
        })
    }

    mapClick(e) {
        console.log('Click', e)

        var rect = e.target.getBoundingClientRect()
        var x = e.clientX - rect.left //x position within the element.
        var y = e.clientY - rect.top //y position within the element.

        let tx = Math.floor(x / this.tileSize)
        let ty = Math.floor(y / this.tileSize)

        // console.log("Left? : " + x + " ; Top? : " + y + ".");
        console.log(`Tile: ${tx},${ty}`)

        if (e.shiftKey) {
            if (this.selectedTiles.length === 1) {
                const ft = this.selectedTiles[0]
                if (tx > ft[0]) tx += 1
                if (ty > ft[1]) ty += 1

                this.selectedTiles.push([tx, ty])
                this.shiftClick(ft, [tx, ty])
                this.showTooltipAt(e.clientX + 20, e.clientY)
                return
            }
        }

        this.showTooltipAt(e.clientX + 20, e.clientY)
        this.tooltip.chunk = this.toChunk(x / this.tileSize, y / this.tileSize)

        console.log('Clicked', `${this.tooltip.chunk[0]}|${this.tooltip.chunk[1]}`)
        this.tooltip.tile = this.tiles[`${this.tooltip.chunk[0]}|${this.tooltip.chunk[1]}`]

        this.clearSelectedTiles()
        this.selectedTiles = [[tx, ty]] //Reset selected tiles as not shift clicking

        this.drawSelect(tx * this.tileSize, ty * this.tileSize, 1)
        this.ctx.stroke()
    }

    shiftClick(e1, e2) {
        console.log('Shift click')
        this.tooltip.show = false

        //Clear the double draw
        this.ctx.fillStyle = this.color(e1[0], e1[1])
        this.ctx.clearRect(e1[0] * this.tileSize, e1[1] * this.tileSize, this.tileSize, this.tileSize)
        this.draw(e1[0] * this.tileSize, e1[1] * this.tileSize, 1)

        for (let x = Math.min(e1[0], e2[0]); x < Math.max(e1[0], e2[0]); x++) {
            for (let y = Math.min(e1[1], e2[1]); y < Math.max(e1[1], e2[1]); y++) {
                this.selectedTiles.push([x, y])
                this.drawSelect(x * this.tileSize, y * this.tileSize, 1)
            }
        }
        this.ctx.stroke()
    }

    showTooltipAt(x, y) {
        this.tooltip.position = { x: x, y: y }
        this.tooltip.tile = this.tileDetail(this.tooltip.chunk[0], this.tooltip.chunk[1])
        this.tooltip.show = true
    }

    clearSelectedTiles() {
        for (let i = 0; i < this.selectedTiles.length; i++) {
            const t = this.selectedTiles[i]
            this.ctx.clearRect(t[0] * this.tileSize, t[1] * this.tileSize, this.tileSize, this.tileSize)
            console.log('Clearing tile: ', t)
            this.ctx.fillStyle = this.color(t[0], t[1])
            this.draw(t[0] * this.tileSize, t[1] * this.tileSize, 1)
        }
    }

    color(x, y) {
        //0|0

        const tx = x - this.mapSize
        const ty = y - this.mapSize

        if (this.shopping.basket[`${Math.floor(tx)}|${Math.floor(ty)}`]) {
            console.log('Basket override')
            return 'rgb(0,255,0)'
        }

        if (!this.tiles[`${Math.floor(tx)}|${Math.floor(ty)}`]) {
            return 'rgb(0,0,0)'
        }

        if (Math.abs(x) === this.mapSize && Math.abs(y) === this.mapSize) {
            return 'rgb(255,255,255)'
        }

        if (x < 0 || y < 0) return `rgb(${Math.abs(x)},${Math.abs(y)},${Math.abs(x) - Math.abs(y)})`

        return `rgb(${x},${y},${x + y})`
    }

    drawMap() {
        for (let x = 0; x < this.map.length; x++) {
            for (let y = 0; y < this.map[x].length; y++) {
                this.ctx.fillStyle = this.color(x, y)
                this.draw(x * this.tileSize, y * this.tileSize, 1)
            }
        }
    }

    onAddBasket(tilePosition) {
        const key = `${tilePosition[0]}|${tilePosition[1]}`

        console.log('adding tile to basket:', tilePosition, key)

        this.shopping.basket[key] = tilePosition
        if (!this.shopping.basket_open) this.toggleBasket()
    }

    toggleBasket() {
        if (!this.shopping.basket_open) this.tooltip.show = false

        this.shopping.basket_open = !this.shopping.basket_open
    }

    /**
     * TODO Return actual tile details
     * @param tx
     * @param ty
     * @returns {{owner: string, image: string, guild: {name: string}, name: string, points: number}}
     */
    tileDetail(tx, ty) {
        return {
            image: '/lounge/virtual_room_logo.png',
            name: 'AlphaBatem Central',
            owner: '0x0000000000000000000000000000000000000000',
            guild: { name: 'AlphaBatem' },
            position: { x: tx, y: ty },
            points: 1698,
        }
    }

    toChunk(x, y) {
        console.log(`ToChunk: ${Math.floor(x) / this.tileSize},${Math.floor(y) / this.tileSize} - ${this.mapSize}`)
        const b = this.mapSize //Middle out
        return [Math.floor(x - b), Math.floor(y - b)]
    }

    draw(x, y, size) {
        const border = (this.tileSize / 100) * 3
        this.ctx.fillRect(x + border, y + border, this.tileSize * size - border * 2, this.tileSize * size - border * 2)
    }

    drawSelect(x, y, size) {
        this.ctx.fillStyle = 'RGBA(55,60,164,0.81)'
        this.ctx.fillRect(x, y, this.tileSize * size, this.tileSize * size)
    }

    handleWheel(e) {
        e.preventDefault()
        // return

        const currentTileSize = this.tileSize

        //TODO this is laggy but works
        if (e.deltaY < 0) {
            this.tileSize += Math.abs(e.deltaY) / 100
        } else {
            this.tileSize -= Math.abs(e.deltaY) / 100
        }

        if (this.tileSize < 10) this.tileSize = 10

        if (this.tileSize > 55) this.tileSize = 55

        if (currentTileSize === this.tileSize) {
            return
        }

        requestAnimationFrame(() => {
            var c = document.getElementById('canvas')
            this.ctx.clearRect(0, 0, c.width, c.height)
            this.drawMap()
        })
    }
}
</script>

<style scoped>
.land-map {
    overflow: hidden;
    max-height: 900px;
}

#canvas {
    margin-top: 5%;
    background: #080b1f;
}
</style>
