<template>
    <div class="container-fluid mt-5">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <PlayerCard :profile="profile"></PlayerCard>
                </div>
            </div>
            <h1 class="mb-3">Update Profile</h1>
            <div class="row my-5">
                <div class="col-lg-8 offset-lg-2 col-md-12 col-sm-12">
                    <form class="form">
                        <div class="form-group">
                            <label>Username</label>
                            <input type="text" class="form-control" v-model="form.name" />
                        </div>

                        <div class="form-group">
                            <label>Profile Image</label>
                            <!--							<input type="file" class="form-control-file" v-model="form.avatar_url">-->
                            <input type="text" class="form-control-file" v-model="form.avatar_url" />
                        </div>

                        <button class="btn btn-block btn-primary" v-on:click="updateProfile">Update</button>
                    </form>
                </div>
            </div>
            <hr />
            <h1 class="mb-3">Guild</h1>
            <div class="row my-5">
                <div class="col-lg-8 offset-lg-2 col-md-12 col-sm-12 text-center">
                    <h2 class="my-5">No Current Guild</h2>
                    <button class="btn btn-primary" v-on:click="leaveGuild" disabled>Leave Guild</button>
                </div>
            </div>
            <hr />
        </div>
    </div>
</template>

<script>
import Auth from '@/api/auth'
import User from '@/api/user'
import { Component, Vue } from 'vue-property-decorator'

import PlayerCard from '../../components/profile/PlayerCard'

@Component({
    components: {
        PlayerCard: () => import('../../components/profile/PlayerCard.vue'),
    },
})
export default class ProfileUpdate extends Vue {
    user = ''
    profile = {}
    form = {
        name: '',
        avatar_url: '',
    }
    getProfile() {
        User.show(this.user).then(resp => {
            this.profile = resp.data
        })
    }

    updateProfile(e) {
        e.preventDefault()

        //Web3ProfileUpdate
        const payload = {
            public_address: this.$store.state.wallet_addr,
            signature: '', //TODO: Sign through phantom
            name: this.form.name,
            avatar_url: this.form.avatar_url,
            // sprite_url: "",
        }

        Auth.update(this.$store.state.wallet_addr, payload).then(resp => {
            console.log('Profile updated', resp.data)
            this.$router.push('/profile')
        })
    }

    leaveGuild(e) {
        e.preventDefault()
        Auth.leaveGuild(this.$store.state.wallet_addr).then(resp => {
            console.log('Profile updated', resp.data)
            this.$router.push('/profile')
        })
    }
	
    beforeMount() {
        this.user = this.$store.state.wallet_addr
    }

    mounted() {
        this.getProfile()
    }
}
</script>

<style scoped>
form {
    color: white;
}

.btn,
h2 {
    color: white;
}
</style>
