








































import { Component, Prop, Vue } from 'vue-property-decorator';
import { getPhantomWallet } from '@solana/wallet-adapter-wallets';
import { NFTGet } from '@/common/NFTget';
import { INFTParams } from '@/common/helpers/types';

@Component({
  name: 'ShowShopProfileItem',
  components: {
    SearchBar: () => import('../../components/marketplace/SearchBar.vue'),
    FilterSelect: () => import('./components/FilterSelect.vue'),
    NftItem: () => import('./components/NftItem.vue'),
  },
})
export default class ShowShopProfileItem extends Vue {
  @Prop({
    type: Array,
    default: () => {
      let itemDataDefault = [];
      for (let i = 0; i < 18; i++) {
        const item = {
          id: i,
          name: 'Item ' + i,
          description: 'This is a description for item ' + i,
          price: (i * 10 + 1) * Math.round(Math.random() * 100),

          creator: {
            id: i,
            name: 'Creator ' + i,
            wallet_addr: '0x' + i.toString(16),
            avatar:
              'https://lh3.googleusercontent.com/wl09kAQ8Zr_9rMmJM6hquCMWctYAgiQIOCnCvuHkabkaUKoC2hHq_qR2_5Re_Kq9j5ayjkqUnI19sHjKwvD8j8lyfZcUP_Meq8Q9=w600',
          },
          owner: {
            id: i,
            name: 'Owner ' + i,
            wallet_addr: '0x' + i.toString(16),
            avatar:
              'https://lh3.googleusercontent.com/wl09kAQ8Zr_9rMmJM6hquCMWctYAgiQIOCnCvuHkabkaUKoC2hHq_qR2_5Re_Kq9j5ayjkqUnI19sHjKwvD8j8lyfZcUP_Meq8Q9=w600',
          },
          media: {
            thumbnail_src:
              'https://lh3.googleusercontent.com/wl09kAQ8Zr_9rMmJM6hquCMWctYAgiQIOCnCvuHkabkaUKoC2hHq_qR2_5Re_Kq9j5ayjkqUnI19sHjKwvD8j8lyfZcUP_Meq8Q9=w600',
            image_src:
              'https://lh3.googleusercontent.com/wl09kAQ8Zr_9rMmJM6hquCMWctYAgiQIOCnCvuHkabkaUKoC2hHq_qR2_5Re_Kq9j5ayjkqUnI19sHjKwvD8j8lyfZcUP_Meq8Q9=w600',
            video_src:
              'https://lh3.googleusercontent.com/wl09kAQ8Zr_9rMmJM6hquCMWctYAgiQIOCnCvuHkabkaUKoC2hHq_qR2_5Re_Kq9j5ayjkqUnI19sHjKwvD8j8lyfZcUP_Meq8Q9=w600',
            object_src:
              'https://lh3.googleusercontent.com/wl09kAQ8Zr_9rMmJM6hquCMWctYAgiQIOCnCvuHkabkaUKoC2hHq_qR2_5Re_Kq9j5ayjkqUnI19sHjKwvD8j8lyfZcUP_Meq8Q9=w600',
          },
          metadata: {},
          tags: [i, i + 1, i + 2],
          analytics: {
            views: i * 10,
          },
          history: [
            {
              id: i,
              name: 'Owner ' + i,
              avatar:
                'https://lh3.googleusercontent.com/wl09kAQ8Zr_9rMmJM6hquCMWctYAgiQIOCnCvuHkabkaUKoC2hHq_qR2_5Re_Kq9j5ayjkqUnI19sHjKwvD8j8lyfZcUP_Meq8Q9=w600',
              action: 'purchase',
              wallet_addr: '0x' + i.toString(16),
              timestamp: new Date().getTime(),
            },
          ],
        };

        if (i < 6) {
          itemDataDefault.push(item);
        }
      }

      return itemDataDefault;
    },
  })
  items: any;

  loading = false;
  listNFT = [] as any[];

  filterItem = [
    {
      label: 'All Items',
      value: '',
    },
    {
      label: 'Single Items',
      value: 'single',
    },
    {
      label: 'Bundles',
      value: 'bundle',
    },
  ];

  shortItem = [
    {
      label: 'Recently Received',
      value: 'recently received',
    },
    {
      label: 'Recently Created',
      value: 'recently created',
    },
    {
      label: 'Recently Sold',
      value: 'recently sold',
    },
    {
      label: 'Price: Low To High',
      value: 'low to high',
    },
    {
      label: 'Price: High To Low',
      value: 'high to low',
    },
  ];

  searchItem() {
    console.log('Search... Item');
  }

  async created() {
    this.loading = true;
    const gottenWallet = getPhantomWallet();
    const connectedAdapter = gottenWallet.adapter();

    connectedAdapter
      .connect()
      .then(() => {
        this.$store.commit(
          'walletModule/set_wallet_public_key',
          connectedAdapter.publicKey,
        );
      })
      .catch((err: any) => {
        console.log(err);
      });

    const params: INFTParams = {
      owner: connectedAdapter.publicKey,
      creator: undefined,
      mint: undefined,
      updateAuthority: undefined,
    } as INFTParams;

    NFTGet(params)
      .then(rs => {
        console.log('@@@ - NFTGet - rs', rs);

        this.listNFT = rs;
        console.log(this.listNFT);
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  beforeMount() {}

  mounted() {}
}
