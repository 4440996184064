<template>
    <div class="index"></div>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator'

@Component({ name: 'PlayerIndex' })
export default class PlayerIndex extends Vue {}

</script>

<style scoped></style>
