export const Progresses = {
  createExternalPriceAccount: {
    type: 'createExternalPriceAccount',
    desc: 'Create external price account',
  },
  createVault: {
    type: 'createVault',
    desc: 'Create Vault',
  },
  addTokensToVault: {
    type: 'addTokensToVault',
    desc: 'Add tokens to vault',
  },
  activeVault: {
    type: 'activeVault',
    desc: 'Active vault',
  },
  combineVault: {
    type: 'combineVault',
    desc: 'Combine vault',
  },
  initAuction: {
    type: 'initAuction',
    desc: 'Create Auction house',
  },
  initAuctionManager: {
    type: 'initAuctionManager',
    desc: 'Init auction manager',
  },
  setVaultAndAuctionAuthority: {
    type: 'setVaultAndAuctionAuthority',
    desc: 'Set vault and auction authority to AuctionManager',
  },
  validateSafetyDepositBox: {
    type: 'validateSafetyDepositBox',
    desc: 'Validate safety Deposit box',
  },
  startAuction: {
    type: 'startAuction',
    desc: 'Start auction',
  },
  updateStore: {
    type: 'updateStore',
    desc: 'Update store',
  },
};
