import { RouteConfig } from 'vue-router';

const routes: Array<RouteConfig> = [
  {
    path: '/shop',
    name: 'Shop',
    component: () =>
      import(/* webpackChunkName: "item_index" */ '@/views/shop/Metaplex.vue'),
    children: [
      {
        path: '',
        name: 'Shop',
        component: () =>
          import(
            /* webpackChunkName: "item_index" */ '@/views/shop/components/sale-list/Index.vue'
          ),
      },
      {
        path: 'profile',
        name: 'Shop Profile',
        component: () =>
          import(/* webpackChunkName: "item_index" */ '@/views/shop/Profile.vue'),
      },
      {
        path: 'creators',
        name: 'Creators',
        component: () =>
          import(/* webpackChunkName: "item_index" */ '@/views/shop/Profile.vue'),
      },
      {
        path: 'artworks',
        name: 'List artwork',
        component: () =>
          import(/* webpackChunkName: "item_index" */ '@/views/shop/ArtWork.vue'),
      },
      {
        path: 'artworks/create',
        name: 'Create NFT',
        component: () =>
          import(
            /* webpackChunkName: "item_index" */ '@/views/shop/components/create-nft/Index.vue'
          ),
      },
      {
        path: 'artworks/sell',
        name: 'Sell NFT',
        component: () =>
          import(
            /* webpackChunkName: "item_index" */ '@/views/shop/components/sell-nft/Index.vue'
          ),
      },
      {
        path: 'artworks/:id',
        name: 'Artwork detail',
        component: () =>
          import(
            /* webpackChunkName: "item_index" */ '@/views/shop/components/wallet-items/Detail.vue'
          ),
      },
    ],
  },
];

const addMeta = (route: RouteConfig) => {
  if (!route.meta) {
    route.meta = {};
  }
  route.meta.category = 'marketplace';
  if (route.children?.length) {
    route.children.forEach(child => {
      addMeta(child);
    });
  }
};

routes.forEach(route => {
  addMeta(route);
});

export default routes;
