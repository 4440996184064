<template>
  <div>
    <component
      :is="showComponent"
      :uri="uriState"
      :animationUrl="animationURLState"
      :files="filesState"
      :preview="preview"
      :artView="artView"
      :active="active"
    />
  </div>
</template>

<script>
import { Vue, Component, Prop } from 'vue-property-decorator';
import { pubkeyToString } from '@/views/shop/utils';
import { mapActions } from 'vuex';
import { PublicKey } from '@solana/web3.js';
import get from 'lodash/get';
import { getLast } from '@/common/arrays';
import { MeshArtParserFactory } from '@/views/shop/components/wallet-items/art-content/meshArtContentDefines';

const ArtDetailComponent = {
  mesh: 'MeshArtContent',
  html: 'HTMLContent',
  video: 'VideoContent',
  image: 'ImageContent',
  audio: 'AudioContent',
};

const ArtCategory = {
  image: 'image',
  video: 'video',
  mesh: 'vr',
  html: 'html',
  audio: 'audio',
};
@Component({
  name: 'ArtContent',
  components: {
    [ArtDetailComponent.image]: () => import('./ImageContent'),
    [ArtDetailComponent.video]: () => import('./VideoContent'),
    [ArtDetailComponent.audio]: () => import('./AudioContent'),
    [ArtDetailComponent.mesh]: () => import('./MeshArtContent'),
    [ArtDetailComponent.html]: () => import('./HTMLContent'),
  },
  methods: {
    ...mapActions('metaplexNFTModule', ['getExtendedArt']),
  },
})
export default class ArtContent extends Vue {
  @Prop(Boolean) active;
  @Prop(Boolean) artView;
  @Prop(Boolean) allowMeshRender; //@Description: Experienced on mobile may not good enough. iOs - Safari has canvas limit size 256MB - 384MB -> crash | fail to render
  @Prop(String) uri;
  @Prop(String) category;
  @Prop(String) animationURL;
  @Prop(String) preview;
  @Prop(Object) files;
  @Prop(PublicKey) pubkey;

  isLoading = true;
  uriState = '';
  animationURLState = '';
  filesState = '';
  categoryState = '';

  showComponent = ArtDetailComponent.image;

  created() {
    this.initData();
  }

  async initData() {
    // ----- set default by props
    this.uriState = this.uri;
    this.animationURLState = this.animationURL;
    this.filesState = this.files;
    this.categoryState = this.category;

    // ----- set value after fetch
    const id = pubkeyToString(this.pubkey);
    const data = await this.getExtendedArt(id);
    this.isLoading = false;

    if (this.pubkey && data) {
      this.uriState = data.image;
      this.animationURLState = data.animation_url;
    }

    if (this.pubkey && data?.properties) {
      this.filesState = data.properties.files;
      this.categoryState = data.properties.category;
    }

    if (this.animationURLState) {
      // const searchParams = new URL(this.animationURLState).searchParams;
      // console.log(searchParams);
    }

    // --- End of handle data, let switch case/if else to check which component would be shown
    const files = get(data, 'properties.files', []);
    if (files?.length) {
      this.uriState = data.image;
    }

    const animationUrlExt = new URLSearchParams(
      getLast((this.animationURLState || '').split('?')), // https/area.com//e9834t98jjgiuf?ext=mp4
    ).get('ext');
    if (
      this.allowMeshRender &&
      (this.categoryState === ArtCategory.mesh ||
        animationUrlExt === 'glb' ||
        animationUrlExt === 'gltf')
    ) {
      const meshArtParser = MeshArtParserFactory.build(files);
      this.animationURLState = await meshArtParser.parse();
      this.showComponent = ArtDetailComponent.mesh;
      return;
    }

    if (this.categoryState === ArtCategory.html) {
      this.animationURLState =
        files?.find(file => ['text/html', 'html'].includes(file.type))?.uri ||
        this.animationURLState;
      this.showComponent = ArtDetailComponent.html;
      return;
    }

    if (files.length && this.categoryState === ArtCategory.video) {
      this.animationURLState = getLast(files).uri;
      this.showComponent = ArtDetailComponent.video;
      return;
    }

    if (files.length && this.categoryState === ArtCategory.audio) {
      this.animationURLState = getLast(files).uri;
      this.showComponent = ArtDetailComponent.audio;
      return;
    }

    this.showComponent = ArtDetailComponent.image;
  }
}
</script>

<style scoped></style>
