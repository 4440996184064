<template>
  <div>
    <div
      class="col-md-4 col-xs-12 card-add-nft__container p-0 mt-2"
      @click="showSelectNFT = true"
    >
      <div
        class="nft-preview-img__container w-100 h-100 d-inline-flex align-items-center justify-content-center"
      >
        <template v-if="!!nft">
          <div
            @click.stop="deleteSelected"
            class="delete__icon position-absolute d-flex justify-content-center align-items-center"
          >
            <b-icon-trash />
          </div>
          <NFTItem class="nft-preview-img" :nft="nft" :is-quick-view="true" />
        </template>
        <template v-else> Add an NFT </template>
      </div>
    </div>

    <a-modal
      v-model="showSelectNFT"
      centered
      title="Select NFT"
      @close="showSelectNFT = false"
      wrapClassName="modal-add-nft"
    >
      <p class="mx-3" style="color: #898989">
        Select the NFT that you want to sell copy/copies of.
      </p>
      <Artwork :on-popup="true" @select="selectNFT" />
      <template slot="footer">
        <b-button variant="default" class="w-100" @click="showSelectNFT = false"
          ><h6 class="text-white fw-bold mb-0" style="opacity: 1">CONFIRM</h6></b-button
        >
      </template>
    </a-modal>
  </div>
</template>

<script>
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({
  name: 'AddNFT',
  components: {
    Artwork: () => import('@/views/shop/ArtWork.vue'),
    NFTItem: () => import('@/views/shop/components/NftItem.vue'),
  },
})
export default class AddNFT extends Vue {
  @Prop(Object) value;

  get nft() {
    return this.value;
  }

  set nft(value) {
    this.$emit('input', value);
  }

  showSelectNFT = false;

  selectNFT(item) {
    this.nft = item;
    this.showSelectNFT = false;
  }

  deleteSelected(evt) {
    this.nft = null;
    evt.preventDefault();
  }
}
</script>

<style scoped lang="scss">
@import './src/assets/css/components/metaplex-modal.scss';
@import './src/assets/css/pages/sell-nft-components-add-nft.scss';
</style>
