import { actions, Wallet } from '@metaplex/js';
import { Commitment, Connection } from '@solana/web3.js';
import { stringifyPubkeysAndBNsInObject } from './helpers/util';

const getConnection = (committment?: Commitment): Connection =>
  new Connection('https://api.devnet.solana.com', committment ?? 'processed');

export async function NFTMintMaster(wallet: Wallet, uri: string, maxSupply?: number) {
  const connection = getConnection();
  const result = await actions.mintNFT({
    connection,
    wallet,
    uri,
    maxSupply,
  });
  return stringifyPubkeysAndBNsInObject(result);
}
