<template>
    <div class="index">
        <BannerTop title_blue="Item" title_white="Explorer" heading="Explore all the Item's within the AlphaBatem Universe"></BannerTop>

        <div class="container mt-3">
            <div class="row text-center mb-3">
                <div class="col">
                    <input placeholder="Search" class="form-control mt-2" />
                </div>

                <div class="col">
                    <select class="form-control mt-2">
                        <option value="">All</option>
                        <option v-for="(coll, val) in collections" :key="val" :value="val">{{ coll }}</option>
                    </select>
                </div>

                <div class="col">
                    <select class="form-control mt-2">
                        <option value="">All</option>
                        <option v-for="(cat, val) in categories" :key="val" :value="val">{{ cat }}</option>
                    </select>
                </div>

                <div class="col">
                    <button class="btn btn-primary btn-sm">Search</button>
                </div>
            </div>

            <div class="row">
                <div v-show="items.length === 0" class="text-center my-5 col-12">
                    <i>No items found</i>
                </div>

                <ItemCard class="col-12 my-2" v-for="item in items" :item="item" :key="item.id"></ItemCard>
            </div>
        </div>
    </div>
</template>

<script>
import Item from '@/api/item'
import { Component, Vue } from 'vue-property-decorator'

@Component({
    components: {
        BannerTop: () => import('../../components/BannerTop.vue'),
        ItemCard: () => import('../../components/explorer/ItemCard.vue'),
    },
})
export default class ItemIndex extends Vue {
    items = []
    collections = ['Founders', 'Spinners', 'Royal']
    categories = {
        0: 'Babilu',
        1: 'Community',
        2: 'Sponsored',
    }

    getIndex() {
        Item.index().then(r => {
            console.log(r)
            this.items = r.data
        })
    }

    mounted() {
        this.getIndex()
    }
}
</script>

<style scoped></style>
