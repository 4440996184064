import Vue from 'vue';
import Vuex, { ActionTree, GetterTree, MutationTree } from 'vuex';
import WalletModule from './wallet';
import MetaplexNFTModule from '@/views/shop/store/nftStore';
import ConnectionModule from '@/store/connection';
import MarketplaceAuctionModule from '@/views/shop/store/auctionStore';
import MarketplaceAccountModule from '@/views/shop/store/accountStore';

Vue.use(Vuex);

class State {}

const getters: GetterTree<State, any> = {};
const mutations = <MutationTree<State>>{};
// const mutations = <MutationTree<State>>{};

const actions = <ActionTree<State, any>>{};

export default new Vuex.Store({
  state: new State(),
  getters: getters,
  mutations: mutations,
  actions: actions,
  modules: {
    walletModule: WalletModule,
    metaplexNFTModule: MetaplexNFTModule,
    marketplaceAccountModule: MarketplaceAccountModule,
    marketplaceAuctionModule: MarketplaceAuctionModule,
    connectionModule: ConnectionModule,
  },
});
