import { Vue, Component } from 'vue-property-decorator';

@Component({
  name: 'ProgressLoadingMixins',
})
export default class ProgressLoadingMixins extends Vue {
  processes: { [key: string]: any } = {};
  loadingProgresses: any[] = []; // call setupProgresses at created or mounted to init
  currentProgress = 1;

  get typeToIndexStages() {
    const typeToIndex = new Map();
    const keys = Object.keys(this.processes);
    keys.forEach((key, idx) => {
      typeToIndex.set(this.processes[key].type, idx + 1);
    });

    return typeToIndex;
  }

  setupProgresses(obj: { [key: string]: any }) {
    this.processes = obj;
    this.loadingProgresses = Object.keys(obj).map((key: string) => obj[key].desc);
  }

  callbackProgress(type: string) {
    this.currentProgress = this.typeToIndexStages.get(type);
  }
}
