import {GetterTree, MutationTree, ActionTree} from "vuex";
import {clusterApiUrl, Connection} from "@solana/web3.js";
import {ENV as ChainId} from "@solana/spl-token-registry/dist/main/lib/tokenlist";

export type ENDPOINT_NAME =
    | 'mainnet-beta'
    | 'testnet'
    | 'devnet'
    | 'localnet'
    | 'lending';

type Endpoint = {
    name: ENDPOINT_NAME;
    label: string;
    url: string;
    chainId: ChainId;
};

export const ENDPOINTS: Array<Endpoint> = [
    {
        name: 'mainnet-beta',
        label: 'mainnet-beta',
        url: 'https://api.metaplex.solana.com/',
        chainId: ChainId.MainnetBeta,
    },
    {
        name: 'testnet',
        label: 'testnet',
        url: clusterApiUrl('testnet'),
        chainId: ChainId.Testnet,
    },
    {
        name: 'devnet',
        label: 'devnet',
        url: clusterApiUrl('devnet'),
        chainId: ChainId.Devnet,
    },
];

const DEFAULT_ENDPOINT = ENDPOINTS[2];

class State {
    connection = new Connection(DEFAULT_ENDPOINT.url, 'recent')
    endpoint = DEFAULT_ENDPOINT
    token = new Map()
}

const getters: GetterTree<State, any> = {
    useConnection(State) {
        return State
    }
};
const mutations = <MutationTree<State>>{

};
const actions = <ActionTree<State, any>>{

};

const ConnectionModule = {
    namespaced: true,
    getters,
    state: new State(),
    mutations,
    actions,
};

export default ConnectionModule;
