<template>
    <div class="inventory-item text-center p-2 py-3">
        <span class="rarity" :style="{ backgroundColor: rarityColour }">{{ rarity }}</span>
        <h3 class="qty">x{{ item.quantity_owned }}</h3>
        <img v-if="!is3DModel" class="img-fluid img-sm" :src="item.item.sprite_uri" alt="Item image" />
        <model-viewer
            class="model-view"
            alt="Item image"
            ar
            ar-modes="webxr scene-viewer quick-look"
            v-if="is3DModel"
            :src="item.item.sprite_uri"
        ></model-viewer>
        <h3 class="name">{{ item.item.name }}</h3>
    </div>
</template>

<script>
import '@google/model-viewer'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({ name: 'InventoryItem' })
export default class InventoryItem extends Vue {
    @Prop({ type: Object, required: true }) item
	
    get is3DModel() {
        return this.item.item.sprite_uri.indexOf('.glb') !== -1
    }

    get rarity() {
        switch (this.item.item.rarity) {
            case 'common':
                return 'Common'
            case 'uncommon':
                return 'Uncommon'
            case 'rare':
                return 'Rare'
            case 'epic':
                return 'Epic'
            case 'legendary':
                return 'Legendary'
            default:
                return '???'
        }
    }

    get rarityColour() {
        switch (this.item.item.rarity) {
            case 'common':
                return 'green'
            case 'uncommon':
                return 'blue'
            case 'rare':
                return 'yellow'
            case 'epic':
                return 'purple'
            case 'legendary':
                return 'orange'
            default:
                return 'grey'
        }
    }
}
</script>

<style scoped>
.inventory-item {
    position: relative;
    border: 1px solid #0a9fb9;
    background: #1e1e1f;
    border-radius: 8px;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
    min-height: 100%;
}

.name,
.qty {
    color: white;
    position: absolute;
    bottom: 0;
    text-align: left;
    width: 100%;
}

.qty {
    text-align: right;
    top: 0;
    right: 15px;
}

.inventory-item:hover {
    background: rgba(22, 99, 117, 1);
    transform: scale(1.1);
}

.img-sm {
    max-height: 100px;
}

span.rarity {
    border-radius: 15px;
    position: absolute;
    top: 5px;
    left: 5px;
    color: white;
    font-size: 0.8rem;
    padding: 0 5px 0 5px;
}

model-viewer {
    --poster-color: transparent;
    height: 100px;
    width: 100%;
}
</style>
