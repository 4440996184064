import {api} from "./api";

export class Auth {
	prefix() {
		return "auth"
	}

	//TODD: add auth

	leaveGuild() {
		return api.put(`${this.prefix()}/users/profile/guild/leave`)
	}

	//Accepts Web3ProfileUpdate
	update(uid, obj) {
		///auth/users/profile
		return api.put(`${this.prefix()}/users/profile`, obj)
	}
}

export default new Auth()