<template>
    <div class="list-filter-item">
        <div class="select-item mr-5 ml-2">
            <select :value="selected" @input="$emit('input', $event.target.value)" class="form-select form-select-lg mb-3" aria-label=".form-select-lg">
                <option v-for="item in filterData" :key="item.value" :value="item.value" :label="item.label"></option>
            </select>
        </div>
    </div>
</template>

<script>
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({ name: 'FilterSelect' })
export default class FilterSelect extends Vue {
    @Prop({
        type: Array,
        default: () => {
            return [
                {
                    label: 'AlphaBatem',
                    value: 'AlphaBatem',
                },
                {
                    label: 'Extenal',
                    value: 'Extenal',
                },
                {
                    label: 'Mytery Boxes',
                    value: 'Mytery Boxes',
                },
            ]
        },
    })
    filterData
    get selected() {
        if (!this.filterData) return 'All'

        return this.filterData[0].value
    }
}
</script>

<style scoped>
.select-item select {
    height: fit-content;
    border-radius: 10px;
    min-width: 200px;
    border: 1.5px solid;
    color: #26b6d4;
    background: #0e0e0f;
    transition: all 0.3s ease-in-out;
}
</style>
