<template>
  <div class="loading__container col-12 px-0 d-flex justify-content-between">
    <div class="animated__container m-3">
      <div class="animated__content col-12"></div>
    </div>
    <div class="animated__container m-3">
      <div class="animated__content col-12"></div>
    </div>
    <div class="animated__container m-3">
      <div class="animated__content col-12"></div>
    </div>
    <div class="animated__container m-3">
      <div class="animated__content col-12"></div>
    </div>
  </div>
</template>

<script>
import { Vue, Component } from 'vue-property-decorator';

@Component({
  name: 'SaleListLoader',
})
export default class SaleListLoader extends Vue {}
</script>

<style scoped lang="scss">
.loading__container {
  background-color: transparent;
}
.background-masker {
  background-color: transparent;
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -800px 0;
  }
  100% {
    background-position: 800px 0;
  }
}

.animated {
  &__container {
    width: calc(25% - 32px);
    height: 360px;
  }

  &__content {
    display: inline-block;
    animation-duration: 3s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    border-radius: 12px;
    background: linear-gradient(
      to right,
      #525252 8%,
      #666666 18%,
      #666666 18%,
      #525252 8%
    );
    background-size: 800px 104px;
    height: 100%;
  }
}
</style>
