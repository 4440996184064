





























































import { Component, Watch, Prop } from 'vue-property-decorator';
import IntegratedTokenMixins from '@/mixins/integratedTokenMixins';
import { TokenInfo } from '@solana/spl-token-registry';

@Component({
  name: 'AuctionMint',
})
export default class AuctionMint extends IntegratedTokenMixins {
  @Prop(Object || null) value: any;

  showSelectCurrency = false;

  get unknownTokenLogo() {
    return require('@/assets/images/unknown_token.png');
  }

  get currency() {
    return this.value;
  }

  set currency(val) {
    this.$emit('input', val);
  }

  selectCurrency(cur: any) {
    this.currency = cur;
    this.showSelectCurrency = false;
  }

  setUnknownToken(cur?: any) {
    if (!cur) {
      cur = this.currency;
    }
    if (!cur) {
      return;
    }
    if (cur?.logoURI === this.unknownTokenLogo) {
      return;
    }

    cur.logoURI = this.unknownTokenLogo;
  }

  @Watch('tokenFetched')
  watchTokenFetched(val: boolean) {
    if (!val) {
      return;
    }

    if (this.currency) {
      return;
    }

    this.currency =
      this.tokens?.find((token: TokenInfo) => token.symbol === 'SOL') || null;
  }
}
