import Vue from 'vue';
import './plugins/axios';
import App from './App.vue';
import router from './router';
import VueGtag from 'vue-gtag';
import 'animate.css';
import store from './store';
import './mixins/ultiMixins';
// import WOW from "wow.js/dist/wow.js"
// @ts-ignore
import * as WOW from 'wow.js/dist/wow';
import './plugins/bootstrap';
import './plugins/vee-validate';

// Import Ant Design
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
Vue.use(Antd);

Vue.config.productionTip = false;
// new WOW().init()
const wow = new WOW();
// @ts-ignore
wow.init();
// GOOD

// Optionally install the BootstrapVue icon components plugin
// Vue.use(IconsPlugin)
Vue.config.productionTip = false;
router.beforeEach((to: any, from: any, next: any) => {
  const getStore: any = store;
  if (to.name !== 'Login' && getStore && getStore.state.walletModule.wallet_addr === '')
    next({ name: 'Login' });
  else next();
});

Vue.use(
  VueGtag,
  {
    config: { id: 'G-ZDGCCWZ2MM' },
    appName: 'AlphaBatem',
    pageTrackerScreenviewEnabled: true,
  },
  router,
);

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');
