import { GetterTree, MutationTree, ActionTree, ActionContext } from 'vuex';
import { AccountInfo, Connection, PublicKey } from '@solana/web3.js';
import { TOKEN_PROGRAM_ID } from '@solana/spl-token';
import { Buffer } from 'buffer';

import { NFTGet } from '@/common/NFTget';
import { INFT } from '@/common/helpers/types';
import { getEdition } from '@/views/shop/actions/metadata';
import { processMetaData } from '@/views/shop/actions/processDecodeMetadata';
import { getEmptyMetaState } from '@/views/shop/store/metaState';
import { makeSetter } from '@/views/shop/contexts/meta/loadAccount';
import { cloneObject } from '@/helper/objects';
import { metadataToArt } from '@/views/shop/components/wallet-items/artContent';
import { TokenAccountParser } from '@/views/shop/utils/structParsers';
import { TokenAccount } from '@/views/shop/models';
import { pubkeyToString } from '@/views/shop/utils';

const MaxCacheTime = 60 * 1000; // miliseconds

class State {
  cachedAt = 0;
  walletNFTs = new Map<string, Map<string, INFT>>();
  cacheExtendedNFTs = new Map<string, any>();
  metaState = getEmptyMetaState();
  tokenAccounts = new Map<string, TokenAccount>();
}

const getters: GetterTree<State, any> = {};
const mutations = <MutationTree<State>>{};
const actions = <ActionTree<State, any>>{
  async fetchNFTs(State): Promise<INFT[] | undefined> {
    const walletAdd = State.rootState[`walletModule`].wallet_addr;
    if (!walletAdd) {
      return;
    }

    if (
      !State.state.walletNFTs.has(walletAdd) ||
      !State.state.cachedAt ||
      new Date().getTime() > State.state.cachedAt + MaxCacheTime
    ) {
      const succeeded = await fetchNFTsByOwner(State, new PublicKey(walletAdd)).catch(
        e => {
          if (e.message?.includes('not found')) {
            return;
          }
          throw e;
        },
      );
      if (!succeeded) {
        return [];
      }
      if (!State.state.walletNFTs.has(walletAdd)) {
        return [];
      }
      State.state.cachedAt = new Date().getTime();
    }

    const listNFT = [] as INFT[];
    State.state.walletNFTs.get(walletAdd)?.forEach((item: INFT) => {
      listNFT.push(item);
    });

    return listNFT;
  },

  async getArt(State, mintAddress): Promise<INFT | undefined> {
    const walletAdd = State.rootState[`walletModule`].wallet_addr;
    if (!walletAdd) {
      return;
    }
    const nft = getCacheNFT(State, mintAddress);
    if (nft) {
      return nft.metadataOnchain;
    }
    const pubkey = new PublicKey(walletAdd);
    await fetchNFTsByOwner(State, pubkey);
    return cloneObject(getCacheNFT(State, mintAddress)?.metadataOnchain); // void mutate to vuex state
  },

  async getExtendedArt(State, mintAddress) {
    const cached = getCacheExtendedNFT(State, mintAddress);
    if (cached) {
      return cached;
    }
    const connection = State.rootState.connectionModule.connection as Connection;
    await State.dispatch('getArt', mintAddress); // wait fetch if not exist
    const mintInfo = getCacheNFT(State, mintAddress);
    const nft = mintInfo.metadataOnchain;
    const external = mintInfo.metadataExternal;

    const metaState = State.state.metaState;
    const setter = makeSetter(metaState);
    const editionAddress = await getEdition(mintAddress);
    const edition = await connection.getAccountInfo(new PublicKey(editionAddress));
    if (edition) {
      processMetaData({ account: edition, pubkey: editionAddress }, setter);
    }

    nft.edition = editionAddress;
    nft.masterEdition = editionAddress;
    const art = metadataToArt(nft, metaState.editions, metaState.masterEditions);
    Object.assign(art, external);
    State.state.cacheExtendedNFTs.set(mintAddress, art);
    return art;
  },

  async getTokenAccount(State, mintAddress): Promise<TokenAccount | undefined> {
    const id = pubkeyToString(mintAddress);
    if (State.state.tokenAccounts.has(id)) {
      return State.state.tokenAccounts.get(id);
    }

    await fetchTokenAccountBackgrounds(State);
    return State.state.tokenAccounts.get(id);
  },

  resetCacheList(State) {
    State.state.cachedAt = 0;
  },
};

const MetaplexNFTModule = {
  namespaced: true,
  getters,
  state: new State(),
  mutations,
  actions,
};

export default MetaplexNFTModule;

function getCacheNFT(
  State: ActionContext<State, any>,
  mintAddress: string,
): any | boolean {
  const walletAdd = State.rootState[`walletModule`].wallet_addr;
  if (!walletAdd) {
    return false;
  }
  if (!State.state.walletNFTs.has(walletAdd)) {
    return false;
  }

  return State.state.walletNFTs?.get(walletAdd)?.get(mintAddress);
}

function getCacheExtendedNFT(
  State: ActionContext<State, any>,
  mintAddress: string,
): any | boolean {
  const walletAdd = State.rootState[`walletModule`].wallet_addr;
  if (!walletAdd) {
    return false;
  }
  if (!State.state.cacheExtendedNFTs.has(walletAdd)) {
    return false;
  }

  return State.state.cacheExtendedNFTs?.get(walletAdd)?.get(mintAddress);
}

async function fetchNFTsByOwner(
  State: ActionContext<State, any>,
  owner: PublicKey,
): Promise<boolean> {
  fetchTokenAccountBackgrounds(State);
  const listNFT = await NFTGet({ owner });
  if (!listNFT) {
    return true;
  }

  const walletAdd = State.rootState[`walletModule`].wallet_addr;
  const cached = State.state.walletNFTs;
  cached.set(walletAdd, new Map<string, INFT>());
  listNFT.forEach((nft: any) => {
    // @ts-ignore
    cached.get(walletAdd).set(nft.mint.toString(), nft);
  });
  return true;
}

async function fetchTokenAccountBackgrounds(State: ActionContext<State, any>) {
  const owner = State.rootState.walletModule.wallet_addr;
  const connection = State.rootState.connectionModule.connection as Connection;
  const response = await connection.getTokenAccountsByOwner(new PublicKey(owner), {
    programId: TOKEN_PROGRAM_ID,
  });
  if (!response) {
    return;
  }
  response.value.forEach(
    (accountInfo: { pubkey: PublicKey; account: AccountInfo<Buffer> }) => {
      const address = accountInfo.pubkey.toBase58();
      const tokenAccount = TokenAccountParser(address, accountInfo.account);
      if (tokenAccount) {
        const mintAddress = tokenAccount.info.mint.toBase58();
        State.state.tokenAccounts.set(mintAddress, tokenAccount);
      }
    },
  );
}
