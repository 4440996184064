






import { Component, Vue } from 'vue-property-decorator'

@Component({
    components: { Frame: () => import('../../components/game/Frame.vue') },
})
export default class Dashboard extends Vue {}
