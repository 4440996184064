import { AccountInfo, PublicKey } from '@solana/web3.js';
import { AuctionManagerV2Data, MetaplexKey } from '@metaplex-foundation/mpl-metaplex';
import { METAPLEX_ID, programIds, pubkeyToString } from '@/views/shop/utils';
import { ProcessAccountsFunc } from '@/views/shop/contexts/meta/types';
import { decodeStoreIndexer } from '@/views/shop/actions/storeIndexer';
import { ParsedAccount } from '@/views/shop/contexts/accounts/types';
import { AuctionCache, StoreIndexer } from '@/views/shop/actions/cacheAuctionDefines';
import { programs } from '@metaplex/js';
import { decodeAuctionCache } from '@/views/shop/actions/cacheAuction';

export const processMetaplexAccounts: ProcessAccountsFunc = async (
  { account, pubkey },
  setter,
) => {
  if (!isMetaplexAccount(account)) return;
  const STORE_ID = programIds().store;

  try {
    if (isStoreIndexerV1Account(account)) {
      const indexer = decodeStoreIndexer(account.data);
      const parsedAccount: ParsedAccount<StoreIndexer> = {
        pubkey,
        account,
        info: indexer,
      };
      if (parsedAccount.info.store == STORE_ID?.toBase58()) {
        setter('storeIndexer', pubkey, parsedAccount);
      }
    }

    if (isAuctionManagerV1Account(account) || isAuctionManagerV2Account(account)) {
      const storeKey = new PublicKey(account.data.slice(1, 33));

      if (STORE_ID && storeKey.equals(STORE_ID)) {
        const parsedAccount: ParsedAccount<AuctionManagerV2Data> = {
          pubkey,
          account,
          info: new programs.metaplex.AuctionManager(pubkey, account).data,
        };
        setter('auctionManagersByAuction', parsedAccount.info.auction, parsedAccount);
      }
    }

    if (isAuctionCacheV1Account(account)) {
      const cache = decodeAuctionCache(account.data);

      if (STORE_ID?.toBase58?.() === cache.store) {
        const parsedAccount: ParsedAccount<AuctionCache> = {
          pubkey,
          account,
          info: cache,
        };
        setter('auctionCaches', pubkey, parsedAccount);
      }
    }
  } catch {
    // ignore errors
  }
};

const isMetaplexAccount = (account: AccountInfo<Buffer>) =>
  account && pubkeyToString(account.owner) === METAPLEX_ID;

const isStoreIndexerV1Account = (account: AccountInfo<Buffer>) =>
  account.data[0] === MetaplexKey.StoreIndexerV1;

const isAuctionManagerV1Account = (account: AccountInfo<Buffer>) =>
  account.data[0] === MetaplexKey.AuctionManagerV1;

const isAuctionManagerV2Account = (account: AccountInfo<Buffer>) =>
  account.data[0] === MetaplexKey.AuctionManagerV2;

const isAuctionCacheV1Account = (account: AccountInfo<Buffer>) =>
  account.data[0] === MetaplexKey.AuctionCacheV1;
