<template>
    <div class="nft-item-details">
        <div class="row mt-2">
            <h6 class="col-12">NFT Details</h6>

            <div class="col-6">
                <div class="form-group">
                    <label>Contract Address</label>
                    <input readonly id="contract_addr" class="form-control" :value="selected_asset.asset_contract.address" />
                </div>
                <div class="form-group">
                    <label>Token Address</label>
                    <input readonly id="token_addr" class="form-control" :value="selected_asset.token_id" />
                </div>
                <div class="form-group">
                    <label>Owner Address</label>
                    <input readonly id="owner_addr" class="form-control" :value="selected_asset.owner.address" />
                </div>
                <div class="form-group">
                    <label>Token Type</label>
                    <input readonly id="token_type" class="form-control" :value="selected_asset.asset_contract.schema_name" />
                </div>
            </div>
            <div class="col-6">
                <form class="form">
                    <div class="form-group">
                        <label>Name</label>
                        <input readonly :value="selected_asset.name" class="form-control" />
                    </div>

                    <div class="form-group">
                        <label>Description</label>
                        <input readonly :value="selected_asset.description" class="form-control" />
                    </div>

                    <div class="form-group">
                        <label>Item Type</label>
                        <input readonly :value="form.item_type" class="form-control" />
                    </div>

                    <div class="form-group">
                        <label>Item Sub-Type</label>
                        <input readonly :value="form.interaction_type" class="form-control" />
                    </div>
                </form>
            </div>
        </div>
        <div class="row mt-3">
            <h6 class="col-12">Item Details</h6>

            <div class="col-6">
                <div class="form-group">
                    <label>Tile X</label>
                    <input readonly :value="form.details.tile_x" class="form-control" />
                </div>

                <div class="form-group">
                    <label>Tile Y</label>
                    <input readonly :value="form.details.tile_y" class="form-control" />
                </div>

                <div class="form-group">
                    <label>Tile Z</label>
                    <input readonly :value="form.details.tile_z" class="form-control" />
                </div>

                <div class="form-group mt-5">
                    <label>Use Isometric Shader</label>
                    <input readonly type="checkbox" class="form-check-input" checked />
                </div>
            </div>
            <div class="col-6">
                <div class="form-group">
                    <label>Scale X</label>
                    <input readonly :value="form.details.scale_x" class="form-control" />
                </div>

                <div class="form-group">
                    <label>Scale Y</label>
                    <input readonly :value="form.details.scale_y" class="form-control" />
                </div>

                <div class="form-group">
                    <label>Position X</label>
                    <input readonly :value="form.details.position_x" class="form-control" />
                </div>

                <div class="form-group">
                    <label>Position Y</label>
                    <input readonly :value="form.details.position_y" class="form-control" />
                </div>
            </div>
        </div>
        <div class="col-12 mt-5">
            <button class="btn btn-lg btn-block disabled">Confirm & Pay ({{ total_blocks * 100 }} Daric) - {{ total_blocks }} Blocks x 100 Daric</button>
            <!--            <button class="btn btn-lg btn-block" v-on:click="acceptDetails">Confirm & Pay ({{total_blocks*100}} Daric) - -->
            <!--                {{total_blocks}} Blocks x 100 Daric-->
            <!--            </button>-->
        </div>
    </div>
</template>

<script>
import Web3 from 'web3'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({ name: 'Details' })
export default class Details extends Vue {
    @Prop({ default: null }) web3
    @Prop(Number) total_blocks
    @Prop(Object) selected_asset
    @Prop(Object) form

    importerContractAddress = '0x1b47f49a325EFC79ad3a8d780b77Ce74bbF9a545'

    acceptDetails() {
        window.ethereum
            .request({
                method: 'eth_sendTransaction',
                params: [
                    {
                        from: window.ethereum.selectedAddress,
                        to: this.importerContractAddress,
                        value: Web3.utils.numberToHex(100 * this.total_blocks),
                        gas: '0x2710',
                    },
                ],
            })
            .then(txHash => {
                console.log(txHash)
                this.$emit('payment-processing', txHash)
                this.$emit('next')
            })
            .catch(error => console.error(error))
    }
    
    connectWallet() {
        if (typeof window.ethereum !== 'undefined') {
            // Instance web3 with the provided information
            this.web3 = new Web3(window.ethereum)
            try {
                // Request account access
                window.ethereum.enable().then(() => this.getActiveAccount())
                return true
            } catch (e) {
                // User denied access
                return false
            }
        }
    }

    mounted() {
        this.connectWallet()
    }
}
</script>

<style scoped>
.form-check-input {
    transform: scale(2.5);
}
</style>
