<template>
    <button class="btn btn-default" v-on:click="setupConnector">{{ buttonTxt }}</button>
</template>

<script>
import { Component, Vue} from 'vue-property-decorator'
import WalletConnectClient from '@walletconnect/client'
import QRCodeModal from '@walletconnect/qrcode-modal'

@Component({})
export default class WalletConnect extends Vue {
    buttonTxt = 'Wallet Connect'
    connector = null

    setupConnector() {
        // Check if connection is already established
        if (!this.connector.connected) {
            // create new session
            this.connector.createSession()
        }

        // Subscribe to connection events
        this.connector.on('connect', (error, payload) => {
            if (error) {
                throw error
            }

            // Get provided accounts and chainId
            const { accounts, chainId } = payload.params[0]
            console.log('Wallet connected: ', { a: accounts, c: chainId })
            this.$emit('account', accounts)
            this.$emit('chain', chainId)

            this.$emit('wallet-connected', accounts) //Fire connected event
            this.$store.commit('set_wallet_addr', accounts)
            this.$store.commit('set_wallet_connected', true)
            this.$emit('connected', true)
        })

        this.connector.on('session_update', (error, payload) => {
            if (error) {
                throw error
            }

            // Get updated accounts and chainId
            const { accounts, chainId } = payload.params[0]
            this.$emit('account', accounts)
            this.$emit('chain', chainId)
        })

        this.connector.on('disconnect', (error, payload) => {
            if (error) {
                throw error
            }

            console.log('Connector disconnected', payload)
            // Delete connector
        })
    }

    mounted() {
        this.connector = new WalletConnectClient({
            bridge: 'https://bridge.walletconnect.org', // Required
            qrcodeModal: QRCodeModal,
        })
    }
}
</script>

<style scoped></style>
