import { AccountInfo, PublicKey } from '@solana/web3.js';
import { ProcessAccountsFunc } from '@/views/shop/contexts/meta/types';
import { pubkeyToString, VAULT_ID } from '@/views/shop/utils';
import {
  SafetyDepositBoxData,
  VaultData,
  VaultKey,
} from '@metaplex-foundation/mpl-token-vault';
import { programs } from '@metaplex/js';
import { ParsedAccount } from '@/views/shop/contexts/accounts/types';

export const processVaultAccount: ProcessAccountsFunc = ({ account, pubkey }, setter) => {
  if (!isVaultAccount(account)) return;

  try {
    if (isSafetyDepositBoxV1Account(account)) {
      const parsedAccount: ParsedAccount<SafetyDepositBoxData> = {
        pubkey,
        account: account,
        info: new programs.vault.SafetyDepositBox(new PublicKey(pubkey), account).data,
      };
      setter(
        'safetyDepositBoxesByVaultAndIndex',
        parsedAccount.info.vault + '-' + parsedAccount.info.order,
        parsedAccount,
      );
    }
    if (isVaultV1Account(account)) {
      const parsedAccount: ParsedAccount<VaultData> = {
        pubkey,
        account: account,
        info: new programs.vault.Vault(new PublicKey(pubkey), account).data,
      };

      setter('vaults', pubkey, parsedAccount);
    }
  } catch {
    // ignore errors
    // add type as first byte for easier deserialization
  }
};

const isVaultAccount = (account: AccountInfo<Buffer>) =>
  account && pubkeyToString(account.owner) === VAULT_ID;

const isSafetyDepositBoxV1Account = (account: AccountInfo<Buffer>) =>
  account.data[0] === VaultKey.SafetyDepositBoxV1;

const isVaultV1Account = (account: AccountInfo<Buffer>) =>
  account.data[0] === VaultKey.VaultV1;
