<template>
    <div class="container-fluid index mt-5 p-5">
        <div class="row text-center" id="game-container">
            <div class="col-12 mb-3">
                <SearchBar></SearchBar>
            </div>

            <div class="col-12">
                <div class="row">
                    <div class="col-12">
                        <h3 class="text-left wow fadeInDown">
                            Featured<small> <button class="btn-none btn-sm">ALL</button></small>
                        </h3>
                    </div>
                </div>
                <ItemCollection :items="featured"></ItemCollection>
            </div>

            <div class="col-12 mt-5">
                <div class="row">
                    <div class="col-12">
                        <h3 class="text-left">
                            Trending<small> <button class="btn-none btn-sm">ALL</button></small>
                        </h3>
                    </div>
                </div>
                <ItemCollection :items="trending"></ItemCollection>
            </div>

            <div class="col-12 mt-5">
                <div class="row">
                    <div class="col-12">
                        <h3 class="text-left">
                            Recent<small> <button class="btn-none btn-sm">ALL</button></small>
                        </h3>
                    </div>
                </div>
                <ItemCollection :items="recent"></ItemCollection>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue'
import Component from 'vue-class-component'

@Component({
    name: 'MarketIndex',
    components: {
        ItemCollection: () => import('../../components/marketplace/ItemCollection'),
        SearchBar: () => import('../../components/marketplace/SearchBar.vue'),
    },
})
export default class MarketIndex extends Vue {
    featured = []
    trending = []
    recent = []

    spawn() {
        for (let i = 0; i < 16; i++) {
            const item = {
                id: i,
                name: 'Item ' + i,
                description: 'This is a description for item ' + i,
                price: i * 10 + 1 + Math.round(Math.random() * 100),
                creator: {
                    id: i,
                    name: 'Creator ' + i,
                    wallet_addr: '0x' + i.toString(16),
                    avatar: 'https://lh3.googleusercontent.com/wl09kAQ8Zr_9rMmJM6hquCMWctYAgiQIOCnCvuHkabkaUKoC2hHq_qR2_5Re_Kq9j5ayjkqUnI19sHjKwvD8j8lyfZcUP_Meq8Q9=w600',
                },
                owner: {
                    id: i,
                    name: 'Owner ' + i,
                    wallet_addr: '0x' + i.toString(16),
                    avatar: 'https://lh3.googleusercontent.com/wl09kAQ8Zr_9rMmJM6hquCMWctYAgiQIOCnCvuHkabkaUKoC2hHq_qR2_5Re_Kq9j5ayjkqUnI19sHjKwvD8j8lyfZcUP_Meq8Q9=w600',
                },
                media: {
                    thumbnail_src:
                        'https://lh3.googleusercontent.com/wl09kAQ8Zr_9rMmJM6hquCMWctYAgiQIOCnCvuHkabkaUKoC2hHq_qR2_5Re_Kq9j5ayjkqUnI19sHjKwvD8j8lyfZcUP_Meq8Q9=w600',
                    image_src:
                        'https://lh3.googleusercontent.com/wl09kAQ8Zr_9rMmJM6hquCMWctYAgiQIOCnCvuHkabkaUKoC2hHq_qR2_5Re_Kq9j5ayjkqUnI19sHjKwvD8j8lyfZcUP_Meq8Q9=w600',
                    video_src:
                        'https://lh3.googleusercontent.com/wl09kAQ8Zr_9rMmJM6hquCMWctYAgiQIOCnCvuHkabkaUKoC2hHq_qR2_5Re_Kq9j5ayjkqUnI19sHjKwvD8j8lyfZcUP_Meq8Q9=w600',
                    object_src:
                        'https://lh3.googleusercontent.com/wl09kAQ8Zr_9rMmJM6hquCMWctYAgiQIOCnCvuHkabkaUKoC2hHq_qR2_5Re_Kq9j5ayjkqUnI19sHjKwvD8j8lyfZcUP_Meq8Q9=w600',
                },
                metadata: {},
                tags: [i, i + 1, i + 2],
                analytics: {
                    views: i * 10,
                },
                history: [
                    {
                        id: i,
                        name: 'Owner ' + i,
                        avatar: 'https://lh3.googleusercontent.com/wl09kAQ8Zr_9rMmJM6hquCMWctYAgiQIOCnCvuHkabkaUKoC2hHq_qR2_5Re_Kq9j5ayjkqUnI19sHjKwvD8j8lyfZcUP_Meq8Q9=w600',
                        action: 'purchase',
                        wallet_addr: '0x' + i.toString(16),
                        timestamp: new Date().getTime(),
                    },
                ],
            }

            this.featured.push(item)
            this.trending.push(item)
            this.recent.push(item)
        }
    }
	
    mounted() {
        this.spawn()
    }
}
</script>

<style scoped>
h3 {
    font-family: 'Prime-Light', sans-serif;
    color: #0a9fb9;
    font-size: 1.9rem;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin-bottom: 3vh;
}
</style>
