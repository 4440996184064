import { Artist, ArtType } from '@/views/shop/types';
import { toNumber } from '@/helper/numbers';

export const metadataToArt = (
  info: any | undefined,
  editions: any = {},
  masterEditions: any = {},
  whitelistedCreatorsByCreator: any = {},
) => {
  let type: ArtType = ArtType.NFT;
  let editionNumber: number | undefined = undefined;
  let maxSupply: number | undefined = undefined;
  let supply: number | undefined = undefined;

  if (info) {
    const masterEdition = masterEditions[info.masterEdition || ''];
    const edition = editions[info.edition || ''];
    if (edition) {
      const myMasterEdition = masterEditions[edition.info.parent || ''];
      if (myMasterEdition) {
        type = ArtType.Print;
        editionNumber = toNumber(edition.info.edition);
        supply = toNumber(myMasterEdition.info?.supply || 0);
      }
    } else if (masterEdition) {
      type = ArtType.Master;
      maxSupply =
        typeof masterEdition.info.maxSupply === 'undefined'
          ? undefined
          : toNumber(masterEdition.info.maxSupply);
      supply = toNumber(masterEdition.info.supply);
    }
  }

  return {
    uri: info?.data.uri || '',
    mint: info?.mint,
    title: info?.data.name,
    creators: (info?.data.creators || [])
      .map((creator: Artist) => {
        const knownCreator = whitelistedCreatorsByCreator[creator.address || ''];

        return {
          address: creator.address,
          verified: creator.verified,
          share: creator.share,
          image: knownCreator?.info.image || '',
          name: knownCreator?.info.name || '',
          link: knownCreator?.info.twitter || '',
        };
      })
      .sort((a: Artist, b: Artist) => {
        const share = (b.share || 0) - (a.share || 0);
        if (share === 0) {
          return a.name.localeCompare(b.name);
        }

        return share;
      }),
    seller_fee_basis_points: info?.data.sellerFeeBasisPoints || 0,
    edition: editionNumber,
    maxSupply,
    supply,
    type,
  };
};
