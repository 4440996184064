<template>
  <section class="container-fluid index inner-view ant-layout">
    <transition name="fade">
      <div
        v-if="isLoading"
        class="col-12 vh-100 d-flex justify-content-center align-items-center"
      >
        <a-spin />
      </div>
      <div v-else class="row">
        <div class="col-xs-12 col-md-6 pl-4">
          <ArtContent
            class="artwork-image"
            :style="[{ width: '100%', height: 'auto', margin: '0 auto' }]"
            :height="300"
            :width="300"
            :pubkey="id"
            :active="true"
            :allowMeshRender="true"
            :artView="true"
          />
        </div>

        <div
          class="col-xs-12 col-md-6"
          :style="{ textAlign: 'left', fontSize: '1.4rem' }"
        >
          <div :style="{ fontWeight: 700, fontSize: '4rem' }">
            {{ name }}
          </div>
          <div class="row">
            <div class="col-3">
              <h6>Royalties</h6>
              <div class="royalties">
                {{ ((art.seller_fee_basis_points || 0) / 100).toFixed(2) }}%
              </div>
            </div>
            <div class="col-6">
              <ViewOn :id="id" :art="art" />
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <h6 :style="{ marginTop: 5 }">Created By</h6>
              <div class="creators">
                <div
                  v-for="(creator, idx) in creators"
                  class="mb-2"
                  :key="idx"
                  :style="{ display: 'flex', alignItems: 'center', marginBottom: 5 }"
                >
                  <MetaAvatar :creator="creator" :size="64" />
                  <span class="creator-name px-2">
                    {{ creator.name || shortenAddress(creator.address || '') }}
                  </span>
                  <template v-if="!creator.verified">
                    <button
                      class="ant-btn"
                      v-if="creator.address === pubkey"
                      @click="approve"
                    >
                      Approve
                    </button>
                    <div class="info-header mb-1 mx-4" v-else>
                      <a-tag class="ant-tag ant-tag-blue">UNVERIFIED</a-tag>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <h6 :style="{ marginTop: 5 }">Edition</h6>
              <div class="art-edition">{{ badge }}</div>
            </div>
          </div>
          <div v-if="art.type === ArtType.Master" class="row">
            <div class="col-12">
              <h6 :style="{ marginTop: 5 }">Max Supply</h6>
              <div class="art-edition">{{ maxSupply }}</div>
            </div>
          </div>
          <ArtMinting
            :id="id"
            :remountArtMinting="remountArtMinting"
            :art="art"
            @onMint="remountArtMinting++"
          />
        </div>

        <div class="col-12" />

        <div class="col-12 mt-5">
          <div class="row">
            <div class="col-6">
              <template v-if="totalUnverified > 0">
                <span class="ant-tag ant-tag-blue mb-3">UNVERIFIED</span>
                <br />
                <div :style="[{ fontSize: '12px', fontStyle: 'italic' }]">
                  <i>
                    This artwork is still missing verification from
                    {{ totalUnverified }}
                    {{ totalUnverified > 1 ? 'contributors' : 'contributor' }} before it
                    can be considered verified and sellable on the platform.
                  </i>
                </div>
                <br />
              </template>
              <br />
              <div class="info-header">ABOUT THE CREATION</div>
              <div class="info-content">{{ description }}</div>
              <br />
            </div>
            <div v-if="attributes && attributes.length > 0" class="col-6">
              <br />
              <div class="info-header">Attributes</div>
              <div v-for="(attr, idx) in attributes" :key="idx" class="col-6">
                <ul
                  :style="[{ fontSize: '12px', border: '1px solid white' }]"
                  class="p-2"
                >
                  <div class="info-header" :style="[{ fontSize: '12px' }]">
                    {{ attr.trait_type }}
                  </div>
                  <li class="px-2">{{ attr.value }}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </section>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { Component, Vue } from 'vue-property-decorator';
import { ArtType } from '@/views/shop/types';
import { shortenAddress } from '@/views/shop/utils';

@Component({
  name: 'WalletItem',
  components: {
    ArtContent: () => import('./art-content/Index'),
    ArtMinting: () => import('./art-minting/Index'),
    ViewOn: () => import('./ViewOn'),
    MetaAvatar: () => import('./MetaAvatar'),
  },
  computed: {
    ...mapState('walletModule', ['wallet_addr']),
    ...mapState('metaplexNFTModule', ['metaState']),
  },
  methods: {
    ...mapActions('metaplexNFTModule', ['getArt', 'getExtendedArt']),
  },
})
export default class WalletItem extends Vue {
  ArtType = ArtType;

  id = '';
  creators = undefined;
  name = '';
  badge = '';
  maxSupply = '';
  description = '';
  attributes = undefined;
  pubkey = '';
  art = undefined;
  totalUnverified = 0;
  remountArtMinting = 0;

  isLoading = true;

  shortenAddress = shortenAddress;

  async created() {
    if (!this.$route.params?.id) {
      return;
    }
    this.id = this.$route.params.id;
    const art = await this.getExtendedArt(this.id);

    this.isLoading = false;

    this.creators = art?.creators || this.creators || [];
    this.totalUnverified = this.creators.filter(creator => !creator.verified).length;
    this.name = art?.title || this.name || ' ';

    if (art.type === ArtType.NFT) {
      this.badge = 'Unique';
    } else if (art.type === ArtType.Master) {
      this.badge = 'NFT 0';
      if (art.maxSupply !== undefined) {
        this.maxSupply = art.maxSupply.toString();
      } else {
        this.maxSupply = 'Unlimited';
      }
    } else if (art.type === ArtType.Print) {
      this.badge = `${art.edition} of ${art.supply}`;
    }

    this.description = art.description;
    this.attributes = art.attributes;
    this.pubkey = this.walletModule?.wallet_addr || '';
    this.art = art;
  }

  approve() {}
}
</script>

<style scoped>
.ant-btn {
  height: 56px;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  background-color: transparent !important;
  color: white;
}

.ant-tag-blue {
  color: #096dd9;
  background: #e6f7ff;
  border-color: #91d5ff;
}

.info-header {
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  margin-bottom: 1vh;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.7);
}

section.ant-layout {
  width: 100%;
  max-width: 1440px;
  margin: auto;
  position: relative;
  background: rgba(255, 255, 255, 0);
}

.ant-btn:hover,
.ant-btn:focus {
  color: #a1b4ff;
  border-color: #a1b4ff;
}

.row {
  margin-top: 24px;
}
</style>
