<template>
    <div class="card">
        <div class="card-body text-center">
            <div class="col-lg-10 col-md-12 col-sm-12 offset-lg-1">
                <div class="row">
                    <div class="col-12">
                        <h3>Amount To Play</h3>
                    </div>
                </div>
            </div>

            <div class="col-lg-8 col-md-12 col-sm-12 offset-lg-2 mt-4">
                <div class="row">
                    <div class="col-4"><img src="https://cryptologos.cc/logos/harmony-one-logo.svg?v=014" height="40px" /></div>
                    <div class="col">
                        <h1>{{ bet_amount }}</h1>
                    </div>
                    <div class="col-4"><label class="currency text">ONE</label></div>
                </div>
            </div>

            <h6 class="mt-4">
                Current Address:
                <small>{{ $store.state.wallet_addr }}</small>
            </h6>

            <button class="btn mt-2 mb-2" v-show="!bets_enabled" disabled>Loading Contract...</button>
            <button class="btn btn-default mt-2 mb-2" v-show="bets_enabled" v-on:click="play">Play</button>
        </div>
    </div>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator'

@Component({})
export default class FixedBet extends Vue {
    @Prop({ default: false, type: Boolean }) bets_enabled
    @Prop(Number) bet_amount

    play() {
        this.$emit('place-bet', this.bet_amount)
    }
}
</script>

<style scoped></style>
