<template>
  <div>
    <div class="container-fluid index inner-view align-items-center m-0 p-5 py-0">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import { Component } from 'vue-property-decorator';
import metaplexStoreMixin from '@/views/shop/mixins/metaplexStoreMixin';

@Component({
  name: 'Metaplex',
})
export default class Metaplex extends metaplexStoreMixin {}
</script>

<style>
body {
  margin: 0;
  color: rgba(255, 255, 255);
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue',
    Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-variant: tabular-nums;
  line-height: 1.5715;
  background-color: #fff;
  -moz-font-feature-settings: 'tnum', 'tnum';
  font-feature-settings: 'tnum', 'tnum';
}

h6 {
  display: block;
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  color: #ffffff;
  opacity: 0.5;
}

.metamask-icon {
  background-position: 50%;
  background-repeat: no-repeat;
  /*background-image: url('@/views/shop/public/wallets/metamask.svg');*/
}

.app-right {
  align-items: center;
  display: flex;
  flex-direction: row;
  margin-right: 15px;
}

@media screen and (max-width: 1200px) {
  #desktop-navbar {
    display: none;
  }

  .ant-tabs-mobile {
    padding: 20px;
  }
}

#desktop-navbar {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

section.ant-layout {
  width: 100%;
  margin: auto;
  position: relative;
}

section.ant-layout #width-layout {
  max-width: 1440px;
}

section#main-layout.ant-layout {
  background-color: #121212 !important; /* bg-color */
  min-height: 100vh;
}

section#main-layout.ant-layout span#static-header-gradient {
  display: none;
  position: absolute;
  height: 300px;
  width: 100%;
  left: 0;
  background: linear-gradient(
    0,
    rgba(255, 255, 255, 0.05) 0%,
    rgba(255, 255, 255, 0) 100%
  );
}

section#main-layout.ant-layout span#static-end-gradient {
  display: none;
  position: absolute;
  height: 300px;
  width: 100%;
  left: 0;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.05) 0%,
    rgba(255, 255, 255, 0) 100%
  );
}

section#main-layout.ant-layout #bg-gradient {
  position: absolute;
  height: 200px;
  width: 100%;
  left: 0;
  top: 510px;
  background: linear-gradient(180deg, rgba(18, 18, 18, 0) 0%, #121212 100%);
  display: none;
}

section#main-layout.ant-layout span#main-bg {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 708px;
  background: linear-gradient(180deg, rgba(18, 18, 18, 0) 0%, #121212 100%);
  background-position: top left !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  filter: blur(220px);
  clip-path: inset(0 0 0 0);
}

header.ant-layout-header.App-Bar {
  width: 100%;
  background: transparent;
  display: flex;
  justify-content: center;
  height: fit-content;
  z-index: 10;
}

@media (max-width: 520px) {
  /* @mobile-xs-max */
  header.ant-layout-header.App-Bar {
    max-height: 64px;
    position: sticky;
    background: #121212; /* @black-100 */
  }
}

footer.ant-layout-footer {
  padding: 10px 0;
  width: 100%;
}

.ant-layout-sider {
  background-color: unset;
}

.masonry-grid {
  display: flex;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-left: -32px !important;
}

.masonry-grid_column {
  padding-left: 32px !important; /* gutter size */
  background-clip: padding-box;
}

.masonry-grid_column > div {
  /* change div to reference your elements you put in <Masonry> */
  margin-bottom: 30px;
}

.ant-tabs-ink-bar {
  display: none;
}

.ant-tabs-top > .ant-tabs-nav::before,
.ant-tabs-top > div > .ant-tabs-nav::before {
  display: none;
}

.ant-tabs-top > .ant-tabs-nav,
.ant-tabs-bottom > .ant-tabs-nav,
.ant-tabs-top > div > .ant-tabs-nav,
.ant-tabs-bottom > div > .ant-tabs-nav {
  margin-bottom: 24px;
}

.ant-tabs-tab + .ant-tabs-tab {
  margin-left: 8px;
}

.ant-tabs-tab {
  /* Auto Layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px;
  font-size: 16px;
  line-height: 16px;
  font-weight: 600;
  height: 40px;
  opacity: 0.8;
}

.ant-tabs-tab.ant-tabs-tab-active {
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0 0 24px rgba(26, 26, 26, 0.12);
  border-radius: 8px;
  opacity: 1;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: white;
}

.ant-tabs-tab-btn {
  display: flex;
  align-items: center;
}

span.live {
  width: 8px;
  height: 8px;
  border-radius: 5px;

  background: #00ffbd; /* @metaplex-primary-color */
  box-shadow: 0px 0px 12px #00ffbd; /* @metaplex-primary-color */

  /* Inside Auto Layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  margin-right: 12px;
}

/* Optional, different gutter size on mobile */
@media (max-width: 800px) {
  .masonry-grid {
    margin-left: -15px; /* gutter size offset */
  }

  .masonry-grid_column {
    padding-left: 15px; /* gutter size offset */
  }

  .masonry-grid_column > div {
    margin-bottom: 15px; /* space between items */
  }
}

.ant-card-body {
  padding: 0;
}

.ant-btn {
  line-height: 1.5715;
  position: relative;
  display: inline-block;
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
  background-image: none;
  border: 1px solid transparent;
  -webkit-box-shadow: 0 2px 0 rgb(0 0 0 / 2%);
  box-shadow: 0 2px 0 rgb(0 0 0 / 2%);
  cursor: pointer;
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  touch-action: manipulation;
  height: 32px;
  padding: 4px 15px;
  font-size: 14px;
  border-radius: 2px;
  color: rgba(255, 255, 255);
  border-color: #d9d9d9;
  background: #fff;
}

.tag {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 6px 10px;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  max-height: 32px;
  background: transparent;
  border-radius: 20px;
  margin: 0 5px;
  border-color: transparent;
}

.ant-tag {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  color: rgba(255, 255, 255);
  font-variant: tabular-nums;
  list-style: none;
  -moz-font-feature-settings: 'tnum', 'tnum';
  font-feature-settings: 'tnum', 'tnum';
  display: inline-block;
  height: auto;
  margin-right: 8px;
  padding: 0 7px;
  font-size: 12px;
  line-height: 20px;
  white-space: nowrap;
  background: #fafafa;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  opacity: 1;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
</style>
