import {AccountInfo} from "@solana/web3.js";
import {StringPublicKey} from "@metaplex-foundation/mpl-core";
import {deserializeAccount} from "@/views/shop/contexts/accounts/deserialize";
import {TokenAccount} from "@/views/shop/models";

export const TokenAccountParser = (
    pubKey: StringPublicKey,
    info: AccountInfo<Buffer>,
) => {
    // Sometimes a wrapped sol account gets closed, goes to 0 length,
    // triggers an update over wss which triggers this guy to get called
    // since your UI already logged that pubkey as a token account. Check for length.
    if (info.data.length > 0) {
        const buffer = Buffer.from(info.data);
        const data = deserializeAccount(buffer);

        const details = {
            pubkey: pubKey,
            account: {
                ...info,
            },
            info: data,
        } as TokenAccount;

        return details;
    }
};
