<template>
    <div class="index">
        <section id="home" class="banner_section section_gradiant_dark">
            <div id="banner_bg_effect" class="banner_effect"></div>
            <div class="container-fluid">
                <div class="row align-items-center">
                    <div class="offset-lg-2 col-lg-4 col-md-12 col-sm-12 order-lg-first text_md_center">
                        <div class="banner_text">
                            <h1 class="animate__animated animate__fadeInUp" data-animation="fadeInUp" data-animation-delay="1.5s">Spin <span>To</span> Win</h1>
                            <p class="animate__animated animate__fadeInUp" data-animation="fadeInUp" data-animation-delay="1.7s">
                                Spin to win a variety of prizes
                            </p>

                            <div class="row text-center">
                                <div class="col-4">
                                    <h4>Prizes</h4>
                                    <h5 class="st">LootBoxes & BLU</h5>
                                </div>
                                <div class="col-3">
                                    <h4>Play Limit</h4>
                                    <h5 class="st">Once a Day</h5>
                                </div>
                                <div class="col-5" v-if="details.min_bet > 0">
                                    <h4>Cost To Play</h4>
                                    <h5 class="st">{{ details.min_bet }} BLU</h5>
                                </div>
                            </div>

                            <div class="row mt-5">
                                <div class="col-12 text-break">
                                    <h6 class="contract">
                                        Contract: <a target="_blank" :href="`https://explorer.harmony.one/address/${contract_addr}`">{{ contract_addr }}</a>
                                    </h6>
                                </div>
                                <div class="col-12">
                                    <h6>VRF Provider: <a href="https://docs.harmony.one/home/general/technology/randomness">Harmony ONE</a></h6>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12 col-sm-12 order-last text-center">
                        <FortuneWheel :options="uniquePrizes" :state="dice.state" :result="dice.prize"></FortuneWheel>

                        <a target="_blank" v-if="last_result !== null" :href="`https://explorer.harmony.one/tx/${last_result.transactionHash}`">
                            <small>{{ last_result.transactionHash }}</small>
                        </a>
                    </div>
                </div>
            </div>
        </section>

        <div class="container mt-5">
            <div class="row">
                <div class="col-lg-7 col-md-12 text-center">
                    <FixedBet :bets_enabled="betting_enabled" :bet_amount="parseInt(details.min_bet)" @place-bet="play"></FixedBet>
                </div>

                <div class="col-lg-4 col-md-12 col-sm-12 offset-lg-1">
                    <div class="card">
                        <div class="card-body text-center">
                            <h3>Prizes</h3>
                            <table class="table">
                                <tbody>
                                    <tr class="prize" v-for="(prize, idx) in uniquePrizes" :key="idx">
                                        <td><img class="img-fluid prize-img" :src="prize.img" /></td>
                                        <td class="prize">
                                            <a target="_blank" :href="`https://explorer.harmony.one/tx/${prize.contractAddr}`">{{ prize.description }}</a>
                                        </td>
                                        <td>{{ ((prize.slots / 360) * 100).toFixed(2) }}%</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <h3 class="mt-4">Recent Transactions</h3>
                <div class="col-12 text-center mt-5" v-if="history.length === 0">
                    <i>Loading Contract History...</i>
                </div>

                <div class="col-12" v-for="(evt, k) in history" :key="k">
                    <div class="card mb-3">
                        <div class="card-body">
                            <div class="row">
                                <div class="col">
                                    <h4>{{ evt.event.toUpperCase() }}</h4>
                                    <small
                                        ><a target="_blank" :href="`https://explorer.harmony.one/tx/${evt.transactionHash}`">{{ evt.transactionHash }}</a>
                                    </small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <footer>
            <ResultModal :result="last_result" :prize="dice.result" v-show="showResultModal" @close="showResultModal = false"></ResultModal>
        </footer>
    </div>
</template>

<script>
import WheelOfFortuneContract from '/public/contracts/abi/wheel_of_fortune'
import Web3 from 'web3'
import Contract from 'web3-eth-contract'
import '@/assets/css/lounge.css'
import { Component, Vue } from 'vue-property-decorator'

@Component({
    components: {
        FortuneWheel: () => import('../../components/lounge/FortuneWheel.vue'),
        FixedBet: () => import('../../components/wallet/FixedBet.vue'),
        FortuneWheel: () => import('./WheelResultModal.vue'),
    },
})
export default class SpinToWin extends Vue {
    contract_addr = '0xEDFc09e655c5Fe87FF39ea2Ae49c585696e2dA0A'
    betting_enabled = false
    contract = null
    wsProvider = null
    contractListener = null
    account = {
        balance: 0.0,
    }
    details = {
        total_bets_placed: 'Loading',
        min_bet: 10,
        dice_sides: 'Loading',
        prizes: [
            {
                id: 5,
                contract_addr: '0xc16d4b07a42727836b565f2a3b1a9080273c0944',
                text: 'LootBox',
                description: 'Royal LootBox',
                img: 'https://app.babilu.online/static/collections/0x0/lootbox.png',
                prizeType: 0,
                is_win: true,
                slots: 45,
            },
            {
                id: 99,
                contract_addr: '0xc16d4b07a42727836b565f2a3b1a9080273c0944',
                text: 'Lose',
                description: 'Loser',
                img: '',
                prizeType: 0,
                is_win: false,
                slots: 45,
            },
            {
                id: 1,
                contract_addr: '0xc16d4b07a42727836b565f2a3b1a9080273c0944',
                text: '100 BLU',
                description: 'BLU Tokens',
                img: 'https://cryptologos.cc/logos/harmony-one-logo.svg?v=014',
                prizeType: 0,
                is_win: true,
                slots: 45,
            },
            {
                id: 99,
                contract_addr: '0xc16d4b07a42727836b565f2a3b1a9080273c0944',
                text: 'Lose',
                description: 'Loser',
                img: '',
                prizeType: 0,
                is_win: false,
                slots: 45,
            },
            {
                id: 2,
                contract_addr: '0xc16d4b07a42727836b565f2a3b1a9080273c0944',
                text: '20 ONE',
                description: 'ONE Tokens',
                img: 'https://cryptologos.cc/logos/harmony-one-logo.svg?v=014',
                prizeType: 0,
                is_win: true,
                slots: 45,
            },
            {
                id: 99,
                contract_addr: '0xc16d4b07a42727836b565f2a3b1a9080273c0944',
                text: 'Lose',
                description: 'Loser',
                img: '',
                prizeType: 0,
                is_win: false,
                slots: 45,
            },
            {
                id: 6,
                contract_addr: '0xc16d4b07a42727836b565f2a3b1a9080273c0944',
                text: 'LootBox',
                description: 'Spinner LootBox',
                img: 'https://app.babilu.online/static/collections/0x0/lootbox.png',
                prizeType: 0,
                is_win: true,
                slots: 45,
            },
            {
                id: 99,
                contract_addr: '0xc16d4b07a42727836b565f2a3b1a9080273c0944',
                text: 'Lose',
                description: 'Loser',
                img: '',
                prizeType: 0,
                is_win: false,
                slots: 45,
            },
        ], //TODO
    }
    availablePrizes = {
        '0x289538684cceb76b7735559e4760b4688aCF2386': {
            text: 'BLU',
            description: 'BLU Tokens',
            img: '',
            prizeType: 0,
        },
    }
    eventsHandled = {}
    history = []
    betToPlace = 1.0
    showResultModal = false
    dice = {
        state: 0,
        prize: null,
        roll: -1,
    }
    last_result = null //Winner/Loser evt
    gas_limit = 210000
    harmonyProvider = 'wss://ws.s0.t.hmny.io'
    web3 = null
    get uniquePrizes() {
        let un = []
        let unk = {}
        let usedSlots = 0

        for (let i = 0; i < this.details.prizes.length; i++) {
            let prize = this.details.prizes[i]
            if (!prize.isWin) continue

            usedSlots++

            if (unk[prize.id]) {
                unk[prize.id].slots = unk[prize.id].slots + 1
                continue
            }

            //Combine the prize with our metadata from available_prizes
            unk[prize.id] = Object.assign({}, prize, { slots: 1, ...this.availablePrizes[prize.contractAddr] })
        }

        const keys = Object.keys(unk)
        for (let i = 0; i < keys; i++) {
            un.push(unk[keys[i]])
        }

        un.push({
            id: 0,
            isWin: false,
            img: '',
            prizeType: -1,
            contractAddr: 0x0,
            text: 'Loser',
            description: 'Loser',
            slots: 360 - usedSlots,
        })

        return un
    }
    play(amountToBet) {
        const wallet = this.$store.state.wallet_addr
        console.log(`PLAY: ${wallet} - ${amountToBet}`)

        console.log('Current provider:', this.web3.currentProvider)

        this.web3.eth.getGasPrice().then(gasPrice => {
            console.log('Gas Cost: ', gasPrice)

            this.spinDice()

            let payload = {
                from: wallet,
                gasPrice: gasPrice,
                gasLimit: this.gas_limit,
            }

            this.listenContractEvents()

            let promise
            if (this.details.min_bet > 0) {
                payload.value = Web3.utils.toWei('' + this.details.min_bet, 'ether')
                promise = this.contract.methods.payToPlay()
            } else {
                promise = this.contract.methods.freeToPlay()
            }

            console.log('Initiating Web3 Transaction', payload)
            promise
                .send(payload)
                .then(res => {
                    console.log('Result: ', res)
                    const keys = Object.keys(res.events)
                    for (let i = 0; i < keys.length; i++) {
                        console.log('XHR Result Event: ', res.events[keys[i]])
                        this.handleEvent(res.events[keys[i]])
                    }
                })
                .catch(err => {
                    console.log('Err: ', err)
                    this.dice.state = 0 //Reset state (Idle)
                })
        })
    }

    spinDice() {
        this.dice.roll = -1 //Reset dice
        this.dice.prize = null //Reset dice
        this.dice.state = 1 //Start spin
    }

    getRandomInt(min, max) {
        return Math.floor(Math.random() * (max - min + 1) + min)
    }

    listenContractEvents() {
        if (this.wsProvider !== null) {
            console.log('WS prov', this.wsProvider)
            this.connection.close()
            this.wsProvider.disconnect()
        }

        this.wsProvider = new Web3.providers.WebsocketProvider(this.harmonyProvider)

        console.log('WS prov', this.wsProvider)
        this.contractListener.setProvider(this.wsProvider)

        this.wsProvider.on(this.wsProvider.CONNECT, () => {
            console.log('WS Connected listening for contract events')
            this.contractListener.events
                .allEvents()
                .on('data', event => {
                    console.log('data event in', event)
                    this.handleEvent(event)
                })
                .on('error', console.error)
        })
    }

    spinResult(res) {
        console.log('spinResult', res)
        this.diceWinner(res) //TODO Win/Lose
    }

    diceRolled(res) {
        console.log('diceRolled', res)
        this.dice.roll = parseInt(res.returnValues.result)

        console.log('diceRolled Prize', {
            raw: Object(res.returnValues.prize),
            typ: typeof res.returnValues.prize,
        })
        this.dice.prize = Object(res.returnValues.prize)
        this.dice.state = 3
    }

    onLoser(res) {
        console.log('onLoser', res)
        this.last_result = res
        this.showResultModal = true
        this.history.unshift(res)
    }

    onWinner(res) {
        console.log('onWinner', res)
        this.last_result = res
        this.showResultModal = true
        this.history.unshift(res)
    }

    handleEvent(event) {
        //Only handle event onceResyul
        const evtKey = `${event.transactionHash}-${event.blockHash}-${event.id}`
        if (this.eventsHandled[evtKey]) {
            console.log('skipping event', event)
            return
        }
        this.eventsHandled[evtKey] = true

        event = Array.isArray(event) ? Object.fromEntries(event) : event

        console.log('Handling Event: ', event)
        switch (event.event) {
            case 'SpinResult':
                this.diceRolled(event)
                return
            case 'Loser':
                this.onLoser(event)
                return
            case 'Winner':
                this.onWinner(event)
                return
        }
    }

    async balance() {
        const accounts = await this.web3.eth.getAccounts()
        const balance = await this.web3.eth.getBalance(accounts[0])
        console.log('Balance: ', balance)
        this.account.balance = balance
    }

    async getHistory() {
        const latestBlock = await this.web3.eth.getBlockNumber()
        console.log('Last block', latestBlock)

        console.log('Getting past events')
        this.contract
            .getPastEvents('allEvents', {
                fromBlock: latestBlock - 1000,
                toBlock: 'latest',
            })
            .then(this.onEventHistory)
    }

    onEventHistory(events) {
        console.log('events', events) // same results as the optional callback above

        this.history = []
        for (let e in events.reverse()) {
            if (events[e].event === 'SpinResult') continue
            this.history.push(events[e])
        }
    }

    setupContract() {
        if (!window.web3) {
            console.log('Web3 not detected')
            return false
        }

        //Setup provider (XHR)
        this.web3 = new Web3(window.web3)
        console.log('Setting contract provider', window.web3)
        this.contract.setProvider(window.web3)

        //Load details
        this.loadContractDetails()
        this.getHistory()

        return true
    }

    toOne(num) {
        if (num === undefined) return 'N/A'

        return Web3.utils.fromWei('' + num, 'ether')
    }

    loadContractDetails() {
        this.contract.methods
            .getAmountToPlay()
            .call()
            .then(res => {
                console.log('getAmountToPlay', res)
                this.details.max_bet = this.toOne(res)
                this.details.min_bet = this.toOne(res)
            })

        this.contract.methods
            .getAllPrizes()
            .call()
            .then(res => {
                console.log('getAllPrizes', res)
                this.details.prizes = res
            })
    }
    mounted() {
        this.contract = new Contract(WheelOfFortuneContract, this.contract_addr)
        this.contractListener = new Contract(WheelOfFortuneContract, this.contract_addr)

        if (this.$store.state.wallet_connected) this.betting_enabled = this.setupContract()

        this.$store.subscribe(mutation => {
            if (mutation.type === 'set_wallet_connected') {
                this.betting_enabled = this.setupContract()
            }
        })
    }
}
</script>

<style scoped>
.st {
    color: #26b6d4;
}

.prize {
    line-height: 50px;
}

.prize-img {
    max-height: 50px;
}
</style>
