import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { PublicKey } from '@solana/web3.js';
import { createAuctionManager } from '@/views/shop/actions/auctions';

class State {}

const getters: GetterTree<State, any> = {};
const mutations = <MutationTree<State>>{};
const actions = <ActionTree<State, any>>{
  async listingInstantSale(State, payload) {
    const { dtoNFT, auctionSettings, wallet, callbackProgress } = payload;
    const connection = State.rootState.connectionModule.connection;
    const store = new PublicKey(State.rootState.walletModule.storeId);
    return new Promise<boolean>(resolve => {
      createAuctionManager(
        connection,
        wallet,
        store,
        auctionSettings,
        dtoNFT,
        State.rootState.marketplaceAccountModule.metaState.storeIndexer,
        callbackProgress,
      )
        .then(() => {
          resolve(true);
        })
        .catch(e => {
          console.error(e);
          resolve(false);
        });
    });
  },
};

const MarketplaceAuctionModule = {
  namespaced: true,
  getters,
  state: new State(),
  mutations,
  actions,
};

export default MarketplaceAuctionModule;
