<template>
  <div>
    <div class="container-fluid mt-5 p-4">
      <div class="row">
        <div class="col-md-12 img_container">
          <router-link to="/profile/update" class="btn-none btn-sm">
            <img :src="profile.avatar_url" class="img-fluid" alt="player avatar" />
          </router-link>
        </div>

        <div class="col-12">
          <h3>User Name: {{ profile.name }}</h3>
          <h6>Wallet Address: {{ profile.wallet_addr }}</h6>
        </div>
      </div>
    </div>

    <div class="custom-tab">
      <div class="m-4">
        <nav class="nav nav-tabs">
          <a
            href="#"
            class="nav-item nav-link"
            :class="activeTab == 1 ? 'active' : ''"
            @click="slectTab(1)"
          >
            <i class="fas fa-folder-plus"></i> Created
          </a>
          <a
            href="#"
            class="nav-item nav-link"
            :class="activeTab == 2 ? 'active' : ''"
            @click="slectTab(2)"
          >
            <i class="fas fa-broom"></i> Collected
          </a>
          <a
            href="#"
            class="nav-item nav-link"
            :class="activeTab == 3 ? 'active' : ''"
            @click="slectTab(3)"
          >
            <i class="fas fa-heart"></i> Favorited
          </a>
          <a
            href="#"
            class="nav-item nav-link"
            :class="activeTab == 4 ? 'active' : ''"
            @click="slectTab(4)"
          >
            <i class="fas fa-history"></i> Activity
          </a>
          <a
            href="#"
            class="nav-item nav-link"
            :class="activeTab == 5 ? 'active' : ''"
            @click="slectTab(5)"
          >
            <i class="fas fa-tag"> </i> Offers
          </a>
        </nav>
      </div>

      <div class="row">
        <div v-if="activeTab == 1 || activeTab == 2 || activeTab == 3" class="col-md-12">
          <ShowShopProfileItem />
        </div>

        <div v-if="activeTab == 4" class="col-md-12">
          <div class="item-activity">
            <table class="table">
              <thead>
                <tr>
                  <th>Event</th>
                  <th>Price</th>
                  <th>From</th>
                  <th>To</th>
                  <th>Date</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>List</td>
                  <td>ETH 2</td>
                  <td>burgesa</td>
                  <td>AleyArt</td>
                  <td>3 days ago</td>
                </tr>

                <tr>
                  <td>Transfer</td>
                  <td>ETH 5</td>
                  <td>FiFiRongDoubleAlbum</td>
                  <td>SellBel</td>
                  <td>7 days ago</td>
                </tr>

                <tr>
                  <td>Sale</td>
                  <td>ETH 7</td>
                  <td>NullAddress</td>
                  <td>Thomas</td>
                  <td>1 month</td>
                </tr>

                <tr>
                  <td>Minted</td>
                  <td>ETH 55</td>
                  <td>FiFiRongDoubleAlbum</td>
                  <td>Anthony</td>
                  <td>15 days ago</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div v-if="activeTab == 5" class="col-md-12">
          <div class="form-check-inline col-md-2">
            <input
              class="form-check-input col-2"
              type="radio"
              name="exampleRadios"
              id="exampleRadios1"
              value="option1"
              checked
            />
            <label class="form-check-label col-8" for="exampleRadios1">
              Offer Received
            </label>
          </div>

          <div class="form-check-inline col-md-2">
            <input
              class="form-check-input col-2"
              type="radio"
              name="exampleRadios"
              id="exampleRadios2"
              value="option2"
            />
            <label class="form-check-label col-8" for="exampleRadios2">
              Offer Made
            </label>
          </div>

          <div class="item-activity">
            <table class="table">
              <thead>
                <tr>
                  <th>Item</th>
                  <th>Unit Price</th>
                  <th>USD Unit Price</th>
                  <th>Floor Difference</th>
                  <th>From</th>
                  <th>Expiration</th>
                  <th>Made</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>List</td>
                  <td>ETH 2</td>
                  <td>$2.892</td>
                  <td>burgesa</td>
                  <td>AleyArt</td>
                  <td>Bandaico</td>
                  <td>Campuchino</td>
                  <td>active</td>
                </tr>

                <tr>
                  <td>List</td>
                  <td>ETH 2</td>
                  <td>$2.892</td>
                  <td>burgesa</td>
                  <td>AleyArt</td>
                  <td>Bandaico</td>
                  <td>Campuchino</td>
                  <td>active</td>
                </tr>

                <tr>
                  <td>List</td>
                  <td>ETH 2</td>
                  <td>$2.892</td>
                  <td>burgesa</td>
                  <td>AleyArt</td>
                  <td>Bandaico</td>
                  <td>Campuchino</td>
                  <td>active</td>
                </tr>

                <tr>
                  <td>List</td>
                  <td>ETH 2</td>
                  <td>$2.892</td>
                  <td>burgesa</td>
                  <td>AleyArt</td>
                  <td>Bandaico</td>
                  <td>Campuchino</td>
                  <td>active</td>
                </tr>

                <tr>
                  <td>List</td>
                  <td>ETH 2</td>
                  <td>$2.892</td>
                  <td>burgesa</td>
                  <td>AleyArt</td>
                  <td>Bandaico</td>
                  <td>Campuchino</td>
                  <td>active</td>
                </tr>

                <tr>
                  <td>List</td>
                  <td>ETH 2</td>
                  <td>$2.892</td>
                  <td>burgesa</td>
                  <td>AleyArt</td>
                  <td>Bandaico</td>
                  <td>Campuchino</td>
                  <td>active</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import User from '@/api/user';
import { Component, Vue } from 'vue-property-decorator';
import { getPhantomWallet } from '@solana/wallet-adapter-wallets';
import { NFTGet } from '@/common/NFTget';
import { mapState } from 'vuex';

@Component({
  name: 'ShopProfile',
  components: {
    ShowShopProfileItem: () => import('../shop/ShowShopPrifileItem.vue'),
  },
  computed: {
    ...mapState('walletModule', ['wallet_addr']),
  },
})
export default class ShopProfile extends Vue {
  search = '';
  activeTab = 1;
  user = '';
  profile = {};
  listTab = {
    activeTab: 1,
    tabs: [
      { label: 'Collected' },
      { label: 'Created' },
      { label: 'Favorited' },
      { label: 'Activity' },
      { label: 'Offers' },
    ],
  };

  // async create

  slectTab(input) {
    this.activeTab = input;
  }

  getProfile() {
    User.show(this.user).then(resp => {
      this.profile = resp.data;
      if (!this.profile.wallet_addr) {
        this.profile.wallet_addr = this.wallet_addr;
      }
    });
  }

  async testConnectMetaplex() {
    const gottenWallet = getPhantomWallet();
    const connectedAdapter = gottenWallet.adapter();

    connectedAdapter
      .connect()
      .then(() => {
        this.$store.commit(
          'walletModule/set_wallet_public_key',
          connectedAdapter.publicKey,
        );
      })
      .catch(err => {
        console.log(err);
      });

    const params = {
      owner: connectedAdapter.publicKey,
      creator: undefined,
      mint: undefined,
      updateAuthority: undefined,
    };

    NFTGet(params)
      .then(rs => {
        console.log(rs);
      })
      .catch(err => {
        console.log(err);
      });
  }

  beforeMount() {
    this.user = this.$store.state.wallet_addr;
  }

  mounted() {
    this.getProfile();
  }
}
</script>

<style scoped>
.fill-height {
  height: 100%;
}

.nav {
  justify-content: center !important;
}

.item-activity {
  color: white;
  background: #0e0e0f;
  border-radius: 8px;
  padding: 2%;

  transition: all 0.3s ease-in-out;
}

.form-check-inline.col-md-2 {
  color: white;
}

#exampleRadios1 {
  height: 25px;
}

#exampleRadios2 {
  height: 25px;
}
</style>
