import {
  InstantSaleSteps,
  LimitedSaleSteps,
} from '@/views/shop/components/sell-nft/sellSteps';

export const SellCategories = {
  instant: {
    type: 'instant',
    text: 'Instant Sale',
    desc: 'At a fixed price, sell a single Master NFT or copies of it',
    subSteps: InstantSaleSteps,
  },
  limited: {
    type: 'limited',
    text: 'Limited Edition',
    desc: 'Sell a limited copy or copies of a single Master NFT',
    subSteps: LimitedSaleSteps,
  },
  open: {
    type: 'open',
    text: 'Open Edition',
    desc: 'Sell unlimited copies of a single Master NFT',
  },
  tiered: {
    type: 'tiered',
    text: 'Tiered Auction',
    desc: 'Participants get unique rewards based on their leaderboard rank',
  },
  exist: {
    type: 'exist',
    text: 'Sell an Existing Item',
    desc: 'Sell an existing item in your NFT collection, including Master NFTs',
  },
};
