<template>
  <video
      :src="animationUrl"
      controls
      playsInline
      controlsList="nodownload"
  />
</template>

<script>
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({
  name: 'VideoContent',
})
export default class VideoContent extends Vue {
  @Prop(String) uri;
  @Prop(String) animationUrl;
}
</script>

<style scoped>
video {
  width: 100%;
}
</style>
