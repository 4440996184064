<template>
  <div id="sidenav" tabindex="1" class="sidenav" :style="{ width: currentWidth }" @mouseenter="toggleNav"
       @mouseleave="toggleNav">
    <div class="">
      <img id="logo" class="logo" src="@/assets/images/alpha_black.png"/>
    </div>
    <div class="links">
      <ul style="padding-left: 0">
        <NavItem :show_text="expanded" idx="0" title="Dashboard" src="/images/nav/icon-ft-1.png" href="/"></NavItem>
        <NavItem :show_text="expanded" idx="1" title="Oasis" src="/images/nav/icon-ft-6.png" href="/oasis"></NavItem>
        <NavItem :show_text="expanded" idx="2" title="Land Map" src="/images/nav/icon-ft-4.png" href="/map"></NavItem>
        <NavItem :show_text="expanded" idx="3" title="NFT Import" src="/images/nav/icon-ft-2.png"
                 href="/nft-import"></NavItem>
        <NavItem :show_text="expanded" idx="4" title="Social" src="/images/nav/icon-ft-3.png" href="/social"></NavItem>
        <NavItem :show_text="expanded" idx="5" title="Auction House" src="/images/nav/icon-ft-5.png"
                 href="/marketplace"></NavItem>
        <NavItem :show_text="expanded" idx="5" title="Shop" src="/images/nav/icon-ft-5.png" href="/shop"></NavItem>
        <NavItem :show_text="expanded" idx="6" title="Settings" src="/images/nav/icon-ft-1.png"
                 href="/settings"></NavItem>
      </ul>
    </div>

    <div class="auth m-1 mt-5">
      <router-link to="/profile" v-if="connected">
        <div class="user row">
          <div style="padding-left: 0" class="user_avatar">
            <img class=" ml-3 img-fluid" :src="user.avatar_url" alt=""/>
          </div>
          <div class="user_detail col-7 p-0 ml-1 wow fadeIn"
               data-animation-delay="0.3s"
               v-if="expanded"

          >
            <h5 class="user_name">{{ user.name }}</h5>
            <h5 class="user_wallet">{{ user.balance }} ABMT</h5>
          </div>
        </div>
      </router-link>
      <div v-if="expanded">
        <button v-if="connected" class="btn btn-block btn-primary mt-2 wow fadeIn" data-animation-delay="0.3s">
          {{ user.wallet_addr }}
        </button>
        <Phantom @connected="onConnect" v-if="!connected" class="btn btn-primary btn-block mt-2 wow fadeIn"></Phantom>
      </div>
    </div>
  </div>
</template>

<script>
import User from '../../src/api/user'
import {Component, Vue} from 'vue-property-decorator'

@Component({
  name: 'Sidenav',
  components: {
    NavItem: () => import('./NavItem.vue'),
    Phantom: () => import('./wallet/Phantom.vue'),
  },
})
export default class Sidenav extends Vue {
  user = {
    name: 'TheCloakdOne',
    balance: '0.00',
    wallet_addr: '0x1b47f49a325EFC79ad3a8d780b77Ce74bbF9a545',
    avatar_url:
        'https://lh3.googleusercontent.com/wl09kAQ8Zr_9rMmJM6hquCMWctYAgiQIOCnCvuHkabkaUKoC2hHq_qR2_5Re_Kq9j5ayjkqUnI19sHjKwvD8j8lyfZcUP_Meq8Q9=w50',
  }
  connected = false
  expanded = false

  toggleNav() {
    this.expanded = !this.expanded
  }

  onConnect(success) {
    this.connected = success
    this.getProfile()
  }

  async getProfile() {
    const user = await User.show(this.$store.state.wallet_addr)

    this.user = user.data
    this.user.balance = '0.00'
  }

  get currentWidth() {
    return this.expanded ? '200px' : '70px'
  }
}
</script>

<style lang="scss" scoped>
.sidenav {
  position: fixed;
  top: 0;
  left: 0;
  /*width: 200px;*/
  background: #0e0e0f;
  /*background: linear-gradient(90deg, rgba(22, 99, 117, 1) 0%, rgba(14, 64, 76, 1) 100%);*/
  height: 100vh;
  max-height: 100vh;
  border-right: 2px solid #0a9fb9;
  transition: width 0.3s ease-out;
  z-index: 999;
}

.auth {
  position: absolute;
  bottom: 5px;
  left: 1px;
  width: 185px;
  button.btn.btn-block.btn-primary.mt-2.wow.fadeIn {
    text-overflow: ellipsis;
    width: 100%;
  }
}

.logo {
  cursor: pointer;
}

.user {
  padding-left: 15px ;
  &_avatar {
     margin-bottom: 10px;
   }
}

.user_avatar,
.logo {
  max-height: 50px;
  border-radius: 8px;
}

.user_detail {
  position: relative;
  display: block;
  overflow: hidden;
}

.user_detail h5 {
  color: white;
}

.btn-block {
  overflow: hidden;
}

h1 {
  color: white;
  text-align: center;
  font-size: 1.5rem;
  margin-bottom: 20px;
}
.links ul:first-child {
  border-top: 2px solid #0a9fb9;
}
</style>
