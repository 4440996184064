
























import { Vue, Component, Prop } from 'vue-property-decorator';
import { ParsedAccount } from '@/views/shop/contexts/accounts/types';
import { AuctionData, AuctionDataExtended } from '@metaplex-foundation/mpl-auction';
import { formatPriceNumber, formatTokenAmount } from '@/views/shop/utils';
import { mapGetters } from 'vuex';

@Component({
  name: 'AuctionCard',
  components: {
    Icon: () => import('@/components/Icon.vue'),
  },
  computed: {
    ...mapGetters('walletModule', ['getCurrentExchangeRate']),
  },
})
export default class AuctionCard extends Vue {
  getCurrentExchangeRate: number | undefined;

  @Prop({ type: Object, required: true }) auction:
    | (ParsedAccount<AuctionData> &
        ParsedAccount<AuctionDataExtended> & {
          externalMetadata: { [key: string]: any };
        })
    | undefined;

  get price() {
    if (!this.auction?.info?.instantSalePrice?.toNumber) {
      return 0;
    }

    return formatTokenAmount({
      account: this.auction.info.instantSalePrice.toNumber(),
      precision: 0,
    });
  }

  get priceInUSD() {
    return formatPriceNumber.format(
      Number(this.price) * (this.getCurrentExchangeRate || Number.NaN),
    );
  }
}
