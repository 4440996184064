import Vue from 'vue';

Vue.mixin({
  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },

    resolveState({ errors, pending, valid }) {
      if (errors[0]) {
        return 'error';
      }

      if (pending) {
        return 'validating';
      }

      if (valid) {
        return 'success';
      }

      return '';
    },
  },
});
