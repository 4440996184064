<template>
  <div>
    <audio :src="animationUrl" controls />
    <b-img-lazy :src="uri" alt="" />
  </div>
</template>

<script>
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({
  name: 'AudioContent',
})
export default class AudioContent extends Vue {
  @Prop(String) uri;
  @Prop(String) animationUrl;
}
</script>

<style scoped>
audio {
  width: 100%;
  margin-bottom: 1rem;
}
</style>
