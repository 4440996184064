<template>
  <div class="w-100">
    <a-steps class="mt-3" direction="vertical" size="small" :current="step - 1">
      <template v-for="(stage, idx) in stages">
        <div
          v-if="step - 1 === idx"
          class="step-create-nft ant-steps-item ant-steps-item-wait"
          style="color: white"
          :key="idx + '-loading-stage'"
        >
          <div class="ant-steps-item-container">
            <div class="ant-steps-item-tail"></div>
            <div class="ant-steps-item-content d-flex">
              <div class="loader d-inline-block" />
              <div class="ant-steps-item-title mx-3">{{ stage }}</div>
            </div>
          </div>
        </div>
        <a-step
          v-else
          class="step-create-nft"
          :title="stage"
          :key="idx + '-normal-stage'"
          style="color: white"
        >
        </a-step>
      </template>
    </a-steps>
  </div>
</template>

<script>
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({
  name: 'ProgressLoading',
})
export default class ProgressLoading extends Vue {
  @Prop(Array) stages;
  @Prop({ type: Number, default: 1 }) step;
}
</script>

<style scoped lang="scss">
@import './src/assets/css/components/vertical-ant-progress.scss';

::v-deep {
  .ant-steps-vertical {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    width: fit-content;
  }
}

.loader {
  font-size: 10px;
  text-indent: -9999em;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: #212121;
  background: -moz-linear-gradient(left, white 10%, rgba(255, 255, 255, 0) 42%);
  background: -webkit-linear-gradient(left, white 10%, rgba(255, 255, 255, 0) 42%);
  background: -o-linear-gradient(left, white 10%, rgba(255, 255, 255, 0) 42%);
  background: -ms-linear-gradient(left, white 10%, rgba(255, 255, 255, 0) 42%);
  background: linear-gradient(to right, white 10%, rgba(255, 255, 255, 0) 42%);
  position: relative;
  -webkit-animation: load3 1.4s infinite linear;
  animation: load3 1.4s infinite linear;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  transition: 0.3s;
}

.loader:before {
  width: 50%;
  height: 50%;
  background: white;
  border-radius: 100% 0 0 0;
  position: absolute;
  top: 0;
  left: 0;
  content: '';
  transition: 0.3s;
}

.loader:after {
  background: #4d217a;
  width: 75%;
  height: 75%;
  border-radius: 50%;
  content: '';
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  transition: 0.3s;
}

@-webkit-keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>
