<template>
  <div class="html-content__container" :class="[{ loaded }]">
    <div v-if="!loaded" class="col-12 d-flex align-items-center justify-content-center">
      <a-spin></a-spin>
    </div>
    <iframe
      v-show="loaded"
      class="w-100"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      sandbox="allow-scripts"
      frameBorder="0"
      :src="animationUrl"
      :style="{ height: !loaded ? '0' : '100%' }"
      @load="loaded = true"
    ></iframe>
  </div>
</template>

<script>
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({
  name: 'HTMLContent',
})
export default class HTMLContent extends Vue {
  @Prop(String) uri;
  @Prop(String) animationUrl;

  loaded = false;
}
</script>

<style scoped>
.html-content__container,
.html-content__container iframe {
  min-height: 400px;
}

.html-content__container.loaded {
  background-color: #e7e7e7;
}
</style>
