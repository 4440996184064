import { GetterTree, MutationTree, ActionTree } from 'vuex';
import { PublicKey } from '@solana/web3.js';
import { Coingecko, Currency } from '@metaplex/js';

class State {
  storeId: string = '';
  wallet_connected: boolean = false;
  wallet_addr: string = '';
  wallet_public_key: object = {};
  wallet_balance: { [key: string]: any } = {
    sol: '',
    usd: '',
    rate: Number.NaN,
    cachedAt: 0,
    interval: null,
  };
}

const getters: GetterTree<State, any> = {
  getCurrentExchangeRate(State) {
    return State.wallet_balance.rate;
  },
};
const mutations = <MutationTree<State>>{
  set_store_id(State, id) {
    State.storeId = id;
  },

  set_wallet_connected(State, connected) {
    State.wallet_connected = connected;
  },

  set_wallet_addr(State, addr) {
    State.wallet_addr = addr;
  },

  set_wallet_public_key(State, publicKey) {
    State.wallet_public_key = publicKey;
  },
};
const actions = <ActionTree<State, any>>{
  async getCurrentWalletBalance(State) {
    if (State.state.wallet_balance.cachedAt > new Date().getTime() - 30 * 1000) {
      return State.state.wallet_balance;
    }
    const connection = State.rootState.connectionModule.connection;
    const [rs, balance] = await Promise.all([
      new Coingecko().getRate([Currency.SOL], Currency.USD),
      connection.getBalance(new PublicKey(State.state.wallet_addr)),
    ]);
    const sol = balance / 1000000000;
    State.state.wallet_balance.sol = sol;
    State.state.wallet_balance.rate = rs?.[0]?.rate || Number.NaN;
    State.state.wallet_balance.usd = rs?.[0]?.rate * sol;
    State.state.wallet_balance.cachedAt = new Date().getTime();
    if (State.state.wallet_balance.interval) {
      clearInterval(State.state.wallet_balance.interval);
      State.state.wallet_balance.interval = null;
    }

    State.state.wallet_balance.interval = setInterval(() => {
      State.dispatch('getCurrentWalletBalance');
    }, 300 * 1000);
  },
};

const WalletModule = {
  namespaced: true,
  getters,
  state: new State(),
  mutations,
  actions,
};

export default WalletModule;
