













































































































































































































































































































































































































































import { mapActions } from 'vuex';
import { Component, Watch } from 'vue-property-decorator';
import { getPhantomWallet } from '@solana/wallet-adapter-wallets';
// @ts-ignore
import imgDefault from '@/assets/images/imgDefault/imgDefault.png';
import { alphabatemUpload, deleteFile, Progresses } from '@/common/upload';
import { NFTMintMaster } from '@/common/NFTmint';
import { IMintResult } from '@/common/helpers/types';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { FileCategories } from '@/views/shop/components/create-nft/files';
import { getLast } from '@/common/arrays';
import ProgressLoadingMixins from '@/mixins/progressLoadingMixins';

@Component({
  name: 'CreateNFT',
  components: {
    ProgressLoading: () => import('@/components/ProgressLoading.vue'),
    BaseUpload: () => import('./BaseUpload.vue'),
    ValidationObserver,
    ValidationProvider,
  },
  methods: {
    ...mapActions('metaplexNFTModule', ['resetCacheList']),
  },
})
export default class CreateNFT extends ProgressLoadingMixins {
  inputValue = 100;
  max_step = 5;
  currentStep = 1;

  categories = FileCategories;
  // Modal
  ModalText = 'Content of the modal';
  visible = false;
  confirmLoading = false;
  creatorAdder = '';

  // Royalty Percentage
  royaltyPercentage = 0;

  formData: any = {
    name: '',
    symbol: '',
    description: '',
    seller_fee_basis_points: 0,
    maxSupply: 0,
    attributes: [],
    collection: [],
  };
  imgDefault: any = imgDefault;
  file: any = null;
  clearFile: number = 0;
  mintResult = {} as IMintResult;
  loading = false;
  typeSelected = '';
  linkFile = null;

  imageThumb = null;

  created() {
    this.setupProgresses(Progresses);
  }

  get Step2Ref() {
    return this.$refs.step2 as any;
  }

  get Step3Ref() {
    return this.$refs.step3 as any;
  }

  @Watch('file')
  onChangeFile() {
    this.Step2Ref.reset();
  }

  get progress() {
    return Math.round(100 / this.max_step) * this.currentStep;
  }

  get getListFileExt(): string {
    if (!this.typeSelected || !(this.typeSelected in this.categories)) {
      return '';
    }

    //@ts-ignore
    return this.getListExt(this.categories[this.typeSelected].accept);
  }

  get blobCoverImage() {
    if (!this.imageThumb) return null;
    return URL.createObjectURL(this.imageThumb);
  }

  get blobPreviewImage() {
    if (this.typeSelected !== 'image' || !this.file) return null;
    return URL.createObjectURL(this.file);
  }

  getListExt(arr: []) {
    const list = [] as Array<string>;
    arr.forEach((ext: string) => {
      ext = ext.replaceAll('.', '').toUpperCase();
      list.push(ext);
    });

    return list.join(', ');
  }

  async onSubmit() {
    this.loading = true;
    this.callbackProgress(Progresses.start.type);
    const walletAddress = this.$store.state.walletModule.wallet_addr;
    const { collection, ...metaData } = this.formData;

    let metadataUrl;
    // step 1: upload metaData

    if (this.imageThumb) {
      const [metadata] = await alphabatemUpload(
        this.formData.collection,
        metaData,
        this.file,
        this.typeSelected,
        walletAddress,
        this.imageThumb,
        this.callbackProgress,
      );
      metadataUrl = metadata;
    } else {
      const [metadata] = await alphabatemUpload(
        this.formData.collection,
        metaData,
        this.file,
        this.typeSelected,
        walletAddress,
        null,
        this.callbackProgress,
      );
      metadataUrl = metadata;
    }

    // Start mint NFT
    return this.mintNewMaster(metadataUrl);
  }

  async mintNewMaster(uri: any) {
    this.callbackProgress(Progresses.authenticate.type);
    //@ts-ignore
    const connected = await window.solana?.connect().catch(() => {
      return Promise.resolve();
    });
    if (!connected) {
      this.$notification.error({
        message: 'Login failed',
        description: 'You need to login before signing to transaction.',
      });
      this.deleteFiles(uri.replace(/"/gi, ''));
      this.loading = false;
      return;
    }

    const connectedAdapter = getPhantomWallet().adapter();
    connectedAdapter.connect().catch(() => {});

    this.callbackProgress(Progresses.approve.type);
    NFTMintMaster(connectedAdapter as any, uri.replace(/"/gi, ''), 0)
      .then(async result => {
        this.mintResult = result as IMintResult;
        //@ts-ignore
        this.resetCacheList();
        return this.$router.push('/shop/artworks');
      })
      .catch(e => {
        if (e.message && e.message.includes('User rejected the request')) {
          this.$notification.error({
            message: 'Rejected',
            description: 'Your transaction was rejected.',
          });
        }
        this.deleteFiles(uri.replace(/"/gi, ''));
        this.loading = false;
      });
  }

  async deleteFiles(uri: string) {
    const data = await fetch(uri).then(resp => {
      return resp.json();
    });
    [data.image, ...(data.properties?.files?.map((f: any) => f.uri) || [])].forEach(
      file => {
        try {
          const path = new URL(file);
          deleteFile(
            getLast(path.pathname.split('/')) || '',
            this.$store.state.walletModule.wallet_addr,
          );
        } catch (_) {}
      },
    );
  }

  onSelectType(type: string) {
    this.typeSelected = type;
    this.onClickNext();
  }

  backToStep(step: number) {
    if (step < this.currentStep) {
      this.currentStep = step;
    }
  }

  async onClickNext() {
    if (this.currentStep === 3) {
      const isValid = await this.Step3Ref.validate();
      if (!isValid) return;
    }
    this.currentStep++;
  }

  onClickBack() {
    this.currentStep--;
    if (this.currentStep === 1) {
      this.file = null;
      this.imageThumb = null;
    }
  }

  showModal() {
    this.visible = true;
  }

  handleOk(e: any) {
    this.ModalText = 'The modal will be closed after two seconds';
    this.confirmLoading = true;
    setTimeout(() => {
      this.visible = false;
      this.confirmLoading = false;
    }, 2000);
  }

  handleCancel() {
    this.visible = false;
  }

  onAddAttributes() {
    const data = {
      trait_type: '',
      value: '',
      display_type: '',
    };
    this.formData.attributes.push(data);
  }

  onDeleteAttribute(index: number) {
    this.formData.attributes.splice(index, 1);
  }
}
