



















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'DefaultActions',
})
export default class DefaultActions extends Vue {
  @Prop(Boolean) vertical: boolean | undefined;

  Menu = [
    {
      name: 'Explore',
      path: '/shop',
    },
    {
      name: 'Artworks',
      path: '/shop/artworks',
    },
    {
      name: 'Create NFT',
      path: '/shop/artworks/create',
    },
    {
      name: 'Sell NFT',
      path: '/shop/artworks/sell',
    },
  ];
}
