




import Web3 from 'web3'
import MetaMaskOnboarding from '@metamask/onboarding'
import detectEthereumProvider from '@metamask/detect-provider'
import { Vue, Prop } from 'vue-property-decorator'

export default class Metamask extends Vue {
    @Prop({type: String, default: 'Connect Wallet' })
    button_text!: string

    metamask_installed : boolean = false
    connected : boolean  = false
    wallet_addr : string = ''
    onboarding :any = null

    get shortAddr() {
        return `${this.wallet_addr.substr(0, 6)}...${this.wallet_addr.substr(-4)}`
    }

    mounted() {
        this.wallet_addr = this.button_text
        console.log(this.wallet_addr)
        //this.handleConnect();
    }
    connectWallet() {
        if (typeof window.ethereum !== 'undefined') {
            console.log('MetaMask is installed!')

            this.ethEnabled() //Enable etherum connection
            return
        }
    }

    ethEnabled(provider?:any) {
        console.log('Metamask - Setting provider', provider)
      //@ts-ignore
      window.web3 = new Web3(provider)
    }

    handleConnect() {
        detectEthereumProvider().then((provider:any) => {
            if (provider) {
                this.triggerAuth(provider)
            } else {
                console.log('Please install MetaMask!')
            }
        })
    }

    triggerAuth(provider:any) {
        this.onboarding = new MetaMaskOnboarding()

        //Created check function to see if the MetaMask extension is installed
        const isMetaMaskInstalled = () => {
            //Have to check the ethereum binding on the window object to see if it's installed
            return Boolean(provider && provider.isMetaMask)
        }

        //------Inserted Code------\\
        const MetaMaskClientCheck = () => {
            //Now we check to see if MetaMask is installed
            this.metamask_installed = isMetaMaskInstalled()
            if (!this.metamask_installed) {
                //If it isn't installed we ask the user to click to install it
                this.wallet_addr = 'Click here to install MetaMask!'
            } else {
                //If it is installed we change our button text
                this.wallet_addr = 'Connect'
            }
        }
        MetaMaskClientCheck()
        //------/Inserted Code------\\

        if (!this.metamask_installed) {
            this.wallet_addr = 'Onboarding in progress'
            // this.connect_btn_disabled = true;
            //On this object we have startOnboarding which will start the onboarding process for our end user
            this.onboarding.startOnboarding()
        }

        if (typeof window.ethereum == 'undefined') {
            this.wallet_addr = 'Unable to connect'
            return
        }

        console.log('MetaMask is installed!')

        this.ethEnabled(provider) //Enable etherum connection

        provider
            .request({ method: 'eth_requestAccounts' })
            .then((accounts:any) => {
                console.log('Public address', accounts[0])
                this.wallet_addr = accounts[0]
                this.connected = true
                this.$emit('wallet-connected', this.wallet_addr) //Fire connected event
                this.$store.commit('set_wallet_addr', this.wallet_addr)
                this.$store.commit('set_wallet_connected', this.connected)
                this.$emit('connected', this.connected)
            })
            .catch(() => {
                this.wallet_addr = 'Unable to connect'
            })
    }
}
