export const FileCategories = {
  image: {
    type: 'image',
    text: 'Image',
    accept: ['.png', '.jpg', '.jpeg', '.gif', '.svg'],
  },
  video: {
    type: 'video',
    text: 'Video',
    accept: ['.mp4', '.mov'],
    title: 'Upload your video creation',
  },
  audio: {
    type: 'audio',
    text: 'Audio',
    accept: ['.mp3', '.wav', '.flac'],
    title: 'Upload your audio creation',
  },
  vr: {
    type: 'vr',
    text: 'AR/3D',
    accept: ['.glb'],
    title: 'Upload your AR/VR creation',
  },
  html: {
    type: 'html',
    text: 'HTML Asset',
    accept: ['.html'],
    title: 'Upload your HTML file',
  },
};
