<template>
  <div class="collection">
    <div class="row mx-2">
      <ItemCard
        class="col-lg-2 item-card"
        v-for="item in items"
        :key="item.id"
        :item="item"
      ></ItemCard>
    </div>
  </div>
</template>

<script>
// import item from '@/api/item'
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: {
    ItemCard: () => import('@/components/marketplace/ItemCard.vue'),
  },
})
export default class SimilarItem extends Vue {
  @Prop({
    type: Array,
    default: () => {
      let itemDataDefault = [];
      for (let i = 0; i < 18; i++) {
        const item = {
          id: i,
          name: 'Item ' + i,
          description: 'This is a description for item ' + i,
          price: (i * 10 + 1) * Math.round(Math.random() * 100),

          creator: {
            id: i,
            name: 'Creator ' + i,
            wallet_addr: '0x' + i.toString(16),
            avatar:
              'https://lh3.googleusercontent.com/wl09kAQ8Zr_9rMmJM6hquCMWctYAgiQIOCnCvuHkabkaUKoC2hHq_qR2_5Re_Kq9j5ayjkqUnI19sHjKwvD8j8lyfZcUP_Meq8Q9=w600',
          },
          owner: {
            id: i,
            name: 'Owner ' + i,
            wallet_addr: '0x' + i.toString(16),
            avatar:
              'https://lh3.googleusercontent.com/wl09kAQ8Zr_9rMmJM6hquCMWctYAgiQIOCnCvuHkabkaUKoC2hHq_qR2_5Re_Kq9j5ayjkqUnI19sHjKwvD8j8lyfZcUP_Meq8Q9=w600',
          },
          media: {
            thumbnail_src:
              'https://lh3.googleusercontent.com/wl09kAQ8Zr_9rMmJM6hquCMWctYAgiQIOCnCvuHkabkaUKoC2hHq_qR2_5Re_Kq9j5ayjkqUnI19sHjKwvD8j8lyfZcUP_Meq8Q9=w600',
            image_src:
              'https://lh3.googleusercontent.com/wl09kAQ8Zr_9rMmJM6hquCMWctYAgiQIOCnCvuHkabkaUKoC2hHq_qR2_5Re_Kq9j5ayjkqUnI19sHjKwvD8j8lyfZcUP_Meq8Q9=w600',
            video_src:
              'https://lh3.googleusercontent.com/wl09kAQ8Zr_9rMmJM6hquCMWctYAgiQIOCnCvuHkabkaUKoC2hHq_qR2_5Re_Kq9j5ayjkqUnI19sHjKwvD8j8lyfZcUP_Meq8Q9=w600',
            object_src:
              'https://lh3.googleusercontent.com/wl09kAQ8Zr_9rMmJM6hquCMWctYAgiQIOCnCvuHkabkaUKoC2hHq_qR2_5Re_Kq9j5ayjkqUnI19sHjKwvD8j8lyfZcUP_Meq8Q9=w600',
          },
          metadata: {},
          tags: [i, i + 1, i + 2],
          analytics: {
            views: i * 10,
          },
          history: [
            {
              id: i,
              name: 'Owner ' + i,
              avatar:
                'https://lh3.googleusercontent.com/wl09kAQ8Zr_9rMmJM6hquCMWctYAgiQIOCnCvuHkabkaUKoC2hHq_qR2_5Re_Kq9j5ayjkqUnI19sHjKwvD8j8lyfZcUP_Meq8Q9=w600',
              action: 'purchase',
              wallet_addr: '0x' + i.toString(16),
              timestamp: new Date().getTime(),
            },
          ],
        };

        if (i < 6) {
          itemDataDefault.push(item);
        }
      }

      return itemDataDefault;
    },
  })
  items;
}
</script>

<style scoped>
.preview {
  max-height: 50px;
  width: auto;
}

.card {
  background: transparent;
  color: white;
  border: 1px solid #20126f;
}

.card-body::before {
  content: unset;
}

.card-body h4,
h5 {
  color: white;
}

.card-body {
  padding: 1rem;
}

h6,
small {
  color: #7a7a7a;
}

.rarity {
  color: red;
}
</style>
