import {
  MetaplexKey,
  ParticipationStateV2,
  SafetyDepositConfigData,
  WinningConfigType,
} from '@metaplex-foundation/mpl-metaplex';
import BN from 'bn.js';
import { SystemProgram } from '@solana/web3.js';
import { StringPublicKey, TupleNumericType } from '@metaplex-foundation/mpl-core';
import { ParticipationConfigV2 } from '@/views/shop/actions/auctions-v2/vaultDefines';

export class AmountRange {
  amount;
  length;
  constructor(args: any) {
    this.amount = args.amount;
    this.length = args.length;
  }
}

export class SafetyDepositConfig {
  key: MetaplexKey = MetaplexKey.SafetyDepositConfigV1;
  auctionManager: StringPublicKey = SystemProgram.programId.toBase58();
  order: BN = new BN(0);
  winningConfigType: WinningConfigType = WinningConfigType.PrintingV2;
  amountType: TupleNumericType = TupleNumericType.U8;
  lengthType: TupleNumericType = TupleNumericType.U8;
  amountRanges: AmountRange[] = [];
  participationConfig: ParticipationConfigV2 | null = null;
  participationState: ParticipationStateV2 | null = null;

  constructor(args: {
    directArgs?: {
      auctionManager: StringPublicKey;
      order: BN;
      winningConfigType: WinningConfigType;
      amountType: TupleNumericType;
      lengthType: TupleNumericType;
      amountRanges: AmountRange[];
      participationConfig: ParticipationConfigV2 | null;
      participationState: ParticipationStateV2 | null;
    };
  }) {
    if (args.directArgs) {
      Object.assign(this, args.directArgs);
    }
  }
}

export class ValidateSafetyDepositBoxV2Args {
  instruction = 18;
  safetyDepositConfig: SafetyDepositConfigData;
  constructor(safetyDepositConfig: SafetyDepositConfigData) {
    this.safetyDepositConfig = safetyDepositConfig;
  }
}
