<template>
  <div class="pb-3 px-4">
    <div id="mobile-banner">
      <img class="banner-img" :src="src" />
      <div class="banner-content">
        <div id="main-heading">{{ headingText }}}</div>
        <div id="sub-heading">{{ subHeadingText }}}</div>
        {actionComponent}
      </div>
    </div>
    <div id="current-banner" :style="{ backgroundImage: `url(${src})` }">
      <span id="gradient-banner"></span>
      <div id="banner-inner">
        <div id="message-container">
          <div :id="'main-heading'">{{ headingText }}</div>
          <div :id="'sub-heading'">{{ subHeadingText }}</div>
          {{ actionComponent }}
        </div>
        {{ children }}
        <div class="powered-by">
          <span> POWERED BY <b>METAPLEX</b> </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

@Component({
  name: 'Banner',
})
export default class Banner extends Vue {
  @Prop(String) src;
  @Prop(String) headingText;
  @Prop(String) subHeadingText;
  @Prop(Boolean) useBannerBg;
  @Prop(Object) actionComponent;
  @Prop(Object) children;

  mounted() {
    const mainBg = document.getElementById('main-bg');
    const gradient = document.getElementById('bg-gradient');
    if (mainBg && this.useBannerBg) {
      mainBg.style.backgroundImage = `url(${this.src})`;
      mainBg.style.display = 'inline-block';
      if (gradient) {
        gradient.style.display = 'inline-block';
      }
    }
  }

  useEffect() {
    const mainBg = document.getElementById('main-bg');
    const gradient = document.getElementById('bg-gradient');
    if (mainBg && this.useBannerBg) {
      mainBg.style.backgroundImage = '';
      mainBg.style.display = 'none';
    }
    if (gradient) gradient.style.display = 'none';
  }

  @Watch('src', { immediate: true })
  watchSrc() {
    this.useEffect();
  }

  @Watch('useBannerBg', { immediate: true })
  watchUseBannerBg() {
    this.useEffect();
  }
}
</script>

<style scoped>
#mobile-banner {
  width: 100%;
  padding: 0 12px;
}

#mobile-banner .banner-img {
  width: 100%;
}

#mobile-banner .banner-content {
  padding: 20px;
}

#mobile-banner .banner-content #main-heading {
  font-size: 32px;
  font-weight: 600;
}

#mobile-banner .banner-content #sub-heading {
  font-size: 18px;
}

@media (min-width: 768px) {
  #mobile-banner {
    display: none;
  }
}

#current-banner {
  width: 100%;
  height: 380px;
  background-color: #121212; /* @black-100 */
  background-position: top right;
  background-size: 924px 100%;
  background-repeat: no-repeat;
  position: relative;
  border-radius: 12px;
}

@media (max-width: 768px) {
  #current-banner {
    display: none;
  }
}

#current-banner #gradient-banner {
  position: absolute;
  height: 100%;
  width: 462px;
  right: 462px;
  top: 0;
  background: linear-gradient(
    270deg,
    rgba(18, 18, 18, 0) 0%,
    #121212 100%
  ); /* @black-100 */
}

#current-banner #banner-inner {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

#current-banner #banner-inner .powered-by {
  margin-top: -5%;
  text-align: right;
}

#current-banner #banner-inner .powered-by span {
  background: rgba(255, 255, 255, 0.02);
  border-radius: 36px;
  padding: 15px;
  margin-right: 5px;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.02em;
}

#current-banner #message-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 4vw;
  max-width: 600px;
}

#current-banner #message-container #main-heading {
  font-weight: 600;
  font-size: 48px;
  line-height: 48px;
  letter-spacing: -0.02em;
  margin-bottom: 16px;
}

#current-banner #message-container #sub-heading {
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 32px;
}
</style>
