<template>
  <div id="app">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12 px-0 viewport">
          <header v-if="isInMarketPlace" class="container-fluid index inner-view">
            <br />
            <AppBar class="App-Bar" />
          </header>
          <router-view class="inner-view pl-0"></router-view>
        </div>
      </div>
    </div>
    <Sidenav></Sidenav>
  </div>
</template>

<script>
// import '@/assets/bootstrap/css/bootstrap.min.css'
import '@/assets/css/ionicons.min.css';
import 'owl.carousel/dist/assets/owl.carousel.css';
import '@/assets/owlcarousel/css/owl.theme.css';
import '@/assets/css/magnific-popup.css';
import '@/assets/css/style.css';
import '@/assets/css/responsive.css';
import '@/assets/color/theme.css';
import '@/assets/css/main.css';
import '@/assets/css/base.scss';
import '@/assets/css/ant_design_overrides.scss';
// import '@/assets/css/old/main.css'
// import '@/assets/css/old/additional.css'
import '@/assets/css/theme_overrides.css';
import '@/assets/css/animate.css';

//
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {
    Sidenav: () => import('./components/Sidenav'),
    AppBar: () => import('./views/shop/components/app-bar/Index'),
  },
})
export default class App extends Vue {
  mounted() {}

  get isInMarketPlace() {
    return this.$route.meta?.['category'] === 'marketplace';
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css?family=Poppins:100,200,300,400,500,600,700,800,900&display=swap');

div.show,
.index {
  /*margin-bottom: 100px;*/
}

.viewport {
  height: 100vh;
  max-height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
  background: #1e1e1f;
}

.inner-view {
  margin-left: 60px;
  width: 98%;
}

.blurred {
  filter: blur(4px);
  -webkit-filter: blur(4px);
  transition-timing-function: ease-in-out;
  transition-duration: 0.4s;
}
</style>
