import { ProcessAccountsFunc } from '@/views/shop/contexts/meta/types';
import {
  decodeEdition,
  decodeMasterEdition,
  decodeMetadata,
  Edition,
  MasterEditionV1,
  MasterEditionV2,
  Metadata,
} from '@/views/shop/actions/metadata';
import { isValidHttpUrl, METADATA_PROGRAM_ID, pubkeyToString } from '@/views/shop/utils';
import { ParsedAccount } from '@/views/shop/contexts/accounts/types';
import { AccountInfo } from '@solana/web3.js';
import { MetadataKey } from '@metaplex-foundation/mpl-token-metadata';

export const processMetaData: ProcessAccountsFunc = ({ account, pubkey }, setter) => {
  if (!isMetadataAccount(account)) {
    return;
  }
  try {
    if (isMetadataV1Account(account)) {
      const metadata = decodeMetadata(account.data);

      if (isValidHttpUrl(metadata.data.uri)) {
        const parsedAccount: ParsedAccount<Metadata> = {
          pubkey,
          account,
          info: metadata,
        };

        setter('metadataByMint', metadata.mint, parsedAccount);
        setter('metadataByMetadata', pubkey, parsedAccount);
      }
    }

    if (isEditionV1Account(account)) {
      const edition = decodeEdition(account.data);
      const parsedAccount: ParsedAccount<Edition> = {
        pubkey,
        account,
        info: edition,
      };
      setter('editions', pubkey, parsedAccount);
    }

    if (isMasterEditionAccount(account)) {
      const masterEdition = decodeMasterEdition(account.data);

      if (isMasterEditionV1(masterEdition)) {
        const parsedAccount: ParsedAccount<MasterEditionV1> = {
          pubkey,
          account,
          info: masterEdition,
        };
        setter('masterEditions', pubkey, parsedAccount);

        setter('masterEditionsByPrintingMint', masterEdition.printingMint, parsedAccount);

        setter(
          'masterEditionsByOneTimeAuthMint',
          masterEdition.oneTimePrintingAuthorizationMint,
          parsedAccount,
        );
      } else {
        const parsedAccount: ParsedAccount<MasterEditionV2> = {
          pubkey,
          account,
          info: masterEdition,
        };
        setter('masterEditions', pubkey, parsedAccount);
      }
    }
  } catch {
    // ignore errors
    // add type as first byte for easier deserialization
  }
};

const isMetadataAccount = (account: AccountInfo<Buffer>) =>
  account && pubkeyToString(account.owner) === METADATA_PROGRAM_ID;

const isMetadataV1Account = (account: AccountInfo<Buffer>) =>
  account.data[0] === MetadataKey.MetadataV1;

const isEditionV1Account = (account: AccountInfo<Buffer>) =>
  account.data[0] === MetadataKey.EditionV1;

const isMasterEditionAccount = (account: AccountInfo<Buffer>) =>
  account.data[0] === MetadataKey.MasterEditionV1 ||
  account.data[0] === MetadataKey.MasterEditionV2;

const isMasterEditionV1 = (me: any): boolean => {
  return me.key === MetadataKey.MasterEditionV1;
};
