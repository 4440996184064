import {api, apiHelper} from "./api";

export class Saga {
	prefix() {
		return "sagas"
	}

	index(cb, page = 0, limit = 50, sort = 'uid', sortOrder = 'desc') {
		return api.get(`${this.prefix()}?${apiHelper.buildPaginationQuery(page, limit, sort, sortOrder)}`)
	}

	indexItems(uid, cb, page = 0, limit = 50, sort = 'uid', sortOrder = 'desc') {
		return api.get(`${this.prefix()}/${uid}/items?${apiHelper.buildPaginationQuery(page, limit, sort, sortOrder)}`)
	}

	show(uid) {
		return api.get(`${this.prefix()}/${uid}`)
	}

	showItem(uid, item) {
		return api.get(`${this.prefix()}/sagas/${uid}/items/${item}`)
	}

	create(obj) {
		return api.post(`${this.prefix()}`, obj)
	}

	update(uid, obj) {
		return api.put(`${this.prefix()}/${uid}`, obj)
	}

	delete(uid) {
		return api.delete(`${this.prefix()}/${uid}`)
	}
}

export default new Saga()