import { MetaplexKey } from '@metaplex-foundation/mpl-metaplex';
import { StringPublicKey } from '@metaplex-foundation/mpl-core';
import BN from 'bn.js';

export const INDEX = 'index';
export const MAX_INDEXED_ELEMENTS = 100;

export class SetAuctionCacheArgs {
  static SCHEMA = new Map<any, any>([
    [
      SetAuctionCacheArgs,
      {
        kind: 'struct',
        fields: [['instruction', 'u8']],
      },
    ],
  ]);
  instruction = 22;
}

export class StoreIndexer {
  static SCHEMA = new Map<any, any>([
    [
      StoreIndexer,
      {
        kind: 'struct',
        fields: [
          ['key', 'u8'],
          ['store', 'pubkeyAsString'],
          ['page', 'u64'],
          ['auctionCaches', ['pubkeyAsString']],
        ],
      },
    ],
  ]);
  key: MetaplexKey = MetaplexKey.StoreIndexerV1;
  store: StringPublicKey;
  page: BN;
  auctionCaches: StringPublicKey[];

  constructor(args: {
    store: StringPublicKey;
    page: BN;
    auctionCaches: StringPublicKey[];
  }) {
    this.key = MetaplexKey.StoreIndexerV1;
    this.store = args.store;
    this.page = args.page;
    this.auctionCaches = args.auctionCaches;
  }
}

export class SetStoreIndexArgs {
  static SCHEMA = new Map<any, any>([
    [
      SetStoreIndexArgs,
      {
        kind: 'struct',
        fields: [
          ['instruction', 'u8'],
          ['page', 'u64'],
          ['offset', 'u64'],
        ],
      },
    ],
  ]);
  instruction = 21;
  page: BN;
  offset: BN;
  constructor(args: { page: BN; offset: BN }) {
    this.page = args.page;
    this.offset = args.offset;
  }
}

export class AuctionCache {
  static SCHEMA = new Map<any, any>([
    [
      AuctionCache,
      {
        kind: 'struct',
        fields: [
          ['key', 'u8'],
          ['store', 'pubkeyAsString'],
          ['timestamp', 'u64'],
          ['metadata', ['pubkeyAsString']],
          ['auction', 'pubkeyAsString'],
          ['vault', 'pubkeyAsString'],
          ['auctionManager', 'pubkeyAsString'],
        ],
      },
    ],
  ]);
  key: MetaplexKey = MetaplexKey.AuctionCacheV1;
  store: StringPublicKey;
  timestamp: BN;
  metadata: StringPublicKey[];
  auction: StringPublicKey;
  vault: StringPublicKey;
  auctionManager: StringPublicKey;

  constructor(args: {
    store: StringPublicKey;
    timestamp: BN;
    metadata: StringPublicKey[];
    auction: StringPublicKey;
    vault: StringPublicKey;
    auctionManager: StringPublicKey;
  }) {
    this.key = MetaplexKey.AuctionCacheV1;
    this.store = args.store;
    this.timestamp = args.timestamp;
    this.metadata = args.metadata;
    this.auction = args.auction;
    this.vault = args.vault;
    this.auctionManager = args.auctionManager;
  }
}
