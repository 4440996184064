<template>
  <div @click="$router.push(`/marketplace/assets/${item.id}`)" class="item-card">
    <div class="item">
      <div class="media__container">
        <img class="img-fluid" :src="item.media.image_src" />
      </div>

      <div class="detail__container">
        <h3 class="price">{{ item.price > 0 ? `$${item.price}` : 'FREE' }}</h3>

        <h3>{{ item.name }}</h3>
        <h4>{{ item.creator.name }}</h4>
      </div>
    </div>
  </div>
</template>

<script>
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({ name: 'ItemCard' })
export default class ItemCard extends Vue {
  @Prop({ type: Object, required: true }) item;
}
</script>

<style scoped>
.item-card {
  min-height: 10vh;
}

.item {
  color: white;
  background: #0e0e0f;
  border-radius: 8px;
  box-shadow: black 0 4px 7px;
  transition: all 0.3s ease-in-out;
}

.detail__container {
  padding: 1rem;
}

h2,
h3,
h4 {
  text-align: left;
  color: white;
}

h2 {
  text-align: center;
  font-size: 2rem;
}

h2.price {
  text-align: right;
}

.item:hover {
  transform: scale(1.05);
  box-shadow: black 0 7px 9px;
}

.img-fluid {
  width: 100%;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
</style>
