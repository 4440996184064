<template>
  <div>
    <h3 class="text-xlarge text-white fw-bold">Initial Phase</h3>
    <p class="text-white opacity-50 fw-bold">Set the terms for your auction.</p>

    <b-form-group
      class="auction-time__container"
      label="When do you want the auction to begin?"
      v-slot="{ ariaDescribedby }"
    >
      <!-- TYPE OF START AUCTION -->
      <template v-for="opt in AuctionTypes">
        <b-form-radio
          v-model="internalValue.auctionType"
          name="auctionAt"
          class="cursor-pointer pt-1"
          :aria-describedby="ariaDescribedby"
          :key="'radio' + opt.type"
          :value="opt.type"
          >{{ opt.text }}
        </b-form-radio>
        <span class="opacity-50 mt-1" :key="'description' + opt.type">{{
          opt.desc
        }}</span>
      </template>
    </b-form-group>

    <div v-if="internalValue.auctionType === AuctionTypes.specified.type">
      <!-- SET DATETIME START AUCTION -->
      <p class="text-white text-large mt-4 opacity-75">Auction start date</p>
      <b-form-datepicker
        id="auction-datepicker"
        v-model="internalValue.auctionStartAt.date"
        class="mb-2"
      ></b-form-datepicker>
      <b-form-timepicker
        v-model="internalValue.auctionStartAt.time"
        show-seconds
        locale="en"
      ></b-form-timepicker>

      <!-- TYPE OF START GOLIVE -->
      <b-form-group
        class="golive-time__container mt-5"
        label="When do you want the listing to go live?"
        v-slot="{ ariaDescribedby }"
      >
        <template v-for="opt in GoliveTypes">
          <b-form-radio
            v-model="internalValue.goLiveType"
            name="goliveAt"
            class="cursor-pointer pt-1"
            :aria-describedby="ariaDescribedby"
            :key="'radio-golive' + opt.type"
            :value="opt.type"
            >{{ opt.text }}
          </b-form-radio>
          <span class="opacity-50 mt-1" :key="'description-golive' + opt.type">{{
            opt.desc
          }}</span>
        </template>
      </b-form-group>

      <div v-if="internalValue.goLiveType === GoliveTypes.specified.type">
        <!-- SET DATETIME START GOLIVE -->
        <p class="text-white text-large mt-4 opacity-75">Preview start date</p>
        <b-form-datepicker
          id="golive-datepicker"
          v-model="internalValue.goLiveAt.date"
          class="mb-2"
        ></b-form-datepicker>
        <b-form-timepicker
          v-model="internalValue.goLiveAt.time"
          show-seconds
          locale="en"
        ></b-form-timepicker>
      </div>
    </div>
  </div>
</template>

<script>
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { cloneObject } from '@/helper/objects';

@Component({
  name: 'InitialPhase',
})
export default class InitialPhase extends Vue {
  @Prop(Object) value;

  AuctionTypes = {
    immediately: {
      type: 'immediately',
      text: 'Immediately',
      desc: 'Participants can buy the NFT as soon as you finish setting up the auction.',
    },
    specified: {
      type: 'specified',
      text: 'At a specified date',
      desc: 'Participants can start buying the NFT at a specified date.',
    },
  };

  GoliveTypes = {
    immediately: {
      type: 'immediately',
      text: 'Immediately',
      desc: 'Participants will be able to view the listing with a countdown to the start date as soon as you finish setting up the sale.',
    },
    specified: {
      type: 'specified',
      text: 'At a specified date',
      desc: 'Participants will be able to view the listing with a countdown to the start date at the specified date.',
    },
  };

  DefaultValue = {
    auctionType: this.AuctionTypes.immediately.type,
    auctionStartAt: {
      date: null,
      time: null,
    },
    goLiveType: '',
    goLiveAt: {
      date: null,
      time: null,
    },
  };

  internalValue = this.value ? cloneObject(this.value) : this.DefaultValue;

  @Watch('internalValue', { deep: true })
  watchInternalValue(value) {
    this.$emit('input', value);
  }
}
</script>

<style scoped lang="scss">
@import './src/assets/css/components/bootstrap-date-picker.scss';
@import './src/assets/css/components/bootstrap-time-picker.scss';

.auction-time__container,
.golive-time__container {
  ::v-deep {
    .col-form-label {
      font-size: 1.25rem;
      opacity: 0.75;
    }

    .custom-radio {
      input,
      label {
        cursor: pointer;
        width: auto;
        display: inline-block;
        margin-right: 8px;
        margin-top: 8px;
      }

      label {
        font-size: inherit;
        font-weight: normal;
      }
    }
  }
}
</style>
