export const toNumber = (val: any): number => {
    if (!val) {
        return 0
    }

    const number = Number(val)
    if (Number.isNaN(number)) {
        return 0
    }

    return number
}