<template>
    <div class="index">
        <section id="home" class="banner_section section_gradiant_dark">
            <div id="banner_bg_effect" class="banner_effect"></div>
            <div class="container-fluid">
                <div class="row align-items-center">
                    <div class="offset-lg-2 col-lg-4 col-md-12 col-sm-12 order-lg-first text_md_center">
                        <div class="banner_text">
                            <h1 class="animate__animated animate__fadeInUp" data-animation="fadeInUp" data-animation-delay="1.5s"><span>Dice</span> Machine</h1>
                            <p class="animate__animated animate__fadeInUp" data-animation="fadeInUp" data-animation-delay="1.7s">
                                Provably fair dice on the Harmony ONE blockchain
                            </p>

                            <div class="row text-center">
                                <div class="col-4">
                                    <h4>Dice Sides</h4>
                                    <h5 class="st">{{ details.dice_sides }}</h5>
                                </div>
                                <div class="col-4">
                                    <h4>Win Condition</h4>
                                    <h5 class="st">Roll a {{ details.dice_num_to_win }}</h5>
                                </div>
                                <div class="col-4">
                                    <h4>Return</h4>
                                    <h5 class="st">2x</h5>
                                </div>
                            </div>

                            <div class="row mt-5">
                                <div class="col-12 text-break">
                                    <h6 class="contract">
                                        Contract: <a target="_blank" :href="`https://explorer.harmony.one/address/${contract_addr}`">{{ contract_addr }}</a>
                                    </h6>
                                </div>
                                <div class="col-12">
                                    <h6>VRF Provider: <a href="https://docs.harmony.one/home/general/technology/randomness">Harmony ONE</a></h6>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12 col-sm-12 order-last text-center">
                        <Dice :state="dice.state" :result="dice.roll"></Dice>

                        <a target="_blank" v-if="last_result !== null" :href="`https://explorer.harmony.one/tx/${last_result.transactionHash}`">
                            <small>{{ last_result.transactionHash }}</small>
                        </a>
                    </div>
                </div>
            </div>
        </section>

        <div class="container mt-5">
            <div class="row">
                <div class="col-lg-7 col-md-12 text-center">
                    <PlaceBet
                        :bets_enabled="betting_enabled"
                        :min_bet="parseInt(details.min_bet)"
                        :max_bet="parseInt(details.max_bet)"
                        @place-bet="play"
                    ></PlaceBet>
                </div>

                <div class="col-lg-4 col-md-12 col-sm-12 offset-lg-1">
                    <div class="card">
                        <div class="card-body text-center">
                            <h3>Details</h3>
                            <table class="table">
                                <tbody>
                                    <tr>
                                        <td>Dice Sides</td>
                                        <td>{{ details.dice_sides }}</td>
                                    </tr>
                                    <tr>
                                        <td>Win Condition</td>
                                        <td>Roll a {{ details.dice_num_to_win }}</td>
                                    </tr>
                                    <tr>
                                        <td>Bet Range</td>
                                        <td>{{ details.min_bet }} - {{ details.max_bet }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <h3 class="mt-4">Recent Transactions</h3>

                <div class="col-12 text-center mt-5" v-if="history.length === 0">
                    <i>Loading Contract History...</i>
                </div>

                <div class="col-12" v-for="(evt, k) in history" :key="k">
                    <div class="card mb-3">
                        <div class="card-body">
                            <div class="row">
                                <div class="col">
                                    <h4>{{ evt.event.toUpperCase() }}</h4>
                                    <small
                                        ><a target="_blank" :href="`https://explorer.harmony.one/tx/${evt.transactionHash}`">{{ evt.transactionHash }}</a>
                                    </small>
                                </div>
                                <div class="col">
                                    <small>Bet</small>
                                    <h1>{{ toOne(evt.returnValues.tokensBet) }} ONE</h1>
                                </div>
                                <div class="col">
                                    <small>Payout</small>
                                    <h1 v-if="evt.returnValues.tokensWon !== undefined">{{ toOne(evt.returnValues.tokensWon) }} ONE</h1>
                                    <h1 v-if="evt.returnValues.tokensWon === undefined">-</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <footer>
            <ResultModal :result="last_result" v-show="showResultModal" @close="showResultModal = false"></ResultModal>
        </footer>
    </div>
</template>

<script>
import DiceContract from '/public/contracts/abi/dice_one'
import Web3 from 'web3'
import Contract from 'web3-eth-contract'
import '@/assets/css/lounge.css'
import { Component, Vue } from 'vue-property-decorator'

@Component({
    components: {
        ResultModal: () => import('./DiceResultModal.vue'),
        PlaceBet: () => import('../../components/wallet/PlaceBet.vue'),
        ResultModal: () => import('./DiceResultModal.vue'),
    },
    name: 'DiceOne',
})
export default class DiceOne extends Vue {
    contract_addr = '0xc16d4b07a42727836b565f2a3b1a9080273c0944'
    betting_enabled = false
    contract = null
    wsProvider = null
    contractListener = null
    details = {
        current_jackpot: 'Loading',
        total_bets_placed: 'Loading',
        min_bet: 1,
        max_bet: 'Loading',
        dice_sides: 'Loading',
        dice_num_to_win: 'Loading',
    }
    eventsHandled = {}
    history = []
    betToPlace = 1.0
    showResultModal = false
    dice = {
        state: 0,
        roll: 0,
    }
    last_result = null //Winner/Loser evt
    gas_limit = 210000
    harmonyProvider = 'wss://ws.s0.t.hmny.io'
    web3 = null
    play(amountToBet) {
        const wallet = this.$store.state.wallet_addr
        console.log(`PLAY: ${wallet} - ${amountToBet}`)

        console.log('Current provider:', this.web3.currentProvider)

        this.web3.eth.getGasPrice().then(gasPrice => {
            console.log('Gas Cost: ', gasPrice)

            this.dice.state = 1 //Start spin

            const payload = {
                from: wallet,
                value: Web3.utils.toWei('' + amountToBet, 'ether'),
                gasPrice: gasPrice,
                gasLimit: this.gas_limit,
            }
            console.log('Initiating Web3 Transaction', payload)

            this.listenContractEvents()
            this.contract.methods
                .play()
                .send(payload)
                .then(res => {
                    console.log('Result: ', res)
                    const keys = Object.keys(res.events)
                    for (let i = 0; i < keys.length; i++) {
                        console.log('Result Event: ', res.events[keys[i]])
                        this.handleEvent(res.events[keys[i]])
                    }
                })
                .catch(err => {
                    console.log('Err: ', err)
                    this.dice.state = 0 //Reset state (Idle)
                })
        })
    }

    listenContractEvents() {
        if (this.wsProvider !== null) {
            console.log('WS prov', this.wsProvider)
            this.connection.close()
            this.wsProvider.disconnect()
        }

        this.wsProvider = new Web3.providers.WebsocketProvider(this.harmonyProvider)

        console.log('WS prov', this.wsProvider)
        this.contractListener.setProvider(this.wsProvider)

        this.wsProvider.on(this.wsProvider.CONNECT, () => {
            console.log('WS Connected listening for contract events')
            this.contractListener.events
                .allEvents()
                .on('data', event => {
                    console.log('data event in', event)
                    this.handleEvent(event)
                })
                .on('error', console.error)
        })
    }

    diceRolled(res) {
        console.log('diceRolled', res)
        this.dice.roll = parseInt(res.returnValues.result)
        this.dice.state = 3
    }

    diceLoser(res) {
        this.last_result = res
        this.showResultModal = true
        this.history.unshift(res)
    }

    diceWinner(res) {
        this.last_result = res
        this.showResultModal = true
        this.history.unshift(res)
    }

    handleEvent(event) {
        //Only handle event once
        const evtKey = `${event.transactionHash}-${event.blockHash}-${event.id}`
        if (this.eventsHandled[evtKey]) {
            console.log('skipping event', event)
            return
        }
        this.eventsHandled[evtKey] = true

        console.log('Handling Event: ', event)
        switch (event.event) {
            case 'DiceRolled':
                this.diceRolled(event)
                return
            case 'Loser':
                this.diceLoser(event)
                return
            case 'Winner':
                this.diceWinner(event)
                return
        }
    }

    async balance() {
        const accounts = await this.web3.eth.getAccounts()
        const balance = await this.web3.eth.getBalance(accounts[0])
        console.log('Balance: ', balance)
    }

    async getHistory() {
        const latestBlock = await this.web3.eth.getBlockNumber()
        console.log('Last block', latestBlock)

        console.log('Getting past events')
        this.contract
            .getPastEvents('allEvents', {
                fromBlock: latestBlock - 1000,
                toBlock: 'latest',
            })
            .then(events => {
                console.log('events', events) // same results as the optional callback above

                this.history = []
                for (let e in events.reverse()) {
                    if (events[e].event === 'DiceRolled') continue
                    this.history.push(events[e])
                }
            })
    }

    setupContract() {
        if (!window.web3) {
            console.log('Web3 not detected')
            return false
        }

        //Setup provider (XHR)
        this.web3 = new Web3(window.web3)
        console.log('Setting contract provider', window.web3)
        this.contract.setProvider(window.web3)

        //Load details
        this.loadContractDetails()
        this.getHistory()

        return true
    }

    toOne(num) {
        return Web3.utils.fromWei('' + num, 'ether')
    }

    loadContractDetails() {
        console.log('get getMaxBet')
        this.contract.methods
            .getMaxBet()
            .call()
            .then(res => {
                console.log('getMaxBet', res)
                this.details.max_bet = this.toOne(res)
            })

        console.log('get minWinNumber')
        this.contract.methods
            .minWinNumber()
            .call()
            .then(res => {
                console.log('minWinNumber', res)
                this.details.dice_num_to_win = res
            })

        console.log('get diceSides')
        this.contract.methods
            .diceSides()
            .call()
            .then(res => {
                console.log('diceSides', res)
                this.details.dice_sides = res
            })

        console.log('get currentJackpot')
        this.contract.methods
            .currentJackpot()
            .call()
            .then(res => {
                console.log('currentJackpot', res)
                this.details.current_jackpot = this.toOne(res)
            })
    }
    mounted() {
        this.contract = new Contract(DiceContract, this.contract_addr)
        this.contractListener = new Contract(DiceContract, this.contract_addr)

        if (this.$store.state.wallet_connected) this.betting_enabled = this.setupContract()

        this.$store.subscribe(mutation => {
            if (mutation.type === 'set_wallet_connected') {
                this.betting_enabled = this.setupContract()
            }
        })
    }
}
</script>

<style scoped></style>
