<template>
  <div>
    <h2 class="text-white fw-bold">Select which item to sell</h2>
    <!--  SELECT NFT TO SELL   -->
    <AddNFT v-model="value.nft" />

    <!--  SELECT SELL TYPE   -->
    <div v-if="value.nft" class="col-12 p-0 mt-3">
      <a-dropdown
        class="w-100 d-flex justify-content-between align-items-center mx-0 text-white"
        overlayClassName="dropdown-sell-type"
        :trigger="['click']"
      >
        <a-menu slot="overlay" @click="onSelectSellType">
          <a-menu-item v-for="(opt, idx) in sellOptions" :key="idx">
            {{ opt.text }}
          </a-menu-item>
        </a-menu>
        <a-button
          style="margin-left: 8px; text-transform: none"
          :class="[{ 'show-place-holder': !value.sellType }]"
        >
          {{
            SellTypes[value.sellType]
              ? SellTypes[value.sellType].text
              : 'Please select this field'
          }}
          <a-icon type="down" />
        </a-button>
      </a-dropdown>
    </div>

    <!--  SELECT AUCTION MINT   -->
    <div class="col-12 p-0 mt-4">
      <AuctionMint v-model="value.auctionMint" />
    </div>

    <ValidationProvider
      ref="validator"
      name="Price"
      :rules="{ required: true }"
      v-slot="slotPrice"
    >
      <h5 class="opacity-75 text-white mt-4">Price</h5>
      <span class="opacity-75">This is the instant sale price for your item.</span>
      <a-form-item :validateStatus="resolveState(slotPrice)" :help="slotPrice.errors[0]">
        <a-input
          placeholder="Price"
          v-model="value.instantSalePrice"
          :suffix="(value.auctionMint && value.auctionMint.symbol) || 'SOL'"
        />
      </a-form-item>
    </ValidationProvider>
  </div>
</template>

<script>
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { ArtType } from '@/views/shop/types';
import { cloneObject } from '@/helper/objects';
import { ValidationProvider } from 'vee-validate';

@Component({
  name: 'InstantSale',
  components: {
    AddNFT: () => import('@/views/shop/components/sell-nft/AddNFT'),
    AuctionMint: () => import('@/views/shop/components/sell-nft/AuctionMint'),
    ValidationProvider,
  },
})
export default class InstantSale extends Vue {
  @Prop(Object) value;

  SellTypes = {
    single: {
      type: 'single',
      text: 'Sell unique token',
    },
    limited: {
      type: 'limited',
      text: 'Sell limited number of copies',
    },
    open: {
      type: 'open',
      text: 'Sell unlimited number of copies',
    },
  };

  get copiesEnabled() {
    const maxSupply = this.value.nft?.maxSupply;
    return !!maxSupply && maxSupply > 0;
  }

  get sellOptions() {
    const sellTypes = cloneObject(this.SellTypes);
    if (!this.copiesEnabled) {
      delete sellTypes[this.SellTypes.limited.type];
    } else if (this.value.nft?.type !== ArtType.Master) {
      delete sellTypes[this.SellTypes.open.type];
      // open: only for unlimited supply and master edition
    }

    return Object.keys(sellTypes).map(key => sellTypes[key]);
  }

  onSelectSellType(evt) {
    this.value.sellType = this.sellOptions[evt.key]?.type;
  }

  async validate() {
    return this.$refs['validator']?.validate?.();
  }

  @Watch('value')
  watchValue(val) {
    this.$emit('input', val);
  }
}
</script>

<style scoped lang="scss">
.dropdown-sell-type {
  .ant-dropdown-menu {
    background-color: #383838;

    .ant-dropdown-menu-item {
      color: white;

      &:hover {
        background-color: #818181;
      }
    }
  }
}
</style>
