import { Readable } from 'form-data';

export const Progresses = {
  start: {
    type: 'start',
    desc: 'Start creating',
  },
  uploadFile: {
    type: 'uploadFile',
    desc: 'Preparing Assets',
  },
  uploadMetadata: {
    type: 'uploadMetadata',
    desc: 'Uploading to Arweave',
  },
  authenticate: {
    type: 'authenticate',
    desc: 'Check wallet connection',
  },
  approve: {
    type: 'approve',
    desc: 'Signing Token Transaction',
  },
};

export async function uploadFile(
  collection: any,
  walletAddress: string,
  contentType: string,
  body: string | Readable | ReadableStream<any> | Blob | Uint8Array | Buffer | any,
): Promise<string> {
  return new Promise((resolve, reject) => {
    const formData = new FormData();
    // @ts-ignore

    // HTML file input, chosen by user
    formData.append('wallet_addr', walletAddress);
    formData.append('file', body);

    const request = new XMLHttpRequest();
    const uploadUrl = 'https://app.alphabatem.com/auth/users/file';

    request.onreadystatechange = function () {
      if (request.readyState === 4) {
        if (request.status === 200) {
          resolve(request.responseText);
        } else {
          reject({ error: 'failed' });
        }
      }
    };

    request.open('POST', uploadUrl, true);
    request.send(formData);
  });
}

export async function deleteFile(
  fileName: string,
  walletAddress: string,
): Promise<boolean> {
  try {
    const request = new XMLHttpRequest();
    const deleteURL = `https://app.alphabatem.com/auth/users/file?wallet_addr=${walletAddress}&filename=${fileName}`;

    request.open('DELETE', deleteURL, false);
    request.send();

    return request.status === 200 && request.responseText === 'ok';
  } catch (err) {
    console.log('Error', err);
  }
  return false;
}

export async function alphabatemUpload(
  collection: any,
  metaData: any,
  file: any,
  category: string,
  walletAddress: '',
  thumbnailFile?: any,
  callbackProgress?: (type: string) => void,
) {
  let fileURL;
  let metaDataUrl;
  let thumbnailURL;
  let fileName = metaData.name;

  callbackProgress?.(Progresses.uploadFile.type);

  // Upload thumbnail file
  if (thumbnailFile) {
    thumbnailURL = await uploadFile(collection, walletAddress, 'image', thumbnailFile);
    thumbnailURL = thumbnailURL.trim().replace(/"/gi, '');
  }

  // Upload file
  fileURL = await uploadFile(collection, walletAddress, category, file);

  fileURL = fileURL.trim().replace(/"/gi, '');
  metaData.image = thumbnailURL || fileURL;
  metaData.seller_fee_basis_points *= 100;

  const properties = {
    category,
    files: [{ uri: fileURL, type: category }],
    creators: [{ address: walletAddress, share: 100 }],
    maxSupply: metaData.maxSupply,
  };

  // Update properties when has thumbnail file
  if (thumbnailURL) {
    properties.files = [{ uri: thumbnailURL, type: 'image' }, ...properties.files];
  }

  metaData.properties = properties;

  fileName = fileName.replace(/(\..+)/, '.json');
  const newFileJson: any = new Blob([JSON.stringify(metaData)], {
    type: 'application/json',
  });

  const fileJson: any = blobToFile(newFileJson, fileName);

  callbackProgress?.(Progresses.uploadMetadata.type);
  metaDataUrl = await uploadFile(collection, walletAddress, 'application/json', fileJson);

  return [metaDataUrl, fileURL];
}

const blobToFile = (theBlob: Blob, fileName: string): File => {
  return new File([theBlob], fileName, {
    lastModified: new Date().getTime(),
    type: theBlob.type,
  });
};
