<template>
    <div class="show">
        <BannerTop :title_blue="saga.saga_name" title_white=" Saga" :heading="saga.description" :image="saga.image"></BannerTop>

        <div class="container-fluid mt-4">
            <div class="row">
                <div class="col-4 offset-2">
                    <h4>{{ saga.saga_name }} Saga</h4>
                    <div class="card">
                        <div class="card-body text-center">
                            <h6>{{ saga.contract_addr }}</h6>

                            <p class="mt-3">{{ saga.description }}</p>

                            <div class="mt-3 text-center">
                                <button class="btn btn-primary">CLAIM</button>
                            </div>
                        </div>
                    </div>

                    <h4 class="mt-3">Stats</h4>
                    <div class="card">
                        <div class="card-body">
                            <div class="saga-stats">
                                <canvas id="stat-chart"></canvas>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-5">
                    <h4>Items</h4>
                    <div class="row">
                        <div v-for="item in items" :key="item.id" class="col-12 mb-3">
                            <ItemCard :item="item"></ItemCard>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Chart, registerables } from 'chart.js'
import Saga from '@/api/saga'
import { Component, Vue } from 'vue-property-decorator'

@Component({
    name: 'SagaShow',
    components: {
        ItemCard: () => import('../../components/explorer/ItemCard'),
        BannerTop: () => import('../../components/BannerTop.vue'),
    },
})
export default class SagaShow extends Vue {
    saga = {}
    items = []
    chart = null

    getSaga(saga) {
        Saga.show(saga).then(r => (this.saga = r.data))
    }

    getSagaItems(saga) {
        Saga.indexItems(saga).then(r => (this.items = r.data))
    }

    mounted() {
        this.getSaga(this.$route.params.id)
        this.getSagaItems(this.$route.params.id)

        Chart.register(...registerables)

        this.chart = new Chart(document.getElementById('stat-chart'), {
            type: 'doughnut',
            data: {
                labels: ['Circulating', 'Unclaimed', 'Opened'],
                datasets: [
                    {
                        label: 'Saga Information',
                        backgroundColor: ['rgb(255, 99, 132)', 'rgb(54, 162, 235)', 'rgb(255, 205, 86)'],
                        data: [3000, 6000, 1000],
                    },
                ],
            },
            options: {
                responsive: true,
            },
        })
    }
}
</script>

<style scoped>
.saga-stats {
    position: relative;
    height: 40vh;
    width: 20vw;
    margin-left: 5vw;
    text-align: center;
}

.card {
    background: transparent;
    color: white;
    border: 1px solid #20126f;
}

.card-body::before {
    content: unset;
}

.card-body,
h4,
h5 {
    color: white;
}

.item-container {
    text-align: center;
}

.preview {
    max-height: 128px;
    width: auto;
}
</style>
