<template>
    <div v-if="attr !== ''" class="badge badge-primary my-2">{{ attr }}</div>
</template>

<script>
import { Prop, Vue } from 'vue-property-decorator'

export default class Attr extends Vue {
    @Prop(String) attr
}
</script>

<style scoped>
.badge {
    background-color: #26b6d4;
}
</style>
